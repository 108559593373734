import { useTranslation } from 'next-export-i18n';
import { Button, Container, Link, Paper, Typography } from 'ui';

export const CompletionView = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Paper>
        <Typography mb={3}>
          {`${t('switching.completionInfo.successRequest')} `}
          <Link href={`mailto:${t('shared.company.consumerSalesEmail')}`}>
            {t('shared.company.consumerSalesEmail')}
          </Link>
          {`. ${t('switching.completionInfo.contactUs')} `}
          <Link href={`tel:${t('shared.company.tokiPhone')}`}>{t('shared.company.tokiPhone')}</Link>
        </Typography>
        <Button href={t('shared.company.tokiSite')}>{t('switching.common.redirectToToki')}</Button>
      </Paper>
    </Container>
  );
};
