import { Controller, useFormContext } from 'react-hook-form';
import { AutocompleteField, AutocompleteFieldProps, FormControl, FormHelperText, TextField } from 'ui';

import { getError } from '../../utils';

export interface Props<T> extends Omit<AutocompleteFieldProps<T>, 'renderInput'> {
  label: string;
  name: string;
  autocomplete?: string;
  'data-testid'?: string;
  required?: boolean;
  freeSolo?: boolean;
  shrink?: boolean;
  placeholder?: string;
}

export const Autocomplete = <T,>({
  label,
  name,
  autocomplete,
  placeholder,
  disabled = false,
  required = false,
  freeSolo = false,
  shrink,
  'data-testid': dataTestId,
  ...rest
}: Props<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, name);

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <AutocompleteField<T, false, false, boolean>
            disablePortal
            onChange={(_e, newValue) => {
              field.onChange(newValue);
            }}
            freeSolo={freeSolo}
            value={field.value || null}
            disabled={disabled}
            {...rest}
            renderInput={(params) => (
              <TextField
                {...params}
                required={required}
                label={label}
                data-testid={dataTestId}
                error={Boolean(error)}
                name={autocomplete || name}
                InputLabelProps={{ shrink }}
                placeholder={placeholder}
                onChange={({ target: { value } }) => {
                  if (!freeSolo) {
                    field.onChange(null);
                    return;
                  }
                  field.onChange({ label: value, value });
                }}
              />
            )}
          />
        )}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};
