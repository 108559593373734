import { TextInput, useFormContext } from 'forms';
import { nanoid } from 'nanoid';
import { useTranslation } from 'next-export-i18n';
import { Box, Button, Grid, IconButton } from 'ui';
import { Delete } from 'ui/icons';

import { Owner } from '../../types';
import { initialOwner } from '../../utils/owners';

interface Props {
  fieldName: string;
  maxOwners: number;
}

const Owners = ({ fieldName, maxOwners }: Props) => {
  const { t } = useTranslation();

  const { watch, getValues, setValue } = useFormContext();
  const isDataAutoPopulated = !watch('isDataAutoPopulated');

  const owners: Owner[] = watch(fieldName);

  const addOwnerHandler = () => {
    setValue(fieldName, [...getValues(fieldName), { ...initialOwner, uid: nanoid() }]);
  };

  const removeOwnerHandler = (uid: string) => {
    setValue(
      fieldName,
      getValues(fieldName).filter((owner: Owner) => owner.uid !== uid)
    );
  };

  return (
    <>
      {owners.map(({ uid }, i, arr) => (
        <Box key={uid}>
          <Grid container spacing={2} alignItems="center" mt={0.5}>
            <Grid item xs={12} md={3.66}>
              <TextInput
                label={t('shared.inputLabels.firstName')}
                name={`${fieldName}.${i}.firstName`}
                required
                disabled={isDataAutoPopulated}
                autocomplete="firstName"
                data-testid={`owner-first-name-${i}`}
              />
            </Grid>
            <Grid item xs={12} md={3.66}>
              <TextInput
                label={t('shared.inputLabels.middleName')}
                disabled={isDataAutoPopulated}
                name={`${fieldName}.${i}.middleName`}
                autocomplete="middleName"
                data-testid={`owner-middle-name-${i}`}
              />
            </Grid>
            <Grid item xs={12} md={3.66}>
              <TextInput
                label={t('shared.inputLabels.lastName')}
                name={`${fieldName}.${i}.lastName`}
                required
                disabled={isDataAutoPopulated}
                autocomplete="lastName"
                data-testid={`owner-last-name-${i}`}
              />
            </Grid>
            <Grid item xs={12} md={1} container justifyContent="center">
              <IconButton
                data-testid="remove-owner"
                onClick={() => removeOwnerHandler(uid)}
                disabled={arr.length === 1 || isDataAutoPopulated}
                color="secondary"
              >
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}

      <Grid container spacing={2} alignItems="center" mt={0.5}>
        <Grid item xs={12} md={2.5}>
          <Button
            data-testid="add-owner"
            onClick={addOwnerHandler}
            disabled={owners.length === maxOwners || isDataAutoPopulated}
          >
            {t('shared.common.add')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Owners;
