import { MAX_FILE_SIZE, validateFileFormat, validateFileSize, yupResolver } from 'forms';
import logger from 'logger';
import { nanoid } from 'nanoid';
import { useTranslation } from 'next-export-i18n';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { addError, Errors } from 'rum';
import { RequestSlice } from 'store';
import { isError, PartiallyCompletedSwitchingRequest } from 'types';
import { Backdrop, Box, Button, Divider, Form, Logo, Paper, Typography } from 'ui';
import { array, mixed, object, string } from 'yup';

import { GOLDEN_SANDS } from '~/config/constants';
import { measuringPointLabel } from '~/config/measuringPointLabel';
import { sendFilesForUpload } from '~/files';
import { useSwitchingStore } from '~/store';
import { buildCustomerName } from '~/utils';
import { valueOrDefault } from '~/utils/valueOrDefault/valueOrDefault';

import { FormValues, Location } from '../types';
import { isErpEso, isErpGoldenSands, isErpNorth, isErpSouth, isErpWest, prepareFilesAndLocations } from '../utils';
import MeteringPointComponent from './MeteringPointComponent';

const initialLocation: Location = {
  address: '',
  file: null,
  meteringPoint: '',
  region: '',
  settlement: '',
  stationName: '',
  uid: nanoid(),
};

const initialFormValues: FormValues = {
  locations: [initialLocation],
};

const getInitialFormValues = (store: RequestSlice['form']): FormValues => {
  if (store?.locations) {
    return {
      locations: store?.locations.map((location) => {
        return {
          address: valueOrDefault(location, 'address', initialLocation),
          erp: valueOrDefault(location, 'erp', initialLocation),
          file: valueOrDefault(location, 'file', initialLocation),
          meteringPoint: valueOrDefault(location, 'meteringPoint', initialLocation),
          region: valueOrDefault(location, 'region', initialLocation),
          settlement: valueOrDefault(location, 'settlement', initialLocation),
          stationName: valueOrDefault(location, 'stationName', initialLocation),
          uid: valueOrDefault(location, 'uid', initialLocation),
        };
      }),
    };
  }
  return initialFormValues;
};

interface Props {
  onComplete?: (switchingRequest: PartiallyCompletedSwitchingRequest) => void;
  submitButtonLabel?: string;
  backButtonLabel?: string;
}

const MeteringPointsView = ({ onComplete, submitButtonLabel, backButtonLabel }: Props) => {
  const { t } = useTranslation();
  const { form, completeRequest, setStep } = useSwitchingStore();
  const [isLoading, setIsLoading] = useState(false);

  const requiredMessage = t('shared.formValidations.required');

  const { handleSubmit, getValues, setValue, watch, trigger, clearErrors, ...rest } = useForm<FormValues>({
    defaultValues: getInitialFormValues(form),
    // @ts-expect-error - resolver type is not correct
    resolver: yupResolver(
      object({
        locations: array().of(
          object({
            address: string().required(requiredMessage).nullable(),
            file: mixed<File>()
              .defined()
              .nullable()
              .test('isTooBig', t('shared.formValidations.bigFile', { size: MAX_FILE_SIZE }), validateFileSize)
              .test('isCorrectType', t('shared.formValidations.wrongFile'), validateFileFormat),
            meteringPoint: string()
              .when('region', {
                is: (val) => measuringPointLabel[val] === 'itn',
                then: () =>
                  string()
                    .required(requiredMessage)
                    .test(
                      'metering-point-validation',
                      t('shared.formValidations.meteringPointNumber'),
                      (value) => isErpSouth(value) || isErpEso(value)
                    ),
              })
              .when('region', {
                is: (val) => measuringPointLabel[val] === 'uin',
                then: () =>
                  string().when('settlement', {
                    is: (val) => val === GOLDEN_SANDS,
                    otherwise: () =>
                      string()
                        .required(requiredMessage)
                        .test(
                          'metering-point-validation',
                          t('shared.formValidations.meteringPointNumber'),
                          (value) => isErpNorth(value) || isErpEso(value)
                        ),
                    then: () =>
                      string()
                        .required(requiredMessage)
                        .test(
                          'metering-point-validation',
                          t('shared.formValidations.meteringPointNumber'),
                          (value) => isErpGoldenSands(value) || isErpEso(value)
                        ),
                  }),
              })
              .when('region', {
                is: (val) => measuringPointLabel[val] === 'meteringPoint',
                then: () =>
                  string()
                    .required(requiredMessage)
                    .test(
                      'metering-point-validation',
                      t('shared.formValidations.meteringPointNumber'),
                      (value) => isErpWest(value) || isErpEso(value)
                    ),
              })
              .when('region', {
                is: null,
                then: () => string().required(t('shared.formValidations.region')),
              }),
            previousUtility: string().when('region', {
              is: (val) => measuringPointLabel[val] === 'meteringPoint',
              then: () => string().required(requiredMessage),
            }),
            region: string().required(requiredMessage),
            settlement: string().required(requiredMessage),
            stationName: string().required(requiredMessage),
          })
        ),
      })
    ),
  });

  const addMeteringPointHandler = () => {
    setValue('locations', [...getValues('locations'), { ...initialLocation, uid: nanoid() }]);
  };

  const goBackClickHandler = () => {
    setStep('companyData');
    document.body.scrollIntoView({ block: 'start' });
  };

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    if (!form) {
      logger.debug('MeteringPointsView: onSubmit form is empty');
      return;
    }

    setIsLoading(true);

    try {
      const { files, meteringPoints } = prepareFilesAndLocations(
        formValues.locations,
        String(buildCustomerName(form.business?.companyName, form.business?.companyType, form.contact?.name))
      );

      if (files.length > 0) {
        const params = {
          companyName: String(
            form.business?.companyName
              ? `${form.business.companyName} ${form.business.companyType}`
              : form.contact?.name
          ),
          requestId: String(form.requestId),
        };
        await sendFilesForUpload(params, ...files);
      }

      const switchingRequest = await completeRequest({ ...formValues, locations: meteringPoints });

      if (onComplete && switchingRequest) {
        await onComplete(switchingRequest);
      }
    } catch (error) {
      if (isError(error)) {
        addError(Errors.UPLOAD_INVOICE_FAILURE, error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...{ clearErrors, getValues, handleSubmit, setValue, trigger, watch, ...rest }}>
      <Form id="contractForm" onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          {watch('locations').map(({ uid }, i, arr) => (
            <Box key={uid}>
              <MeteringPointComponent uid={uid} fieldName={`locations.${i}.`} />
              {arr.length - 1 !== i && <Divider />}
            </Box>
          ))}

          <Box mt={2}>
            <Button size="sm" onClick={addMeteringPointHandler}>
              {t('shared.common.addMeteringPoint')}
            </Button>
          </Box>

          <Box mt={2} display="flex" justifyContent="space-between">
            {backButtonLabel && <Button onClick={goBackClickHandler}>{backButtonLabel}</Button>}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
              <Paper sx={{ textAlign: 'center' }}>
                <Logo />
                <Box mt={4}>
                  <Typography variant="h5">{t('switching.meteringPointsOverlay.title')}</Typography>
                  <Typography marginTop={2} variant="subtitle1">
                    {t('switching.meteringPointsOverlay.description')}
                  </Typography>
                </Box>
              </Paper>
            </Backdrop>
            {submitButtonLabel && (
              <Button type="submit" loading={isLoading} data-testid="submit-metering-point">
                {submitButtonLabel}
              </Button>
            )}
          </Box>
        </Paper>
      </Form>
    </FormProvider>
  );
};

export default MeteringPointsView;
