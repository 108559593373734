export const DEFAULT_COUNTRY_BG = 'bg';
export const MAX_EIK_FIELD_LENGTH = 15;
export const MAX_COUNTRY_CODE_LENGTH = 4;
export const MIN_PID_LENGTH = 10;
export const MIN_BULSTAT_LENGTH = 9;
export const AREA_CITIES = ['гр. София', 'гр. Пловдив', 'гр. Варна'];
export const GOLDEN_SANDS = 'к.к. Златни Пясъци';

export const BILLING_VALUES = {
  blocks: {
    exchangeMetered: 1,
    exchangeMeteredGreen: 1,
  },
  fees: {
    exchangeFixed: 19.9,
    exchangeFixedGreen: 24.9,
    exchangeMetered: 19.9,
    exchangeMeteredGreen: 24.9,
    exchangePercent: 5,
    exchangePercentGreen: 5.5,
  },
  prices: {
    fixedFree: 899,
    fixedFreeGreen: 899,
    fixedStandard: 899,
    fixedStandardGreen: 899,
  },
};
