import { useFormContext } from 'react-hook-form';
import { CountryDropdown, CountryDropdownProps, SelectedCountry } from 'ui';

type Props = Omit<CountryDropdownProps, 'onSelect'>;

export const CountryFlagDropDown = ({ defaultCountry, name, disabled }: Props) => {
  const { setValue } = useFormContext();

  const handleSelect = ({ countryPhoneCode, countryIsoCode }: SelectedCountry) => {
    setValue(name, { country: countryIsoCode, countryCode: countryPhoneCode, value: '' });
  };

  return <CountryDropdown name={name} defaultCountry={defaultCountry} onSelect={handleSelect} disabled={disabled} />;
};
