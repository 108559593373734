import { useSwitchingStore } from '~/store';

export const useCustomNavigation = () => {
  const { setStep } = useSwitchingStore();

  const addPopstateEvent = () => {
    const changeStep = (e: PopStateEvent) => {
      if (e.state.page === 'redirect') window.location.href = e.state.to;
      else setStep(e.state.page);
    };
    window.addEventListener('popstate', changeStep);
  };

  const pushState = (page: string) => {
    window.history.pushState({ page }, page);
  };

  const replaceState = (page: string) => {
    window.history.replaceState({ page }, page);
  };

  return {
    addPopstateEvent,
    pushState,
    replaceState,
  };
};
