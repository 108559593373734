import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox as UiCheckbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText } from 'ui';

import { getError } from '../../utils';

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, name, disabled = false, autocomplete, required, onChange, ...rest }, ref: React.Ref<HTMLInputElement>) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();

    const error = getError(errors, name);

    return (
      <FormControl error={error ? Boolean(error) : undefined}>
        <FormControlLabel
          required={required}
          sx={rest.sx}
          control={
            <Controller
              name={name}
              control={control}
              render={({ field: props }) => (
                <UiCheckbox
                  {...props}
                  inputProps={
                    {
                      'aria-label': 'controlled',
                      'data-testid': rest['data-testid'],
                    } as React.InputHTMLAttributes<HTMLInputElement>
                  }
                  checked={props.value}
                  onChange={(e) => {
                    props.onChange(e.target.checked);

                    if (onChange) {
                      onChange(e, props.value);
                    }
                  }}
                  onBlur={(e) => {
                    e.preventDefault();
                  }}
                  error={error ? Boolean(error) : undefined}
                  name={autocomplete || name}
                  disabled={disabled}
                  ref={ref}
                />
              )}
            />
          }
          label={label}
        />
        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    );
  }
);
