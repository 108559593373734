import { Checkbox, FieldSet, useDependentFieldsTrigger, validatePhoneNumber, yupResolver } from 'forms';
import { FULL_NAME_REGEX, MOBILE_PHONES_LENGTH, NUMBERS_ONLY_REGEX } from 'forms/validations/constants';
import { useTranslation } from 'next-export-i18n';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { RequestSlice } from 'store';
import { Phone } from 'types';
import { Box, Button, countriesData, Form, Link, Typography } from 'ui';
import { boolean, mixed, object, string } from 'yup';

import { DEFAULT_COUNTRY_BG } from '~/config/constants';
import { useSwitchingStore } from '~/store';
import { removeEmptyFields, removeFields, valueOrDefault } from '~/utils';

interface FormValues {
  name: string;
  email: string;
  phone: { value: string; country: string; countryCode: string };
  fixedPhone: string;
  isSubscribedForMarketing?: boolean;
  gdpr: boolean;
  terms: boolean;
}

const initialFormValues = {
  email: '',
  fixedPhone: '',
  gdpr: false,
  isSubscribedForMarketing: false,
  name: '',
  phone: { country: DEFAULT_COUNTRY_BG, countryCode: countriesData[DEFAULT_COUNTRY_BG].countryCode, value: '' },
  terms: false,
};

const getInitialFormValues = (store: RequestSlice['form']): FormValues => {
  if (store?.contact) {
    return {
      email: valueOrDefault(store.contact, 'email', initialFormValues),
      fixedPhone: valueOrDefault(store.contact, 'fixedPhone', initialFormValues),
      gdpr: valueOrDefault(store.contact, 'gdpr', initialFormValues),
      isSubscribedForMarketing: valueOrDefault(store.contact, 'isSubscribedForMarketing', initialFormValues),
      name: valueOrDefault(store.contact, 'name', initialFormValues),
      phone: valueOrDefault(store.contact, 'phone', initialFormValues),
      terms: valueOrDefault(store.contact, 'terms', initialFormValues),
    };
  }

  return initialFormValues;
};

interface Props {
  onMailingDataComplete?: (values: FormValues) => void;
}

const MailingData = ({ onMailingDataComplete }: Props) => {
  const { t } = useTranslation();

  const { form, updateRequest } = useSwitchingStore();
  const [isLoading, setIsLoading] = useState(false);

  const requiredMessage = t('shared.formValidations.required');

  const resolver = yupResolver(
    object({
      email: string().required(requiredMessage).email(t('shared.formValidations.email')),
      fixedPhone: string(),
      gdpr: boolean().oneOf([true], requiredMessage),
      name: string()
        .required(requiredMessage)
        .matches(FULL_NAME_REGEX, { message: t('shared.formValidations.fullName') }),
      phone: object({
        country: string().required(requiredMessage),
        value: string().required(requiredMessage),
      }),
      terms: boolean().oneOf([true], requiredMessage),
    }).shape(
      {
        fixedPhone: string()
          .nullable()
          .when('phone', {
            is: ({ country, value }) =>
              country in MOBILE_PHONES_LENGTH
                ? value === MOBILE_PHONES_LENGTH[country]
                : value.length <= countriesData[country].countryCode.length,
            otherwise: () =>
              string()
                .notRequired()
                .test('emptyString', t('shared.formValidations.onlyNumbersField'), (value) =>
                  value ? NUMBERS_ONLY_REGEX.test(value) : value === ''
                ),
            then: () =>
              string()
                .required(t('shared.formValidations.phone'))
                .matches(NUMBERS_ONLY_REGEX, { message: t('shared.formValidations.onlyNumbersField') }),
          }),
        phone: mixed().when('fixedPhone', {
          is: (fixedPhone) => !fixedPhone,
          otherwise: () =>
            mixed<Phone>()
              .defined()
              .test(
                'withFixed',
                t('shared.formValidations.phone'),
                ({ country, value, countryCode }) => value === '' || validatePhoneNumber(country, countryCode, value)
              ),
          then: () =>
            mixed<Phone>()
              .defined()
              .test('withoutFixed', t('shared.formValidations.mobileOrFixedPhone'), ({ value, country, countryCode }) =>
                validatePhoneNumber(country, countryCode, value)
              ),
        }),
      },
      [
        ['phone', 'fixedPhone'],
        ['fixedPhone', 'phone'],
      ]
    )
  );

  const { handleSubmit, watch, trigger, ...rest } = useForm<FormValues>({
    defaultValues: getInitialFormValues(form),
    // @ts-expect-error - resolver type is not correct
    resolver,
  });

  useDependentFieldsTrigger<FormValues>(watch, trigger, ['phone', 'fixedPhone']);

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    const values = removeFields<FormValues, 'gdpr' | 'terms'>(formValues, ['gdpr', 'terms']);
    setIsLoading(true);

    const request = { contact: { ...removeEmptyFields(values) } };
    await updateRequest(request);

    if (onMailingDataComplete) onMailingDataComplete(formValues);
    setIsLoading(false);
  };

  return (
    <FormProvider {...{ handleSubmit, trigger, watch, ...rest }}>
      <Form id="contractForm" data-testid="company-info-form" onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h5" gutterBottom>
          {t('shared.common.mailingData')}
        </Typography>

        <Box sx={{ mb: 2, mt: 2 }}>
          <FieldSet
            columns="2"
            fields={[
              {
                autocomplete: 'name',
                'data-testid': `contactName`,
                label: t('shared.inputLabels.fullName'),
                name: 'name',
                required: true,
              },
              {
                autocomplete: 'email',
                'data-testid': `email`,
                label: t('shared.inputLabels.email'),
                name: 'email',
                required: true,
              },
              {
                autocomplete: 'phone',
                'data-testid': `phone`,
                inputType: 'phone',
                label: t('shared.inputLabels.phone'),
                name: 'phone',
                required: true,
              },
              {
                autocomplete: 'fixedPhone',
                'data-testid': `fixedPhone`,
                label: t('shared.inputLabels.fixedPhone'),
                name: 'fixedPhone',
              },
            ]}
          />
        </Box>

        <Checkbox
          label={
            <>
              {`${t('shared.common.readAndAgree')} `}
              <Link href={t('shared.files.terms')} newTab>
                {t('shared.common.termsOfUseHyperlink')}
              </Link>
            </>
          }
          required
          name="terms"
          data-testid="terms"
        />
        <Checkbox
          sx={{ mt: 2 }}
          label={
            <>
              {`${t('shared.common.dataProceeding', { subdomain: 'signup' })} `}
              <Link href={t('shared.files.gdpr')} newTab>
                {t('shared.common.personalDataPolicy')}
              </Link>
            </>
          }
          required
          name="gdpr"
          data-testid="gdpr"
        />
        <Checkbox
          sx={{ mt: 2 }}
          label={t('shared.common.marketingMessages')}
          name="isSubscribedForMarketing"
          data-testid="isSubscribedForMarketing"
        />

        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" data-testid="submit-mailing-data" loading={isLoading}>
            {t('shared.common.next')}
          </Button>
        </Box>
      </Form>
    </FormProvider>
  );
};

export default MailingData;
