import { Address } from 'types';

export const convertAddressStringToObject = (address: string): Address => {
  return address.split(',').reduce((acc: Record<string, string>, element: string) => {
    const cleanedElement = element.trim();
    const [key, ...rest] = cleanedElement.includes(':') ? cleanedElement.split(': ') : cleanedElement.split(' ');
    const value = rest.join(' ');

    switch (key) {
      case 'Област':
        acc.region = value;
        return acc;
      case 'Община':
        acc.municipality = value;
        return acc;
      case 'Населено място':
        acc.settlement = value;
        return acc;
      case 'п.к.':
        acc.postCode = value;
        return acc;
      case 'р-н':
        acc.area = value;
        return acc;
      default:
        acc.street = acc.street ? `${acc.street}, ${cleanedElement}` : cleanedElement;
        return acc;
    }
  }, {});
};
