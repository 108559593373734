import { countriesData, CountryIsoCode } from 'ui';

import { MOBILE_PHONE_LENGTH_BG, NUMBERS_ONLY_REGEX } from '../constants';

type SupportedCountries = 'bg';

const isSupportedCountry = (value: string): value is SupportedCountries => ['bg'].includes(value);

export const phoneValidationSchema = {
  bg: (value: string) => {
    switch (value.slice(0, 2)) {
      case '87':
      case '88':
      case '89':
      case '97':
      case '98':
      case '99':
        return value.length === MOBILE_PHONE_LENGTH_BG;
      default:
        return false;
    }
  },
};

export const validatePhoneNumber = (country: CountryIsoCode, countryCode: string, value: string) => {
  if (value.length === 0) return false;

  if (!NUMBERS_ONLY_REGEX.test(value)) return false;

  if (isSupportedCountry(country)) {
    return phoneValidationSchema[country](value);
  }

  if (countryCode !== countriesData[country].countryCode) return false;

  return true;
};
