import { TextInput } from 'forms';
import { useTranslation } from 'next-export-i18n';
import { Grid, Typography } from 'ui';

const IndividualData = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('switching.common.individual')}
      </Typography>

      <Grid container spacing={2} alignItems="center" mt={2} mb={4}>
        <Grid item xs={12} md={4}>
          <TextInput label={t('shared.inputLabels.egn')} name="pid" required data-testid="pid" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput label={t('shared.inputLabels.bulstat')} name="bulstat" data-testid="bulstat" />
        </Grid>
      </Grid>
    </>
  );
};

export default IndividualData;
