import logger from 'logger';
import { ComponentProps } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FileSelectField } from 'ui';

import { getError } from '../../utils';

interface Props extends ComponentProps<typeof FileSelectField> {
  name: string;
}
export const InputFile = ({ name, disabled = false, ...rest }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange, value, ...restField } = field;
        const fileName = value && value.name ? value.name : '';
        return (
          <FileSelectField
            id={name}
            disabled={disabled}
            {...restField}
            {...rest}
            onChange={(e) => {
              if (e.target?.files && e.target?.files.length) {
                onChange(e.target?.files[0]);
              }
              logger.debug(`no file selected for input field ${name}`);
            }}
            error={error?.message}
            value={fileName}
          />
        );
      }}
    />
  );
};
