import { useTranslation } from 'next-export-i18n';
import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from 'ui';

interface Props {
  src: string;
}

const MeteringPointParticle = ({ src }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Typography>
        {`${t('switching.pointInfo.invoiceInfo')}. `}
        <Box component="span" color="secondary.main" sx={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}>
          {t('switching.pointInfo.example')}
        </Box>
      </Typography>

      <Dialog onClose={handleClose} open={showModal} maxWidth="md">
        <DialogContent>
          <img src={`/images/measuringPointLabels/${src}.jpg`} alt="invoice" />
          <Typography>{t('switching.pointInfo.exampleInfo')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('shared.common.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MeteringPointParticle;
