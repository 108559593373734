export const autoDownloadFile = async (buffer: Buffer | Uint8Array, fileName: string) => {
  const blob = new Blob([buffer], { type: 'application/pdf' });
  const link = URL.createObjectURL(blob);

  const anchorElement = document.createElement('a');

  anchorElement.href = link;

  anchorElement.setAttribute('download', fileName);

  anchorElement.setAttribute('target', '_blank');

  document.body.appendChild(anchorElement);

  anchorElement.click();

  anchorElement.remove();

  window.open(link);
};
