import { Grid, TextFieldProps } from 'ui';

import { NumberInput } from '../NumberInput/NumberInput';
import { PhoneInput } from '../PhoneInput/PhoneInput';
import { TextInput } from '../TextInput/TextInput';

export const width = {
  '1': 12,
  '2': 6,
  '3': 4,
  '4': 3,
};

export type FieldsSetFieldProps = TextFieldProps & {
  autocomplete?: string;
  label: string;
  name: string;
  inputType?: 'phone' | 'text' | 'number';
};

export type Props = {
  fields: FieldsSetFieldProps[];
  columns?: keyof typeof width;
};

export const FieldSet = ({ fields, columns = '3' }: Props) => (
  <Grid container spacing={3}>
    {fields.map(({ inputType = 'text', label, ...rest }) => {
      if (inputType === 'number') {
        return (
          <Grid key={label} item xs={12} md={6} lg={6} xl={width[columns]}>
            <NumberInput {...rest} key={label} label={label} />
          </Grid>
        );
      }

      if (inputType === 'phone') {
        return (
          <Grid key={label} item xs={12} md={6} lg={6} xl={width[columns]}>
            <PhoneInput {...rest} key={label} label={label} />
          </Grid>
        );
      }

      return (
        <Grid key={label} item xs={12} md={6} lg={6} xl={width[columns]}>
          <TextInput {...rest} key={label} label={label} />
        </Grid>
      );
    })}
  </Grid>
);
