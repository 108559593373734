import { Controller, useFormContext } from 'react-hook-form';
import { NumberField, TextFieldProps } from 'ui';

import { getError } from '../../utils';

export const NumberInput = ({ name, autocomplete, onBlur, ...rest }: Omit<TextFieldProps, 'type'>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <NumberField
          id={name}
          error={Boolean(error)}
          helperText={error?.message}
          onChange={(e) => field.onChange(Number(e.target.value))}
          name={autocomplete || name}
          inputRef={field.ref}
          value={field.value || ''}
          {...rest}
          {...(onBlur && { onBlur })}
          {...rest}
        />
      )}
    />
  );
};
