import { ACCEPTED_FILE_FORMATS, Autocomplete, InputFile, TextInput } from 'forms';
import { useTranslation } from 'next-export-i18n';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Grid, Typography } from 'ui';
import { Delete } from 'ui/icons';

import { GOLDEN_SANDS } from '~/config/constants';
import { measuringPointLabel } from '~/config/measuringPointLabel';
import { regions } from '~/config/regions';
import { settlements } from '~/config/settlements';
import { useSwitchingStore } from '~/store';

import MeteringPointParticle from './MeteringPointParticle';

const energyUtilityOptions = [
  'АЕЦ КОЗЛОДУЙ ЕАД',
  'АКТАЕЛ ЕООД',
  'АЛПИК ЕНЕРГИЯ БЪЛГАРИЯ ЕООД',
  'АРЕСЕНЕРДЖИ ЕООД',
  'АРМАКО ЕНЕРДЖИ ЕАД',
  'АСМ ЕНЕРДЖИ ООД',
  'БОЛКАН ЛОДЖИК ООД - КОМБИНИРАНА',
  'БОЛКАН ЛОДЖИК ООД - СТАНДАРТНА',
  'ВИК ЕНЕРДЖИ ГРУП ЕООД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ГРАНД ЕНЕРДЖИ ДИСТРИБЮШЪН ЕООД',
  'ГРАНД ЕНЕРДЖИ ДИСТРИБЮШЪН ЕООД - ПРОИЗВОДИТЕЛИ',
  'ГРИЙН ЕНЕРДЖИ ТРЕЙД ООД',
  'ЕВН БЪЛГАРИЯ ЕЛЕКТРОСНАБДЯВАНЕ ЕАД',
  'ЕВН ТРЕЙДИНГ САУТ ИЙСТ ЮРЪП EАД',
  'ЕЛ БОНУС ЕООД - КОМБИНИРАНА',
  'ЕЛ БОНУС ЕООД - ПРОИЗВОДИТЕЛИ',
  'ЕЛ ЕКС КОРПОРЕЙШЪН АД',
  'ЕЛЕКТРОХОЛД ПРОДАЖБИ АД - ДПИ',
  'ЕЛЕКТРОХОЛД ПРОДАЖБИ АД - КРАЕН СНАБДИТЕЛ',
  'ЕЛЕКТРОХОЛД ПРОДАЖБИ АД - ТЪРГОВИЯ',
  'ЕЛЕКТРОХОЛД ТРЕЙД ЕАД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЕЛНОВА ЕАД',
  'ЕМ ЕН ЕР ДЖИ ЛИМИТИД',
  'ЕНЕКОД АД',
  'ЕНЕКОД ГРИЙН ЕАД',
  'ЕНЕРГАМА АД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЕНЕРГЕО ЕООД',
  'ЕНЕРГИЙНИ ИНОВАТИВНИ ТЕХНОЛОГИИ ЕООД',
  'ЕНЕРГО-ПРО ЕНЕРГИЙНИ УСЛУГИ EАД',
  'ЕНЕРГО-ПРО ПРОДАЖБИ АД',
  'ЕНЕРГОВИА ЕООД',
  'ЕНЕРГОИНВЕСТМЪНТ АД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЕНЕРДЖИ ИНВЕСТ ЮРЪП АД',
  'ЕНЕРДЖИ МАРКЕТ АД',
  'ЕНЕРДЖИ МАРКЕТ ГЛОБАЛ ООД',
  'ЕНЕРДЖИ МТ EАД',
  'ЕНЕРДЖИ ОПЕРЕЙШЪНС ООД ("КААЛЕКС-ЕНЕРДЖИ" ООД)',
  'ЕНЕРДЖИ СЪПЛАЙ EООД',
  'ЕНЕРДЖИ СЪПЛАЙ ГРИЙН EООД',
  'ЕОН БЪЛГАРИЯ 1 - ПРОИЗВОДИТЕЛИ КОМБИНИРАНА',
  'ЕОН БЪЛГАРИЯ 1 - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЕСП ЗЛАТНИ ПЯСЪЦИ ООД',
  'ЗАГОРА ЕНЕРДЖИ ООД',
  'ЗЛАТНА ПАНЕГА ЦИМЕНТ АД',
  'ИН АУТ ЕНЕРДЖИ ООД',
  'КОМПАНИЯ ЗА ЕНЕРГЕТИКА И РАЗВИТИЕ ООД',
  'КУМЕР ООД',
  'ЛОНИКО ЕООД',
  'МЕТ ЕНЕРДЖИ ТРЕЙДИНГ ЕАД',
  'МОСТ ЕНЕРДЖИ АД',
  'НЕК ЕАД',
  'НУМАТ ЕНЕРДЖИ КЪМПАНИ ЕООД',
  'ПАУЪР СИСТ ЕООД',
  'ПРОАКТ ООД - ПРОИЗВОДИТЕЛИ КОМБИНИРАНА',
  'ПРОАКТ ООД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'РИТЪМ-4-ТБ ООД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'САЙТ ЕНЕРДЖИ ЕООД',
  'СИ ЕНЕРДЖИ ГРУП ЕАД',
  'СИНЕРГОН ЕНЕРДЖИ ЕООД',
  'СМАРТ ЕНЕРДЖИ ТРЕЙД ЕАД',
  'СОЛАР СВ ООД',
  'СОНЕЛ 888 ЕООД',
  'СТОК ЕНЕРДЖИ ЕООД',
  'ТЕРА КАП ЕООД',
  'ТМ-ТЕХНОЛОДЖИ АД',
  'ТОПЛОФИКАЦИЯ СОФИЯ ЕАД',
  'ТЪРГОВСКА КОМПАНИЯ НА ВЪЗОБНОВЯЕМА ЕНЕРГИЯ ООД',
  'ФАКТОР ЕНЕРДЖИ БЪЛГАРИЯ - ПРОИЗВОДИТЕЛИ',
  'ХИДРО ПАУЪР ЮТИЛИТИС ЕООД - ПРОИЗВОДИТЕЛИ СТАНДАРТНА',
  'ЮРОПИАН ТРЕЙД ОФ ЕНЕРДЖИ АД',
];

interface Props {
  fieldName: string;
  uid: string;
}

const MeteringPointComponent = ({ fieldName, uid }: Props) => {
  const { t } = useTranslation();
  const { form } = useSwitchingStore();
  const { watch, clearErrors, getValues, setValue } = useFormContext();

  const meteringPointRegion = watch(`${fieldName}region`);
  const meteringPointSettlement = watch(`${fieldName}settlement`);

  const [selectedRegion, setSelectedRegion] = useState<string | null>(meteringPointRegion);
  const [selectedSettlement, setSelectedSettlement] = useState<string | null>(meteringPointSettlement);

  const autofillAddress = () => {
    if (!form || !form?.address) {
      return;
    }

    const { region, settlement, street } = form.address;
    setValue(`${fieldName}region`, region);
    setValue(`${fieldName}settlement`, settlement);
    setValue(`${fieldName}address`, street);
    clearErrors(`${fieldName}region`);
    clearErrors(`${fieldName}settlement`);
    clearErrors(`${fieldName}street`);

    setSelectedRegion(form.address?.region ?? null);
    setSelectedSettlement(form.address?.settlement ?? null);
  };

  useEffect(() => {
    if (meteringPointRegion !== selectedRegion) {
      setSelectedRegion(meteringPointRegion);
      setValue(`${fieldName}settlement`, null);
      setValue(`${fieldName}address`, '');
    }

    if (meteringPointSettlement !== selectedSettlement) {
      setSelectedSettlement(meteringPointSettlement);
      setValue(`${fieldName}address`, '');
    }
  }, [meteringPointRegion, setValue, fieldName, selectedRegion, selectedSettlement, meteringPointSettlement]);

  const removeStationHandler = () => {
    setValue(
      'locations',
      getValues('locations').filter((meteringPoint) => meteringPoint.uid !== uid)
    );
  };

  return (
    <>
      <Box display="flex" flexDirection={{ md: 'row', xs: 'column' }}>
        <Typography variant="h5" mr={1}>
          {t('shared.common.siteAddress')}
        </Typography>
        <Box mt={{ md: 0, xs: 1 }}>
          <Button size="sm" onClick={autofillAddress} data-testid="fill-administrative-address">
            {t('shared.common.administrativeAddress')}
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2} alignItems="flex-start" mt={2} mb={4}>
        <Grid item xs={12} lg={4}>
          <Autocomplete
            label={`${t(`shared.inputLabels.region`)}`}
            name={`${fieldName}region`}
            autocomplete="region"
            options={regions}
            required
          />
        </Grid>
        {meteringPointRegion && (
          <Grid item xs={12} lg={4}>
            <Autocomplete
              label={`${t(`shared.inputLabels.settlement`)}`}
              name={`${fieldName}settlement`}
              autocomplete="settlement"
              required
              options={typeof meteringPointRegion === 'string' ? settlements[meteringPointRegion] : []}
            />
          </Grid>
        )}
        {meteringPointSettlement && (
          <Grid item xs={12} lg={4}>
            <TextInput
              label={`${t('shared.inputLabels.address')}`}
              name={`${fieldName}address`}
              placeholder={`${t('shared.common.addressPlaceholder')}`}
              autocomplete="address"
              required
            />
          </Grid>
        )}
      </Grid>

      <Typography variant="h5">{t('switching.common.identifier')}</Typography>

      <Grid container spacing={2} alignItems="flex-start" mt={2} mb={4}>
        <Grid item xs={12} lg={4}>
          <TextInput
            autocomplete="meteringPoint"
            data-testid="metering-point-uid"
            label={t('shared.common.meteringPoint')}
            name={`${fieldName}meteringPoint`}
            required
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextInput
            autocomplete="stationName"
            data-testid="metering-point-name"
            label={t('shared.common.stationName')}
            name={`${fieldName}stationName`}
            required
          />
        </Grid>
        {measuringPointLabel[meteringPointRegion] === 'meteringPoint' && (
          <Grid item xs={12} lg={4}>
            <Autocomplete
              label={t('switching.meteringPointLabels.previousUtility')}
              name={`${fieldName}previousUtility`}
              required
              fullWidth
              options={energyUtilityOptions}
            />
          </Grid>
        )}
      </Grid>

      {measuringPointLabel[meteringPointRegion] && (
        <Box mt={4}>
          <Box>
            <Typography>{t('switching.meteringPointLabels.formats')}</Typography>
            <Typography>
              {t(
                `switching.meteringPointLabels.${
                  meteringPointSettlement === GOLDEN_SANDS ? 'goldenSands' : measuringPointLabel[meteringPointRegion]
                }`
              )}
            </Typography>
          </Box>
          <MeteringPointParticle src={measuringPointLabel[meteringPointRegion]} />
        </Box>
      )}

      <Box mb={2}>
        <Typography variant="h6" my={2}>
          {t('switching.common.invoice')}
        </Typography>
        <Typography my={2}>{t('switching.common.invoiceInfo')}</Typography>
        <InputFile
          label={t('switching.common.attachFile')}
          name={`${fieldName}file`}
          fileFormats={ACCEPTED_FILE_FORMATS}
          helperText={t('switching.common.acceptedFormats', { formats: ACCEPTED_FILE_FORMATS.join(', ') })}
          data-testid={`${fieldName}file`}
        />
      </Box>

      {watch('locations').length > 1 && (
        <Button onClick={removeStationHandler} endIcon={<Delete fontSize="small" />} size="sm">
          {t('shared.common.removeMeteringPoint')}
        </Button>
      )}
    </>
  );
};

export default MeteringPointComponent;
