import { ContractDownloadForm } from './ContractDownloadForm';

interface Props {
  onDownload: () => void;
  onComplete: () => void;
}
const ContractGenerationView = ({ onComplete, onDownload }: Props) => {
  return <ContractDownloadForm onComplete={onComplete} onDownload={onDownload} />;
};

export default ContractGenerationView;
