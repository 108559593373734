import { AnalyticsEvents, getRequestContractNumber, trackEvent } from 'firebase-client';
import logger from 'logger';
import { useTranslation } from 'next-export-i18n';
import React, { useState } from 'react';
import { addError, Errors } from 'rum';
import { isError } from 'types';
import { Button } from 'ui';

import { useSwitchingStore } from '~/store';

import { generateContract } from '../utils/generateContract/generateContract';

export const ContractDownloadButton = ({ onDownload }: { onDownload: (contract: Buffer) => void }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { form } = useSwitchingStore();

  const handleDownload = async () => {
    if (!form) {
      logger.debug('form is not available');
      return;
    }

    setIsLoading(true);

    try {
      const contractNumber = await getRequestContractNumber(form.requestId);

      if (!contractNumber) {
        logger.debug('Contract number is not available');
        return;
      }

      const fileContent = await generateContract({
        requestId: form.requestId,
        type: form.type,
      });

      onDownload(fileContent);

      trackEvent(AnalyticsEvents.sFormContractDownload, { planName: form.plan });
    } catch (error) {
      if (isError(error)) {
        addError(Errors.DOWNLOAD_GENERATED_CONTRACT_FAILURE, error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button onClick={handleDownload} loading={isLoading} data-testid="download-contract">
      {t('switching.common.downloadContract')}
    </Button>
  );
};
