import { Autocomplete, TextInput } from 'forms';
import { useTranslation } from 'next-export-i18n';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from 'ui';

import { areas } from '~/config/areas';
import { AREA_CITIES } from '~/config/constants';
import { regions } from '~/config/regions';
import { settlements } from '~/config/settlements';

import InvoicingData from './InvoicingData/InvoicingData';

interface Props {
  fieldName: string;
}

const AddressContent = ({ fieldName }: Props) => {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext();

  const isDataAutoPopulated = !watch('isDataAutoPopulated') && watch('isBusiness');

  const region = watch(`${fieldName}.region`);
  const settlement = watch(`${fieldName}.settlement`);

  const [selectedRegion, setSelectedRegion] = useState<string | null>(region);
  const [selectedSettlement, setSelectedSettlement] = useState<string | null>(settlement);

  useEffect(() => {
    if (region !== selectedRegion) {
      setSelectedRegion(region);

      if (settlement === selectedSettlement) setValue(`${fieldName}.settlement`, null);
    }

    if (settlement !== selectedSettlement) {
      setSelectedSettlement(settlement);
    }
  }, [fieldName, region, settlement, selectedRegion, selectedSettlement, setValue]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <TextInput
            required
            autocomplete="post-code"
            data-testid="post-code"
            label={`${t('shared.inputLabels.postCode')}`}
            disabled={isDataAutoPopulated}
            name={`${fieldName}.postCode`}
            placeholder={`${t('shared.common.postCode')}`}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Autocomplete
            label={`${t(`shared.inputLabels.region`)}`}
            data-testid="region"
            name={`${fieldName}.region`}
            autocomplete="region"
            required
            disabled={isDataAutoPopulated}
            options={regions}
          />
        </Grid>
        {region && (
          <Grid item xs={12} lg={4}>
            <Autocomplete
              label={`${t(`shared.inputLabels.settlement`)}`}
              name={`${fieldName}.settlement`}
              autocomplete="settlement"
              required
              disabled={isDataAutoPopulated}
              data-testid="settlement"
              options={selectedRegion ? settlements[selectedRegion] : []}
            />
          </Grid>
        )}
        {AREA_CITIES.includes(settlement) && (
          <Grid item xs={12}>
            <Autocomplete
              label={`${t(`shared.inputLabels.area`)}`}
              name={`${fieldName}.area`}
              autocomplete="area"
              required
              disabled={isDataAutoPopulated}
              data-testid="area"
              options={selectedSettlement ? areas[selectedSettlement.split(' ')[1]] : []}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextInput
            required
            data-testid="street"
            autocomplete="address"
            label={`${t('shared.inputLabels.address')}`}
            disabled={isDataAutoPopulated}
            name={`${fieldName}.street`}
            placeholder={`${t('shared.common.addressPlaceholder')}`}
          />
        </Grid>
      </Grid>

      <InvoicingData />
    </>
  );
};

export default AddressContent;
