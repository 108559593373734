export const regions = [
  'Благоевград',
  'Бургас',
  'Варна',
  'Велико Търново',
  'Видин',
  'Враца',
  'Габрово',
  'Добрич',
  'Кърджали',
  'Кюстендил',
  'Ловеч',
  'Монтана',
  'Пазарджик',
  'Перник',
  'Плевен',
  'Пловдив',
  'Разград',
  'Русе',
  'Силистра',
  'Сливен',
  'Смолян',
  'София',
  'София (столица)',
  'Стара Загора',
  'Търговище',
  'Хасково',
  'Шумен',
  'Ямбол',
];
