import { initServerGrowthBook } from './growthbook';

export const getGrowthBookPayload = async () => {
  const growthBook = initServerGrowthBook();
  await growthBook.init({ streaming: true, timeout: 1000 });

  const payload = growthBook.getDecryptedPayload();

  growthBook.destroy();

  return { payload };
};
