import { FILE_FORMATS } from 'types';

const MOBILE_PHONE_LENGTH_BG = 9;
const ACCEPTED_FILE_FORMATS = Object.values(FILE_FORMATS);
const MAX_FILE_SIZE = 4; // MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE * 1024 * 1024; // bytes
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const FULL_NAME_REGEX = /(\p{L}+) (\p{L}+)/u;
const NUMBERS_ONLY_REGEX = /^\d+$/;
const MOBILE_PHONES_LENGTH = {
  bg: 9,
};
const PID_LENGTH = 10;

export {
  ACCEPTED_FILE_FORMATS,
  FULL_NAME_REGEX,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_BYTES,
  MOBILE_PHONE_LENGTH_BG,
  MOBILE_PHONES_LENGTH,
  NUMBERS_ONLY_REGEX,
  PID_LENGTH,
};
