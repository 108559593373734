import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from 'ui';

import { getError } from '../../utils';

export const TextInput = ({
  name,
  autocomplete,
  label,
  placeholder,
  onBlur,
  required = false,
  disabled = false,
  'data-testid': dataTestId,
  uppercase = false,
  shrink,
  ...rest
}: TextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          fullWidth
          required={required}
          label={label}
          id={name}
          placeholder={placeholder}
          error={Boolean(error)}
          helperText={error?.message}
          value={field.value}
          onChange={({ target: { value } }) => field.onChange(uppercase ? value.toUpperCase() : value)}
          name={autocomplete || name}
          inputRef={field.ref}
          {...(onBlur && { onBlur })}
          disabled={disabled}
          inputProps={{ autoComplete: autocomplete, 'data-testid': dataTestId }}
          InputLabelProps={{ shrink }}
          {...rest}
        />
      )}
    />
  );
};
