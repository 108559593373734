import { overwriteFileName, sendFilesForUpload } from '~/files';

import { buildContractFileName } from './buildContractFileName';

export const uploadSignedContract = async ({
  file,
  contractNumber,
  customerName,
  requestId,
}: {
  file: File;
  contractNumber: string;
  customerName: string;
  requestId: string;
}) => {
  overwriteFileName(file, buildContractFileName(contractNumber, customerName, 'signed'));

  await sendFilesForUpload(
    {
      companyName: customerName,
      requestId: String(requestId),
    },
    file
  );
};
