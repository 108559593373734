import { TradeRegistryCompanyData } from '../../types';
import { prepareCompanyData } from '../prepareCompanyData/prepareCompanyData';

const TRUVITY_TOKEN = 'cac5487ab4c65ac570b166d59610479aac428776';

const isValidData = (data: Record<string, unknown>): data is TradeRegistryCompanyData =>
  data && Boolean(data.company_id) && !('detail' in data);
export const getCompany = async (eik: string) => {
  const data = await fetch(`https://platform.truvity.net/api/companies/${eik}/`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${TRUVITY_TOKEN}`,
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json());

  if (!isValidData(data)) {
    return null;
  }

  return prepareCompanyData(data);
};
