import { Controller, useFormContext } from 'react-hook-form';
import { CountryIsoCode, PhoneField, PhoneFieldProps, Stack } from 'ui';

import { getError } from '../../utils';
import { CountryFlagDropDown } from '../CountryFlagDropDown/CountryFlagDropDown';

type Props = PhoneFieldProps & {
  defaultCountry?: CountryIsoCode;
};

export const PhoneInput = ({
  name,
  autocomplete,
  label,
  required = false,
  disabled = false,
  placeholder,
  defaultCountry = 'bg',
  ...rest
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, name);

  return (
    <Stack direction="row">
      <CountryFlagDropDown defaultCountry={defaultCountry} name={name} disabled={disabled} />

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <PhoneField
            required={required}
            fullWidth
            label={label}
            id={name}
            placeholder={placeholder}
            error={Boolean(error)}
            disabled={disabled}
            helperText={error?.message}
            value={field.value?.value}
            onChange={(e) => field.onChange({ ...field.value, value: e.target.value })}
            name={autocomplete || name}
            inputRef={field.ref}
            inputProps={{ 'data-testid': rest['data-testid'] }}
          />
        )}
      />
    </Stack>
  );
};
