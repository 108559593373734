export const municipalities = [
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Абланица' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Абланица' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Абланица' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Абрит' },
  { municipality: 'Якоруда', region: 'Благоевград', settlement: 'с. Аврамово' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Аврамово' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Аврен' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Аврен' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Агатово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Азманите' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Айдемир' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Айрово' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'гр. Айтос' },
  { municipality: 'Белово', region: 'Пазарджик', settlement: 'с. Аканджиево' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Акациево' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'гр. Аксаково' },
  { municipality: 'Златоград', region: 'Смолян', settlement: 'с. Аламовци' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Албанци' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Алваново' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Алдомировци' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Алеко Константиново' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Алеково' },
  { municipality: 'Алфатар', region: 'Силистра', settlement: 'с. Алеково' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Александрия' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Александрово' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Александрово' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Александрово' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Александрово' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Александрово' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Александрово' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'с. Александрово' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Александрово' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Александър Стамболийски' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Алендарова' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Алиговска' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Алино' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Алтимир' },
  { municipality: 'Алфатар', region: 'Силистра', settlement: 'гр. Алфатар' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Алцек' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Ангел войвода' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Ангеларий' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Ангелов' },
  { municipality: 'Сопот', region: 'Пловдив', settlement: 'с. Анево' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Антимово' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Антимово' },
  { municipality: 'Антон', region: 'София', settlement: 'с. Антон' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'гр. Антоново' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Аплаци' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Априлово' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Априлово' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Априлово' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Априлци' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Априлци' },
  { municipality: 'Априлци', region: 'Ловеч', settlement: 'гр. Априлци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Арбанаси' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Арда' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'гр. Ардино' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Арзан' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Арковна' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Армените' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Армянковци' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Арнаутито' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Арпаджик' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Арчар' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Асен' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'гр. Асеновград' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Асеновец' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Асеново' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Асеново' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Асеново' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Асеновци' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Аспарухово' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Аспарухово' },
  { municipality: 'Медковец', region: 'Монтана', settlement: 'с. Аспарухово' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Аспарухово' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Атия' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Атолово' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Ауста' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'гр. Ахелой' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Ахматово' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Ахрянско' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'гр. Ахтопол' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Баба Тонка' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Бабек' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Бабино' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Бабинска река' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Бабинци' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Бабица' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Баблон' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Бабово' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Бабук' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Бабяк' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Багалевци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Багра' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Багренци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Багрилци' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Багрянка' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Бадевци' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Бадино' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Баевци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Баевци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Баждари' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Байкал' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Байкалско' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Байково' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Байлово' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Бакалите' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Бакьово' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Балабаново' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Балабанско' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Балалея' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Баланите' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Баланово' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Балван' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Балванците' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Балдево' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'с. Балей' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Балик' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Балиновци' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Балкан' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Балкан махала' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Балканец' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Балкански' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Балканци' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Балканци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Балуци' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'гр. Балчик' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Балша' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Бальовци' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Балювица' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Банари' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Бангейци' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Баниска' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Баните' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Баница' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Баничан' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Банище' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Банковец' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Банковци' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Банкя' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'гр. Банкя' },
  { municipality: 'Суворово', region: 'Варна', settlement: 'с. Баново' },
  { municipality: 'Банско', region: 'Благоевград', settlement: 'гр. Банско' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Банчовци' },
  { municipality: 'Разлог', region: 'Благоевград', settlement: 'с. Баня' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Баня' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'с. Баня' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'гр. Баня' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Баня' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Бараково' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Бараци' },
  { municipality: 'Доспат', region: 'Смолян', settlement: 'с. Барутин' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Басарбово' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Баскалци' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Бата' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Батак' },
  { municipality: 'Батак', region: 'Пазарджик', settlement: 'гр. Батак' },
  { municipality: 'Перник', region: 'Перник', settlement: 'гр. Батановци' },
  { municipality: 'Борово', region: 'Русе', settlement: 'с. Батин' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Батишница' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Батово' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Батошево' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Батулия' },
  { municipality: 'Ябланица', region: 'Ловеч', settlement: 'с. Батулци' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Баурене' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Бахалин' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Баховица' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Бахреци' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Бацова махала' },
  { municipality: 'Разлог', region: 'Благоевград', settlement: 'с. Бачево' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Бачково' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Башево' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Бащино' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Бащино' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Бащино' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Баячево' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Бдинци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Беброво' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Беглеж' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Бегово' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Бегуновци' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Бегунци' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Беден' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Беджене' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Бежаново' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Бежаново' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Безводица' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Безводно' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Безден' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Безденица' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Безмер' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Безмер' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Бейковци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Бекриите' },
  { municipality: 'Якоруда', region: 'Благоевград', settlement: 'с. Бел камен' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Бела' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Бела Рада' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Беланица' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Беласица' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Белащица' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Белгун' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Белев дол' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Белевехчево' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Белеврен' },
  { municipality: 'Белене', region: 'Плевен', settlement: 'гр. Белене' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Беленци' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Бели брег' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Бели брод' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Бели бряг' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Бели вир' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Бели дол' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Бели извор' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Бели Искър' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Бели Лом' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Бели Осъм' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Бели пласт' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Белила' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'с. Белимел' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Белинци' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'гр. Белица' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Белица' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Белица' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Белица' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'с. Белица' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Белица' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Белиш' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Бело поле' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Бело поле' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Беловец' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Беловица' },
  { municipality: 'Белово', region: 'Пазарджик', settlement: 'гр. Белово' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Белоградец' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'гр. Белоградчик' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Белодол' },
  { municipality: 'Раковски', region: 'Пловдив', settlement: 'с. Белозем' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Белокопитово' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Беломорци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Беломъжите' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Белополци' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Белополяне' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Белопопци' },
  { municipality: 'Белослав', region: 'Варна', settlement: 'гр. Белослав' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Белотинци' },
  { municipality: 'Ценово', region: 'Русе', settlement: 'с. Белцов' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Белчевци' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Белчин' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Белчински бани' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Бельово' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Беляковец' },
  { municipality: 'Ценово', region: 'Русе', settlement: 'с. Беляново' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Бенковски' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Бенковски' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Бенковски' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Бенковски' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Бенковски' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Бенковски' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Берайнци' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Беренде' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Беренде' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Беренде извор' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Бериево' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'гр. Берковица' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Берковски' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Берковци' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Бероново' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Берсин' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Беслен' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Беснурка' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Биволаре' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Биволяне' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Бижовци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Бижовци' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Биково' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Билинци' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Билка' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Билкини' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Било' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Билянска' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Бинкос' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Биркова' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Бисер' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Бисерци' },
  { municipality: 'Алфатар', region: 'Силистра', settlement: 'с. Бистра' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Бистра' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Бистренци' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Бистрец' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Бистрец' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Бистрилица' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Бистрица' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Бистрица' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Бистрица' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Бистроглед' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Благово' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Благово' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'гр. Благоевград' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Благоево' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Благоево' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Благун' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Блажиево' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Блатец' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Блатец' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Блатешница' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Блатино' },
  { municipality: 'Стрелча', region: 'Пазарджик', settlement: 'с. Блатница' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Блато' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Блатска' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Бленика' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Близнак' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Близнаци' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Близнаци' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'с. Близнец' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Блъсково' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Блъсковци' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Боазът' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Бобевци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Бобешино' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'гр. Бобов дол' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Бобовец' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Бобораци' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'гр. Бобошево' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Бов' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Бов' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Богатино' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Богатово' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Богдан' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Богдан' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Богданица' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Богданлия' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Богданов дол' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Богданово' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Богданово' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Богдановци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Богданско' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Богданци' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Богданци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Богданчовци' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Богойна' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Боголин' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Богомил' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Богомилово' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Богомилци' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Богомолско' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Богорово' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Богорово' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Богородица' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Богослов' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Богутево' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Богьовци' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Боденец' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Бодрово' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Боево' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Боерица' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Божак' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Божан' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Божаново' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Бождово' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Божевци' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Боженица' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Боженците' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Божичен' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Божурец' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Божурица' },
  { municipality: 'Божурище', region: 'София', settlement: 'гр. Божурище' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Божурка' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Божурлук' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Божурово' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Божурово' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Божурово' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Божурци' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Бозаджии' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Бозвелийско' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Боздуганово' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Бозьова' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Боил' },
  { municipality: 'Етрополе', region: 'София', settlement: 'с. Бойковец' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Бойково' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Бойковци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Бойник' },
  { municipality: 'Бойница', region: 'Видин', settlement: 'с. Бойница' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Бойно' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Бойновци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Бойчеви колиби' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Бойчета' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'гр. Бойчиновци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Бойчовци' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Бокиловци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Болтата' },
  { municipality: 'Раковски', region: 'Пловдив', settlement: 'с. Болярино' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'гр. Болярово' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Болярски извор' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Болярско' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Болярци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Болярци' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Болярци' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Бонево' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Бор' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Борец' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Борие' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Борика' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Борика' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Борики' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Бориково' },
  { municipality: 'Бойница', region: 'Видин', settlement: 'с. Бориловец' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Борилово' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Борима' },
  { municipality: 'Лесичово', region: 'Пазарджик', settlement: 'с. Боримечково' },
  { municipality: 'Борино', region: 'Смолян', settlement: 'с. Борино' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Бориново' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Боринци' },
  { municipality: 'Пордим', region: 'Плевен', settlement: 'с. Борислав' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Бориславци' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Борисово' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Борисово' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Борнарево' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'с. Боров дол' },
  { municipality: 'Борован', region: 'Враца', settlement: 'с. Борован' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Боровец' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Боровина' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Боровица' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Боровица' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Боровичене' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Борово' },
  { municipality: 'Борово', region: 'Русе', settlement: 'гр. Борово' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Борово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Борово' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Боровско' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Боровци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Борското' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Борущица' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Борци' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Боряна' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Босевци' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Босилица' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Босилково' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Босилково' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Босилковци' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Босна' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Боснек' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Бостанци' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Бостина' },
  { municipality: 'Ботевград', region: 'София', settlement: 'гр. Ботевград' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Ботево' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Ботево' },
  { municipality: 'Хайредин', region: 'Враца', settlement: 'с. Ботево' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Ботево' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Ботево' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Ботров' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Ботуня' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Ботурче' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Бохова' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Бохот' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Бочковци' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Бошуля' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Бояджик' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Боян' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Боян Ботево' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Бояна' },
  { municipality: 'Грамада', region: 'Видин', settlement: 'с. Бояново' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Бояново' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Бояновци' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Боянци' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Брадвари' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Бракница' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Бракьовци' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Браниполе' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Браница' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Браничево' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Бранище' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Бранковци' },
  { municipality: 'Грамада', region: 'Видин', settlement: 'с. Бранковци' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Братан' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Братаница' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Братово' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Братово' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Братушково' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Братя Даскалови' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Братя Кунчеви' },
  { municipality: 'Брацигово', region: 'Пазарджик', settlement: 'гр. Брацигово' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Брегаре' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'гр. Брегово' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Брегово' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Брежана' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Брежани' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Брежниците' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Бреза' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Брезе' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Брезе' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Брезен' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'гр. Брезник' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Брезница' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Брезнишки извор' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Брезовдол' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Брезово' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'гр. Брезово' },
  { municipality: 'Кнежа', region: 'Плевен', settlement: 'с. Бреница' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Бреница' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Брест' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Брест' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Брестак' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Брестака' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Бресте' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Брестник' },
  { municipality: 'Ябланица', region: 'Ловеч', settlement: 'с. Брестница' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Брестница' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Брестова' },
  { municipality: 'Завет', region: 'Разград', settlement: 'с. Брестовене' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Брестовец' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Брестовица' },
  { municipality: 'Борово', region: 'Русе', settlement: 'с. Брестовица' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Брестово' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Брестово' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Брод' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Бродилово' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Брош' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'гр. Брусарци' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Брусевци' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Брусен' },
  { municipality: 'Етрополе', region: 'София', settlement: 'с. Брусен' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Брусино' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Брусник' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Брънеците' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Бръчковци' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Бръшлен' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Бръшлян' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Бръшляница' },
  { municipality: 'Доспат', region: 'Смолян', settlement: 'с. Бръщен' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Бряговец' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Бряговица' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Брягово' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Брягово' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Бряст' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Брястовец' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Брястово' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Брястово' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Брястово' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Бубино' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Бузовград' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Бузяковци' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Буйновица' },
  { municipality: 'Борино', region: 'Смолян', settlement: 'с. Буйново' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Буйново' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Буйновци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Бук' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Букак' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Буката' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Букаците' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Букова поляна' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Буковец' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Буковец' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Буковец' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'с. Буковец' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Буковец' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Буковлък' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Буково' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Буково' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Буково' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Букоровци' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Булаир' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Буново' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Буново' },
  { municipality: 'Якоруда', region: 'Благоевград', settlement: 'с. Бунцево' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Бураново' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'гр. Бургас' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Бурево' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Буря' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Бусинци' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Бусманци' },
  { municipality: 'Козлодуй', region: 'Враца', settlement: 'с. Бутан' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Бутово' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Бутрева' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Бутроинци' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'гр. Бухово' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Буховци' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Бучин проход' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Бучино' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Бучуковци' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Бъдеще' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Бъзовец' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Бъзовец' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Бъзовица' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Бъзън' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Българаново' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Българево' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Българене' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Българене' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Българене' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Българи' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Българин' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Българка' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'гр. Българово' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Българска поляна' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Български извор' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Българско Сливово' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Българчево' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Бънзарето' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Бърдарево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Бърдарите' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Бърдарски геран' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Бърдени' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Бърдо' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Бърдоква' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Бърза река' },
  { municipality: 'Хайредин', region: 'Враца', settlement: 'с. Бързина' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Бързица' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Бързия' },
  { municipality: 'Долни Дъбник', region: 'Плевен', settlement: 'с. Бъркач' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Бъркачево' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Бърложница' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Бърля' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Бърчево' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'с. Бъта' },
  { municipality: 'Брацигово', region: 'Пазарджик', settlement: 'с. Бяга' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'с. Бял бряг' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Бял извор' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Бял извор' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Бял кладенец' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Бял кладенец' },
  { municipality: 'Бяла', region: 'Варна', settlement: 'гр. Бяла' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'гр. Бяла' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Бяла' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Бяла вода' },
  { municipality: 'Белене', region: 'Плевен', settlement: 'с. Бяла вода' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'с. Бяла паланка' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Бяла поляна' },
  { municipality: 'Сухиндол', region: 'Велико Търново', settlement: 'с. Бяла река' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Бяла река' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Бяла река' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Бяла река' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'гр. Бяла Слатина' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'гр. Бяла черква' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Бялградец' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Бялка' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Бялково' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Бялковци' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Бяло поле' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Вазово' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Вакарел' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Ваклино' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Ваклиново' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Ваксево' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Валевци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Валето' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Варана' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Варвара' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Варвара' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Вардим' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Вардун' },
  { municipality: 'Варна', region: 'Варна', settlement: 'гр. Варна' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Варненци' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Варник' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Варовник' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Васил Друмев' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Васил Левски' },
  { municipality: 'Алфатар', region: 'Силистра', settlement: 'с. Васил Левски' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Васил Левски' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Васил Левски' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Василево' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'с. Василовци' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Василовци' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Васильово' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'с. Васково' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Ведраре' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Ведрина' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Ведрово' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Везенково' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Векилски' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Веленци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Велешани' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Велика' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Великан' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Великденче' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Великденче' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'гр. Велики Преслав' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'гр. Велико Търново' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Великово' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Великово' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Великовци' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'гр. Велинград' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Велино' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Велиново' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Велислав' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Величка' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Величково' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Величково' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Велково' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Велковци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Велковци' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Велковци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Велчево' },
  { municipality: 'Априлци', region: 'Ловеч', settlement: 'с. Велчево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Велчовци' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Вельово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Велювци' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Велющец' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Венелин' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Венец' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Венец' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Венец' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Венковец' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Венчан' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Верен' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Веренци' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Веринско' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Верско' },
  { municipality: 'Завет', region: 'Разград', settlement: 'с. Веселец' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Веселец' },
  { municipality: 'Приморско', region: 'Бургас', settlement: 'с. Веселие' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Веселина' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Веселина' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'с. Веселиново' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Веселиново' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Веслец' },
  { municipality: 'Ветово', region: 'Русе', settlement: 'гр. Ветово' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Ветрен' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'гр. Ветрен' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Ветрен' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Ветрен' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Ветрен дол' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Ветрино' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Ветринци' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Ветрище' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Ветрово' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Ветрушка' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Вехтино' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Вехтово' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Вещица' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Виден' },
  { municipality: 'Видин', region: 'Видин', settlement: 'гр. Видин' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Видинци' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Видлица' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Видно' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Видрар' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Видраре' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Видрица' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Виево' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Визица' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Виларе' },
  { municipality: 'Ново село', region: 'Видин', settlement: 'с. Винарово' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Винарово' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Винарско' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Винево' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Виница' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Винище' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Виноград' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Виноградец' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Виногради' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Вирове' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Вировско' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Вис' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Вискяр' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Висок' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Висока' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Висока могила' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Висока поляна' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Висока поляна' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Високите' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Високовци' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Витановци' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Витина' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Вихрен' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Вичово' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Вишан' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Вишеград' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Вишлене' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Вишна' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Вишнево' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Вишовград' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Владая' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Владимир' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Владимирово' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Владимирово' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Владимирово' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Владимировци' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Владиня' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Владислав' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Владиславци' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Владиченци' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Владо Тричков' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Владовци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Влайчовци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Власатили' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Власатица' },
  { municipality: 'Кресна', region: 'Благоевград', settlement: 'с. Влахи' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Влахово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Влаховци' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Водата' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Водач' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Воден' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Воден' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Воден' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Воденичане' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Воденичарово' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Воденичарско' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Воденци' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Водица' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Водица' },
  { municipality: 'Грамада', region: 'Видин', settlement: 'с. Водна' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Водни пад' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Водно' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Водно' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Воднянци' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Воднянци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Водолей' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Вождово' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Войвода' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Войводенец' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Войводино' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Войводиново' },
  { municipality: 'Мизия', region: 'Враца', settlement: 'с. Войводово' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Войводово' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Войкова лъка' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Войнеговци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Войнежа' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Войника' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Войниково' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Войница' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Войници' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Войниците' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Войнишка' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Войново' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Войново' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Войнягово' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Войсил' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Вокил' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Волно' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Воловарово' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Воловарци' },
  { municipality: 'Борово', region: 'Русе', settlement: 'с. Волово' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Волуяк' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Вонеща вода' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Врабево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Врабците' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Врабча' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Вракуповица' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Враненци' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Врани кон' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Враниловци' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Вранино' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Вранинци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Вранско' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Враня' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Враня стена' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Враняк' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Врата' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Вратарите' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Вратица' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Вратца' },
  { municipality: 'Враца', region: 'Враца', settlement: 'гр. Враца' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Врачанци' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Врачеш' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Врело' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Вресово' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'с. Връв' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Връдловци' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Всемирци' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Вукан' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Вуково' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Въбел' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Въгларово' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Въглевци' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Въглен' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Въглен' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Възел' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Вълкан' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Вълков дол' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Вълкович' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Вълково' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Вълковци' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Вълкосел' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Вълнари' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Вълча поляна' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Вълчан' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Вълчан дол' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Вълчанка' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Вълчаново' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'с. Вълче поле' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'гр. Вълчедръм' },
  { municipality: 'Макреш', region: 'Видин', settlement: 'с. Вълчек' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'гр. Вълчи дол' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Вълчи извор' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Вълчин' },
  { municipality: 'Пордим', region: 'Плевен', settlement: 'с. Вълчитрън' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Вълчовци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Вълчовци' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Върба' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Върба' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Върбак' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Върбен' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Върбен' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Върбенци' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Върбешница' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Върбина' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Върбино' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Върбица' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Върбица' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Върбица' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'гр. Върбица' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Върбица' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Върбница' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Върбовка' },
  { municipality: 'Чупрене', region: 'Видин', settlement: 'с. Върбово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Върбово' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Върбово' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Върбовчец' },
  { municipality: 'Каспичан', region: 'Шумен', settlement: 'с. Върбяне' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Въргов дол' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Вързилковци' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Вързулица' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Върли дол' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Върли дол' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Върлинка' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Върлино' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Въртоп' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Върхари' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'гр. Вършец' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Вършило' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Габаре' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Габарево' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Габер' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Габер' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Габерово' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Габерово' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Габра' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Габрака' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Габрене' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Габрешевци' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Габрина' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Габрица' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Габрица' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Габровдол' },
  { municipality: 'Белово', region: 'Пазарджик', settlement: 'с. Габровица' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Габровница' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Габровница' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Габрово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'гр. Габрово' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Габрово' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Габровци' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Габър' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Габърница' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Гавраилово' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Гаврил Геново' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Гаганица' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Гагово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Гайдари' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Гайкини' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Гайтанево' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Гайтаниново' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Гайтаните' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Гайтанци' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Галата' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Галатин' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Галиче' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Галище' },
  { municipality: 'Оряхово', region: 'Враца', settlement: 'с. Галово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Ганев дол' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Ганчовец' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Гарван' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Гарван' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Гарваново' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Гащевци' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Гега' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Гела' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Гелеменово' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Генерал Гешево' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Генерал Инзово' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Генерал Кантарджиево' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Генерал Киселово' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Генерал Колево' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Генерал Колево' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Генерал Мариново' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Генерал Тодоров' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'гр. Генерал Тошево' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Генерал Тошево' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Генералово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Геновци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Генчовци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Генчовци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Геня' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Георги Дамяново' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'с. Георги Добрево' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Гергевец' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Гергини' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Герман' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Гецово' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Геша' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Гешаново' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Гиген' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Гигинци' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Гинци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Гирчевци' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Гита' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Глава' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Главан' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Главан' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Главановци' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Главановци' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Главанци' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Главатар' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Главатарци' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Главаци' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Главиница' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'гр. Главиница' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Главник' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Глашатай' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Гледаци' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Гледачево' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Гледка' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Глогинка' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Глогино' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Глоговец' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Глоговица' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Глогово' },
  { municipality: 'Ветово', region: 'Русе', settlement: 'гр. Глоджево' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Гложене' },
  { municipality: 'Козлодуй', region: 'Враца', settlement: 'с. Гложене' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Глумово' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Глумче' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Глутниците' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Глуфишево' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Глухар' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Глушка' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Глушник' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Гнездаре' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Гняздово' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Говедаре' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Говедарци' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Говежда' },
  { municipality: 'Годеч', region: 'София', settlement: 'гр. Годеч' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Годешево' },
  { municipality: 'Разлог', region: 'Благоевград', settlement: 'с. Годлево' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Гоз' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Гоздевица' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Гоздейка' },
  { municipality: 'Костенец', region: 'София', settlement: 'с. Голак' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Голем Цалим' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Голема Раковица' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Голема Фуча' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Големани' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Големаните' },
  { municipality: 'Кула', region: 'Видин', settlement: 'с. Големаново' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Големанци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Големи Българени' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Големи Станчовци' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Големо Бабино' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Големо Бучино' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Големо Малово' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Големо село' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Голец' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Голеш' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Голеш' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Голешово' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Голица' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Голобрад' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Голобрадово' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Голям Върбовник' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Голям Девесил' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Голям Дервент' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Голям дол' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Голям извор' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Голям извор' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Голям извор' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Голям манастир' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Голям Поровец' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'с. Голям чардак' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Голяма бара' },
  { municipality: 'Ябланица', region: 'Ловеч', settlement: 'с. Голяма Брестница' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Голяма вода' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Голяма долина' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Голяма Желязна' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Голяма Чинка' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Голямо Асеново' },
  { municipality: 'Белово', region: 'Пазарджик', settlement: 'с. Голямо Белово' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Голямо Буково' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Голямо Враново' },
  { municipality: 'Опака', region: 'Търговище', settlement: 'с. Голямо градище' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Голямо Доляне' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Голямо Дряново' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Голямо Каменяне' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Голямо Крушево' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Голямо ново' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Голямо Пещене' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Голямо Соколово' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Голямо църквище' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Голямо Чочовени' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Голяновци' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Гомотарци' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Горан' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Горановци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Горановци' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Гореме' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Горен Еневец' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Горен чифлик' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Горица' },
  { municipality: 'Бяла', region: 'Варна', settlement: 'с. Горица' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Горица' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Горица' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Горичане' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Горичево' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Горна Арда' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'с. Горна Бела речка' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Горна Бешовица' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Горна Биркова' },
  { municipality: 'Кресна', region: 'Благоевград', settlement: 'с. Горна Брезница' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Горна Брестница' },
  { municipality: 'Костенец', region: 'София', settlement: 'с. Горна Василица' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Горна Вереница' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Горна Врабча' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Горна Глоговица' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Горна Гращица' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Горна Диканя' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Горна Дъбева' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Горна Златица' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Горна Кабда' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'с. Горна Ковачица' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Горна Козница' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Горна Кремена' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Горна крепост' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Горна Крушица' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Горна кула' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Горна Липница' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'с. Горна Лука' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Горна Малина' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Горна махала' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Горна Мелна' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Горна Митрополия' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'гр. Горна Оряховица' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Горна Рибница' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Горна Росица' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Горна Секирна' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Горна Студена' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Горна Сушица' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Горна Хаджийска' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Горна Хубавка' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Горни Богров' },
  { municipality: 'Оряхово', region: 'Враца', settlement: 'с. Горни Вадин' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Горни Върпища' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Горни Главанак' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Горни Дамяновци' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Горни Домлян' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Горни Драгойча' },
  { municipality: 'Долни Дъбник', region: 'Плевен', settlement: 'с. Горни Дъбник' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Горни Коритен' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Горни край' },
  { municipality: 'Чупрене', region: 'Видин', settlement: 'с. Горни Лом' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Горни Маренци' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Горни Окол' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Горни Радковци' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Горни Романци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Горни Танчевци' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Горни Цибър' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Горни Цоневци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Горни Юруци' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Горник' },
  { municipality: 'Борово', region: 'Русе', settlement: 'с. Горно Абланово' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Горно Александрово' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Горно Белево' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Горно Ботево' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Горно Войводино' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Горно вършило' },
  { municipality: 'Разлог', region: 'Благоевград', settlement: 'с. Горно Драглище' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Горно Дряново' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Горно Изворово' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Горно Камарци' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Горно Кирково' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Горно Кобиле' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Горно Козарево' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Горно Краище' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Горно Къпиново' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Горно Луково' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Горно Новково' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Горно ново село' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'с. Горно Озирово' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Горно Осеново' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Горно Павликене' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Горно Пещене' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Горно поле' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Горно Прахово' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Горно Сахране' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Горно село' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Горно Спанчево' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Горно трапе' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Горно Уйно' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Горно Хърсово' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Горно Церовене' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Горно Черковище' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Горно Ябълково' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Горнова могила' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Горноселци' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Горнослав' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Горово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Горово' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Гороцвет' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Горочевци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Горска' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Горска поляна' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Горски горен Тръмбеш' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Горски долен Тръмбеш' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Горски извор' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Горски извор' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Горски Сеновец' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Горско' },
  { municipality: 'Опака', region: 'Търговище', settlement: 'с. Горско Абланово' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Горско Дюлево' },
  { municipality: 'Сухиндол', region: 'Велико Търново', settlement: 'с. Горско Калугерово' },
  { municipality: 'Сухиндол', region: 'Велико Търново', settlement: 'с. Горско Косово' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Горско ново село' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Горско Писарево' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Горско село' },
  { municipality: 'Летница', region: 'Ловеч', settlement: 'с. Горско Сливово' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Горталово' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Горун' },
  { municipality: 'Етрополе', region: 'София', settlement: 'с. Горунака' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Горчево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Горяни' },
  { municipality: 'Бяла', region: 'Варна', settlement: 'с. Господиново' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Господиново' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Господинци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Гостилица' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Гостиля' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Гостиня' },
  { municipality: 'Банско', region: 'Благоевград', settlement: 'с. Гостун' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'гр. Гоце Делчев' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Градево' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Градежница' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Градец' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Градец' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Градец' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Градешница' },
  { municipality: 'Долни Дъбник', region: 'Плевен', settlement: 'с. Градина' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Градина' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Градина' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Градинарово' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Градини' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Градище' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Градище' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Градище' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Градница' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Градница' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Градско' },
  { municipality: 'Бойница', region: 'Видин', settlement: 'с. Градсковски колиби' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Градът' },
  { municipality: 'Грамада', region: 'Видин', settlement: 'гр. Грамада' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Грамаде' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Грамаде' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Грамаждано' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Граматиково' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Граматици' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Гранит' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Гранитец' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Гранитово' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Гранитово' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Граница' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Граничак' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Граничар' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Граничар' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Граф Игнатиево' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Грашево' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Гращица' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Греевци' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Гривица' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Гривка' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Гривяк' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Григорево' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Грозден' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Гроздьово' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Громшин' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Грохотно' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Груево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Гръблевци' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Грълска падина' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Грънчарово' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Губеш' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Губислав' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Гугутка' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Гудевица' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Гулийка' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Гулия' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'гр. Гулянци' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Гумощник' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Гурбановци' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Гургулица' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Гургулят' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Гурково' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'гр. Гурково' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Гурково' },
  { municipality: 'Божурище', region: 'София', settlement: 'с. Гурмазово' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Гусла' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Гуслар' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Гуцал' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Гъбене' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Гълъбец' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Гълъбец' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Гълъбинци' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Гълъбник' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Гълъбово' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Гълъбово' },
  { municipality: 'Куклен', region: 'Пловдив', settlement: 'с. Гълъбово' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'гр. Гълъбово' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Гълъбовци' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'с. Гъмзово' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Гърбино' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Гърбище' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Гърдевци' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Гърло' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Гърляно' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Гърмен' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Гърнати' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Гърня' },
  { municipality: 'Опака', region: 'Търговище', settlement: 'с. Гърчиново' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Гъсково' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Гьоврен' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Гюешево' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Гюльовца' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Гюргич' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Давери' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Давидково' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Давидово' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Давидово' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Дагоново' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Даевци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Дайновци' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Дамяница' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Дамяново' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Даскал-Атанасово' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Даскалово' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'гр. Две могили' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Две тополи' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Дворище' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Дворище' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Дебел дял' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'гр. Дебелец' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Дебелец' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Дебели лаг' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Дебели рът' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Дебелт' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Дебелцово' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Дебеляново' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Дебнево' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Дебово' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Дебочица' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Дебрен' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Дебрене' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Дебрене' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Дебръщица' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Девене' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Девенци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Девесилица' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Девесилово' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Деветак' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Деветаки' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Деветаците' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Деветинци' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'гр. Девин' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Девино' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Девинци' },
  { municipality: 'Девня', region: 'Варна', settlement: 'гр. Девня' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Дедец' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Дедина' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Дедино' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Дединци' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Дедово' },
  { municipality: 'Белене', region: 'Плевен', settlement: 'с. Деков' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Делвино' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Делвино' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'с. Делейна' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Делова махала' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Делчево' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Делчево' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Делян' },
  { municipality: 'Божурище', region: 'София', settlement: 'с. Делян' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Деляновци' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Денница' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Денница' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Денчевци' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Дервишка могила' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Дерманци' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Детелина' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Детелина' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Дечковци' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Джамузовци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Джанка' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'гр. Джебел' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Джелепско' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Джерман' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Джерово' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Джигурово' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Джинот' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Джинчовци' },
  { municipality: 'Лясковец', region: 'Велико Търново', settlement: 'с. Джулюница' },
  { municipality: 'Ценово', region: 'Русе', settlement: 'с. Джулюница' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Джумриите' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Джурково' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Джурово' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Джуровци' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Дибич' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Дива Слатина' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Дивеци' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Дивля' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Дивотино' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Дивчовото' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Диманово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Димиевци' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Димитриево' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'гр. Димитровград' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Димитровци' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Димитровче' },
  { municipality: 'Димово', region: 'Видин', settlement: 'гр. Димово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Димово' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Димовци' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Димовци' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Димча' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Димчево' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Динево' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Динк' },
  { municipality: 'Лесичово', region: 'Пазарджик', settlement: 'с. Динката' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Динковица' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Динково' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Диня' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Дирало' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Дисевица' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Дисманица' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Дичево' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Дичин' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Длъгнево' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Длъгня' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Длъжка поляна' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Длъжко' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Длъхчево-Сабляр' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Доборско' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Добра поляна' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Добравица' },
  { municipality: 'Куклен', region: 'Пловдив', settlement: 'с. Добралък' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Добрева череша' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Добрево' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Добревци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Добревци' },
  { municipality: 'Ябланица', region: 'Ловеч', settlement: 'с. Добревци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Добрените' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Добри Войниково' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Добри дол' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Добри дол' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'с. Добри дол' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Добри дол' },
  { municipality: 'Лясковец', region: 'Велико Търново', settlement: 'с. Добри дял' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Добри лаки' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Добрин' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Добрина' },
  { municipality: 'Банско', region: 'Благоевград', settlement: 'гр. Добринище' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Добриново' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Добриново' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Добринци' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Добрич' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Добрич' },
  { municipality: 'Добрич', region: 'Добрич', settlement: 'гр. Добрич' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Добровница' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Доброво' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Доброглед' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Добродан' },
  { municipality: 'Борован', region: 'Враца', settlement: 'с. Добролево' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Добромир' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Добромирка' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Добромирци' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Доброплодно' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Доброселец' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Доброславци' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Добростан' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Добротино' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Добротица' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Добротица' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Добротич' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Добруджанка' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Добруша' },
  { municipality: 'Разлог', region: 'Благоевград', settlement: 'с. Добърско' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Добърчин' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Доганово' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Дождевица' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Дойновци' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Дойранци' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Дойранци' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Дойренци' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Докатичево' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Доктор Йосифово' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Докьовци' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Долен' },
  { municipality: 'Златоград', region: 'Смолян', settlement: 'с. Долен' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Долен Еневец' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Долене' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Долени' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Долец' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Долец' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Долие' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Долина' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Долина' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Долистово' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Долище' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Долище' },
  { municipality: 'Долна баня', region: 'София', settlement: 'гр. Долна баня' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'с. Долна Бела речка' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Долна Бешовица' },
  { municipality: 'Костенец', region: 'София', settlement: 'с. Долна Василица' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Долна Вереница' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Долна Врабча' },
  { municipality: 'Кресна', region: 'Благоевград', settlement: 'с. Долна Градешница' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Долна Гращица' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Долна Диканя' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Долна Дъбева' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Долна Златица' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Долна Кабда' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Долна Козница' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Долна Кремена' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Долна крепост' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Долна Крушица' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Долна кула' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Долна Липница' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Долна Малина' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Долна махала' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Долна Мелна' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'гр. Долна Митрополия' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Долна Невля' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'гр. Долна Оряховица' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Долна Рибница' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Долна Рикса' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Долна Секирна' },
  { municipality: 'Ценово', region: 'Русе', settlement: 'с. Долна Студена' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Долна Хубавка' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Долни Богров' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Долни Бошняк' },
  { municipality: 'Оряхово', region: 'Враца', settlement: 'с. Долни Вадин' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Долни Вит' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Долни Върпища' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Долни Главанак' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Долни Дамяновци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Долни Драгойча' },
  { municipality: 'Долни Дъбник', region: 'Плевен', settlement: 'гр. Долни Дъбник' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Долни Коритен' },
  { municipality: 'Чупрене', region: 'Видин', settlement: 'с. Долни Лом' },
  { municipality: 'Искър', region: 'Плевен', settlement: 'с. Долни Луковит' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Долни Маренци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Долни Марян' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Долни Окол' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Долни Пасарел' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Долни Радковци' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Долни Раковец' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Долни Романци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Долни Танчевци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Долни Томчевци' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Долни Цибър' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'гр. Долни чифлик' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Долни Юруци' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Долно Абланово' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Долно Белево' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Долно Белотинци' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Долно Ботево' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Долно Войводино' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Долно вършило' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Долно Големанци' },
  { municipality: 'Разлог', region: 'Благоевград', settlement: 'с. Долно Драглище' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Долно Дряново' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Долно Изворово' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Долно Камарци' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Долно Кобиле' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Долно Козарево' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Долно Къпиново' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'с. Долно Линево' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Долно Луково' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Долно Новково' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Долно ново село' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Долно ново село' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'с. Долно Озирово' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Долно Осеново' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Долно поле' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Долно Прахово' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Долно Ряхово' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Долно Сахране' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Долно село' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Долно Спанчево' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Долно Съдиево' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Долно Уйно' },
  { municipality: 'Якимово', region: 'Монтана', settlement: 'с. Долно Церовене' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Долно Черковище' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Долно Шивачево' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Долно Ябълково' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Долноселци' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Долнослав' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Домище' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Домлян' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'с. Дондуково' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Донино' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Донкино' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Донковци' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Дончево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Дончовци' },
  { municipality: 'Ракитово', region: 'Пазарджик', settlement: 'с. Дорково' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Доситеево' },
  { municipality: 'Доспат', region: 'Смолян', settlement: 'гр. Доспат' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Доспей' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Доча' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Драбишна' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Драгана' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'с. Драганица' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Драгановец' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Драганово' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Драганово' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Драганово' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Драганово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Драгановци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Драгановци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Драганосковци' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Драганци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Драганчетата' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Драгаш войвода' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Драгиевци' },
  { municipality: 'Лясковец', region: 'Велико Търново', settlement: 'с. Драгижево' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Драгийци' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Драгиново' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Драгичево' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Драгневци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Драгневци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Драговищица' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Драговищица' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Драгово' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Драгодан' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Драгоданово' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Драгоево' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Драгоил' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Драгойново' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Драгойчинци' },
  { municipality: 'Драгоман', region: 'София', settlement: 'гр. Драгоман' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Драгомани' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'с. Драгомир' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Драгомирово' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Драгомирово' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Драгомъж' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Драгор' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Драготинци' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Драгуш' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Драгушиново' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Дражево' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Дражинци' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Драка' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Драката' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Дралфа' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Драма' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Дрангово' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Дрангово' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Дрангово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Драндарите' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Драчево' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Драшан' },
  { municipality: 'Априлци', region: 'Ловеч', settlement: 'с. Драшкова поляна' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Дреатин' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Дрен' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Дренково' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Дренов' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Дреновец' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Дреновица' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Дреново' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Дреново' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Дрента' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Дренци' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Дриново' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Дрипчево' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Дропла' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Дропла' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Друган' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Дружба' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Дружево' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Дружинци' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Друмево' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Друмохар' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Друмче' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Дръмша' },
  { municipality: 'Суворово', region: 'Варна', settlement: 'с. Дръндар' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Дрянка' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Дрянковец' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Дрянова глава' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Дряновец' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Дряновец' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Дряновец' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Дряновец' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'гр. Дряново' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Дряново' },
  { municipality: 'Симеоновград', region: 'Хасково', settlement: 'с. Дряново' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Дряново' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Дуванлии' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Дуковци' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'гр. Дулово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Думници' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Дунавец' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Дунавци' },
  { municipality: 'Видин', region: 'Видин', settlement: 'гр. Дунавци' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Дунавци' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Дунево' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Дуня' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'гр. Дупница' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Дуранкулак' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Дуровци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Дурча' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Духовец' },
  { municipality: 'Пирдоп', region: 'София', settlement: 'с. Душанци' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Душево' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Душевски колиби' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Душинково' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Душинци' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Душка' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Дъбен' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Дъбене' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Дъбник' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Дъбница' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Дъбова' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Дъбова' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'с. Дъбова махала' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Дъбован' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'с. Дъбовец' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Дъбовик' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Дъбовица' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Дъбово' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Дъбово' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Дъбрава' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Дъбрава' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Дъбрава' },
  { municipality: 'Ябланица', region: 'Ловеч', settlement: 'с. Дъбравата' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Дъбравино' },
  { municipality: 'Белово', region: 'Пазарджик', settlement: 'с. Дъбравите' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Дъбравица' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Дъбравка' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Дъждино' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Дъждовник' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Дъждовница' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Дълбок дол' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Дълбок извор' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Дълбоки' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Дълга лука' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Дългач' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Дълги дел' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Дълги припек' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Дълго поле' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Дълго поле' },
  { municipality: 'Якимово', region: 'Монтана', settlement: 'с. Дългоделци' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'гр. Дългопол' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Дънгово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Дървари' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Държава' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Държавен' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Държаница' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Дърлевци' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Дърманци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Дъскарите' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Дъскот' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Дъскотна' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Дюлево' },
  { municipality: 'Стрелча', region: 'Пазарджик', settlement: 'с. Дюлево' },
  { municipality: 'Бяла', region: 'Варна', settlement: 'с. Дюлино' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Дюлица' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Дюля' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Дядово' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Дядовско' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Дядовци' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Дяково' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Дянково' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Евлогиево' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Евренозово' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Егрек' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Егълница' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Единаковци' },
  { municipality: 'Николаево', region: 'Стара Загора', settlement: 'с. Едрево' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Едрино' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Ездимирци' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Езерец' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Езеро' },
  { municipality: 'Белослав', region: 'Варна', settlement: 'с. Езерово' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Езерово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Езерото' },
  { municipality: 'Цар Калоян', region: 'Разград', settlement: 'с. Езерче' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Екзарх Антимово' },
  { municipality: 'Борово', region: 'Русе', settlement: 'с. Екзарх Йосиф' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'гр. Елена' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Елена' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Еленино' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Еленка' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Еленов дол' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Еленово' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Еленово' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Еленово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Еленска' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Еленците' },
  { municipality: 'Разлог', region: 'Благоевград', settlement: 'с. Елешница' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Елешница' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Елин Пелин' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'гр. Елин Пелин' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Елисейна' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Елов дол' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Еловдол' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Еловица' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Еловица' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Елховец' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Елхово' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'гр. Елхово' },
  { municipality: 'Николаево', region: 'Стара Загора', settlement: 'с. Елхово' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'с. Елшица' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Ельово' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Емен' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Емона' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Енев рът' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Енево' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Енево' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Енина' },
  { municipality: 'Кнежа', region: 'Плевен', settlement: 'с. Еница' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Енчец' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Енчовци' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Еньовче' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Ерден' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Еремия' },
  { municipality: 'Златоград', region: 'Смолян', settlement: 'с. Ерма река' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Еровете' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Ерул' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Есен' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Есеница' },
  { municipality: 'Етрополе', region: 'София', settlement: 'гр. Етрополе' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Ефрейтор Бакалово' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Ефрем' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Жабляно' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Жабокрът' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Жегларци' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Жеглица' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Жедна' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Железари' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Железарци' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Железино' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'с. Железна' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Железник' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Железник' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Железница' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Железница' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Желен' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'с. Желъд' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Желъдово' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Желю войвода' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Желява' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Желязковец' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Желязно' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Желязово' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Женда' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Жеравино' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Жеравна' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Жерговец' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Жернов' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Живко' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Живково' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Живково' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Жижево' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Жиленци' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Жилино' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Жинзифово' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Житарник' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Житен' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Житен' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Житница' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Житница' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Житница' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Житница' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Житосвят' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Житуша' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Жребево' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Жребино' },
  { municipality: 'Брацигово', region: 'Пазарджик', settlement: 'с. Жребичко' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'с. Жълт бряг' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Жълт камък' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Жълтеш' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Жълти бряг' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Жълти рид' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Жълтика' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Жълтопоп' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Жълтуша' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Забел' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Заберново' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Забърдо' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Завала' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Завет' },
  { municipality: 'Завет', region: 'Разград', settlement: 'гр. Завет' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Заветно' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Завидовци' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Завой' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Завоя' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Загоре' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Загориче' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Загорски' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Загорско' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Загорци' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Загорци' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Загорци' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Загражден' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Загражден' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Задруга' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Заевите' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Заимчево' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Зайчар' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Зайчари' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Зайчино' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Зайчино ореше' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'с. Замфир' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Замфирово' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Заноге' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Зараево' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Зарица' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Зарник' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Заселе' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Засмяно' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Зафирово' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Захари Стояново' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Захари Стояново' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Зая' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Звегор' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Звезда' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Звезда' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Звездел' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Звезделина' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Звезден' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Звездец' },
  { municipality: 'Варна', region: 'Варна', settlement: 'с. Звездица' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Звездица' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Звенимир' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Зверино' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Звиница' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Звънарка' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Звънарци' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Звънец' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Звъника' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Звъничево' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Звънче' },
  { municipality: 'Пордим', region: 'Плевен', settlement: 'с. Згалево' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Згориград' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Згурово' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Здравец' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Здравец' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Здравец' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Здравец' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Здравец' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Здравковец' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Здравчец' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Зебил' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Зелена морава' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Зелендол' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Зелениград' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Зеленик' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Зеленика' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Зелениково' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Зелениково' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Зелено дърво' },
  { municipality: 'Земен', region: 'Перник', settlement: 'гр. Земен' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Земенци' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Землен' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Зетьово' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Зетьово' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Зидарово' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Зидарци' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Зимевица' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Зимен' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Зимзелен' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Зимница' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Зимница' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Зимница' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Зимовина' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Зиморница' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Златар' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Златари' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Златарица' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'гр. Златарица' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Златевци' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Злати войвода' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Златина' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Златиница' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Златирът' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Златитрап' },
  { municipality: 'Златица', region: 'София', settlement: 'гр. Златица' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Златия' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Златия' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Златна ливада' },
  { municipality: 'Каспичан', region: 'Шумен', settlement: 'с. Златна нива' },
  { municipality: 'Ябланица', region: 'Ловеч', settlement: 'с. Златна Панега' },
  { municipality: 'Варна', region: 'Варна', settlement: 'к.к. Златни Пясъци' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Златовръх' },
  { municipality: 'Златоград', region: 'Смолян', settlement: 'гр. Златоград' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Златоклас' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Златолист' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Златолист' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Златополе' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Златосел' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Златоустово' },
  { municipality: 'Божурище', region: 'София', settlement: 'с. Златуша' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Злидол' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Злогош' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Злокучене' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Злокучене' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Змеево' },
  { municipality: 'Доспат', region: 'Смолян', settlement: 'с. Змеица' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Змейно' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Змейово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Змиево' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Знаменосец' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Зограф' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Зойчене' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Зорница' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Зорница' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Зорница' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Зорница' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Зорница' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Зорница' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Зърнево' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Ивайло' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'гр. Ивайловград' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Иван Вазово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Иван Димов' },
  { municipality: 'Завет', region: 'Разград', settlement: 'с. Иван Шишманово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Иванивановци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Иванили' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Иванковци' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Иваново' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Иваново' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Иваново' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Иваново' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Иваново' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Ивановци' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Ивановци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Ивановци' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Ивански' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Иванци' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Иванча' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Иванча' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Иваняне' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Иганово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Иглика' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Иглика' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Иглика' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'гр. Игнатиево' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Игнатица' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Игнатово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Игнатовци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Игнатовци' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Игралище' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Идилево' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Избеглии' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Избул' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Извор' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Извор' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Извор' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Извор' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Извор' },
  { municipality: 'Кула', region: 'Видин', settlement: 'с. Извор махала' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Изворище' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Изворник' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Изворово' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Изворово' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Изворово' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Изворово' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Изворско' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Изворче' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Изгрев' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Изгрев' },
  { municipality: 'Суворово', region: 'Варна', settlement: 'с. Изгрев' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Изгрев' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Изгрев' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Изгрев' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Изгрев' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Изгрев' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Източник' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Илаков рът' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Илевци' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Илийно' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Илийско' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Илинден' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Илинден' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Илинденци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Илиница' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Илиювци' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Илия' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Илия Блъсково' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Имренчево' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Индже войвода' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Иново' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Иречек' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Иречеково' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Иринеци' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Ирник' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Искра' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Искра' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Искра' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Искра' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Искра' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Искрец' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Искрица' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Искър' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Искър' },
  { municipality: 'Искър', region: 'Плевен', settlement: 'гр. Искър' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'гр. Исперих' },
  { municipality: 'Брацигово', region: 'Пазарджик', settlement: 'с. Исперихово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Исьовци' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'гр. Ихтиман' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Ичера' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'с. Йерусалимово' },
  { municipality: 'Стамболийски', region: 'Пловдив', settlement: 'с. Йоаким Груево' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Йовково' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Йововци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Йовчевци' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Йоглав' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Йонково' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Йончово' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Йорданово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Кокорово' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Кабиле' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'гр. Каблешково' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Каблешково' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Каблешково' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'гр. Каварна' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Кавлак' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Кавракирово' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Кадиево' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Кадровица' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Казак' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Казанка' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'гр. Казанлък' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Казаците' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Казачево' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Казашка река' },
  { municipality: 'Варна', region: 'Варна', settlement: 'с. Казашко' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Казимир' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Казичене' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Кайнарджа' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Калайджиево' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Калайджии' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Калейца' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Калековец' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Кален' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Каленик' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Каленик' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Каленовци' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Калиманци' },
  { municipality: 'Суворово', region: 'Варна', settlement: 'с. Калиманци' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'с. Калина' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Калина' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Калинка' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Калино' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Калипетрово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Калитиново' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Калище' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Калнище' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Калово' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Каломен' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Калотина' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Калотинци' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'гр. Калофер' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Калоян' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Калояновец' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Калояново' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Калояново' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Калоянци' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Калугерене' },
  { municipality: 'Лесичово', region: 'Пазарджик', settlement: 'с. Калугерово' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Калугерово' },
  { municipality: 'Симеоновград', region: 'Хасково', settlement: 'с. Калугерово' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Калчево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Калчовци' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Камбелевци' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Камбурово' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Камен' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Камен' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Камен' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Камен бряг' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Камен връх' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Камен дял' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Камена' },
  { municipality: 'Варна', region: 'Варна', settlement: 'с. Каменар' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Каменар' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Каменари' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Каменарци' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Каменец' },
  { municipality: 'Пордим', region: 'Плевен', settlement: 'с. Каменец' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Каменец' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Каменик' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Каменица' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Каменица' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Каменичка Скакавица' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Каменка' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Каменна река' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Каменна Рикса' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'гр. Камено' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Камено поле' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Каменово' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Каменово' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Каменци' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Каменяк' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Каменяк' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Каменяне' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Камещица' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Камилски дол' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Камчия' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Кандилка' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Кандови' },
  { municipality: 'Бойница', region: 'Видин', settlement: 'с. Каниц' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Кантари' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Каняк' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'гр. Каолиново' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Капатово' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Капитан Андреево' },
  { municipality: 'Пещера', region: 'Пазарджик', settlement: 'с. Капитан Димитриево' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Капитан Димитрово' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Капитан Петко' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Капитан Петко войвода' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Капитановци' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Капище' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Кара Михал' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Карабунар' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Каравелово' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Каравелово' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Каравелово' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Каравельово' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Карагеоргиево' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Караджалово' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Караджово' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Караиванца' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Караиванци' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Караисен' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Карали' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Караманите' },
  { municipality: 'Ценово', region: 'Русе', settlement: 'с. Караманово' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Караманци' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Карамичевци' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Карамфил' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Каран Върбовка' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Карандили' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Караново' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Караново' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Каранци' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Карапелит' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Караполци' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Караш' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Карбинци' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Карвуна' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Кардам' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Кардам' },
  { municipality: 'Златица', region: 'София', settlement: 'с. Карлиево' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'гр. Карлово' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Карловско' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Карлуково' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'гр. Карнобат' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Касапско' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Касилаг' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Каснаково' },
  { municipality: 'Каспичан', region: 'Шумен', settlement: 'гр. Каспичан' },
  { municipality: 'Каспичан', region: 'Шумен', settlement: 'с. Каспичан' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Кастел' },
  { municipality: 'Пордим', region: 'Плевен', settlement: 'с. Катерица' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Катранджии' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Катраница' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Катрище' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Катунец' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Катуница' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Катунище' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Катунци' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Кацелово' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Качулка' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Кашенци' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Кашина' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Каялоба' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Керека' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Керените' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'гр. Кермен' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Кесарево' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Кестен' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Кестеново' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Киевци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'гр. Килифарево' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Кипилово' },
  { municipality: 'Девня', region: 'Варна', settlement: 'с. Кипра' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Киревци' },
  { municipality: 'Макреш', region: 'Видин', settlement: 'с. Киреево' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Кирилово' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Кирилово' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Кирково' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Кирово' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Кирчево' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'с. Киселево' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Киселица' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Киселковци' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Киселчово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Кисийците' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Кисьовци' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Китанчево' },
  { municipality: 'Приморско', region: 'Бургас', settlement: 'гр. Китен' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Китен' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Китино' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Китка' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Китна' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Китница' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Кичево' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Киченица' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Кладенец' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Кладенец' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Кладенци' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Кладенци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Кладни дял' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Кладница' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Кладоруб' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Кленовик' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Клепало' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Кликач' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Климаш' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Климент' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Климент' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Климентово' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Климентово' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Клисура' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'гр. Клисура' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Клисура' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Клисура' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Клисурица' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'ман. Клисурски манастир' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Клокотница' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Клъшка река' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Ключ' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Кметовци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Кметчета' },
  { municipality: 'Кнежа', region: 'Плевен', settlement: 'гр. Кнежа' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Книжовник' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'с. Княжева махала' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Княжево' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Княжевско' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Кобилино' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Кобиляк' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Кобиляне' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Ковач' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Ковачевец' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Ковачевица' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Ковачево' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Ковачево' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Ковачево' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Ковачевци' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Ковачевци' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Ковачите' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'с. Ковачица' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Ковил' },
  { municipality: 'Сухиндол', region: 'Велико Търново', settlement: 'с. Коевци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Коевци' },
  { municipality: 'Борино', region: 'Смолян', settlement: 'с. Кожари' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Кожинци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Кожлювци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Кожухарци' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Козаново' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Козар Белене' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Козаре' },
  { municipality: 'Лясковец', region: 'Велико Търново', settlement: 'с. Козаревец' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Козаревец' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Козарево' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Козарка' },
  { municipality: 'Брацигово', region: 'Пазарджик', settlement: 'с. Козарско' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Кози рог' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Козица' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Козица' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Козичино' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Козлево' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Козлец' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Козловец' },
  { municipality: 'Козлодуй', region: 'Враца', settlement: 'гр. Козлодуй' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Козлодуйци' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Козма презвитер' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Козница' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Козя река' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Козяк' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Коиловци' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'гр. Койнаре' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Койчовци' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Кокаляне' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Кокиче' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Кокорци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Кокошане' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Колари' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Коларово' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Коларово' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Коларово' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Коларово' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Коларци' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Колена' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Колец' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Колибите' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Колишовци' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Колобър' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Колю Ганев' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Колю Мариново' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Комарево' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Комарево' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Комарево' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Комарево' },
  { municipality: 'Якимово', region: 'Монтана', settlement: 'с. Комощица' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Комунари' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Комунига' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Комщица' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Конак' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Конаре' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Конаре' },
  { municipality: 'Якоруда', region: 'Благоевград', settlement: 'с. Конарско' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Конарското' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Кондово' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Кондолово' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Кондофрей' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Коневец' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Конево' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Конево' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Конево' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Конници' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Коноп' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Конска' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Константин' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Константиновец' },
  { municipality: 'Варна', region: 'Варна', settlement: 'с. Константиново' },
  { municipality: 'Симеоновград', region: 'Хасково', settlement: 'с. Константиново' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Константиново' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Контил' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Конуш' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Конуш' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Конче' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Коньовец' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Коньово' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Коняво' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Копаница' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Копиловци' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Копиловци' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Копитник' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Копрец' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Коприва' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Копривец' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Копривлен' },
  { municipality: 'Копривщица', region: 'София', settlement: 'гр. Копривщица' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Копринка' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Копчелиите' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Корен' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Кориите' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Коритата' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Коритен' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Корията' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Коркина' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Кормянско' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Корница' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Кортен' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Кос' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Косара' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Косарка' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Косача' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Косевци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Косилка' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Косовец' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'с. Косово' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Косово' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Косово' },
  { municipality: 'Каспичан', region: 'Шумен', settlement: 'с. Косово' },
  { municipality: 'Кула', region: 'Видин', settlement: 'с. Коста Перчево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Костадините' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Костадинкино' },
  { municipality: 'Цар Калоян', region: 'Разград', settlement: 'с. Костанденец' },
  { municipality: 'Ракитово', region: 'Пазарджик', settlement: 'гр. Костандово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Костел' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Костелево' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Костен' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Костена река' },
  { municipality: 'Костенец', region: 'София', settlement: 'гр. Костенец' },
  { municipality: 'Костенец', region: 'София', settlement: 'с. Костенец' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Костенковци' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Костенци' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Кости' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Костиево' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Костилково' },
  { municipality: 'Костинброд', region: 'София', settlement: 'гр. Костинброд' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Костино' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Костичовци' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Костур' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Костурино' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Костуринци' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'гр. Котел' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Котеновци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Котлари' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Котленци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Котуци' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Кочан' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Кочани' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Кочево' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'гр. Кочериново' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Кочмар' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Кочово' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Кошава' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Кошарево' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Кошарите' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Кошарица' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Кошарна' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Кошница' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Кошничари' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Кошов' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Кравино' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Краводер' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Крагулево' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Краево' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Краище' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Краище' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Крайгорци' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Крайна' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Крайна' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Крайни дол' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Крайници' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Крайно село' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Крайново' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Крайполе' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Кракра' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Кралев дол' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Кралево' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Кралево' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Крали Марко' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Крамолин' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Кран' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Кранево' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Краново' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Крапец' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Крапец' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Крапчене' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Красава' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Красен' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Красен' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Красен дол' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Красимир' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Красино' },
  { municipality: 'Сухиндол', region: 'Велико Търново', settlement: 'с. Красно градище' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Красново' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Красноселци' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Крачимир' },
  { municipality: 'Банско', region: 'Благоевград', settlement: 'с. Кремен' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Кремен' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Кремена' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Кремене' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Кременец' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Кременик' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Крепост' },
  { municipality: 'Опака', region: 'Търговище', settlement: 'с. Крепча' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Креслювци' },
  { municipality: 'Кресна', region: 'Благоевград', settlement: 'гр. Кресна' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Крета' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Крета' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Крибул' },
  { municipality: 'Козлодуй', region: 'Враца', settlement: 'с. Крива бара' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'с. Крива бара' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Крива круша' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Крива река' },
  { municipality: 'Ценово', region: 'Русе', settlement: 'с. Кривина' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Кривина' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Кривини' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Кривица' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Кривня' },
  { municipality: 'Ветово', region: 'Русе', settlement: 'с. Кривня' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Криво поле' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'гр. Криводол' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Кривонос' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Крилатица' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Крилювци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Крин' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Крислово' },
  { municipality: 'Кричим', region: 'Пловдив', settlement: 'гр. Кричим' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Кромидово' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Крояч' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Кроячево' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Крум' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'гр. Крумовград' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Крумово' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Крумово' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Крумово' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Крумово' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Крумово градище' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Крумчевци' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Крупен' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Крупник' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Круша' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Круша' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Крушаре' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Крушари' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Крушев дол' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Крушевец' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Крушево' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Крушево' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Крушево' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Крушевска' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Крушето' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Крушка' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Крушовене' },
  { municipality: 'Мизия', region: 'Враца', settlement: 'с. Крушовица' },
  { municipality: 'Долни Дъбник', region: 'Плевен', settlement: 'с. Крушовица' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Крушовица' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Крушово' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Крушово' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Крушолак' },
  { municipality: 'Летница', region: 'Ловеч', settlement: 'с. Крушуна' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Кръвеник' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'гр. Крън' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Крънджилица' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Крънча' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Кръстава' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Кръстатица' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Кръстевич' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Кръстеняците' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Кръстец' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Кръстилци' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Кръстина' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Кръшно' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Кубадин' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'гр. Кубрат' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Кубратово' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'с. Куделин' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Кузьово' },
  { municipality: 'Куклен', region: 'Пловдив', settlement: 'гр. Куклен' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Кукля' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Кукорево' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Кукувица' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Кукурахцево' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Кукуряк' },
  { municipality: 'Кула', region: 'Видин', settlement: 'гр. Кула' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Кулата' },
  { municipality: 'Белене', region: 'Плевен', settlement: 'с. Кулина вода' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Куманите' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Куманово' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Кундево' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Кунино' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Купен' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Купен' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Купците' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Курново' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Куртово' },
  { municipality: 'Стамболийски', region: 'Пловдив', settlement: 'с. Куртово Конаре' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Кутела' },
  { municipality: 'Алфатар', region: 'Силистра', settlement: 'с. Кутловица' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Кутово' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Кутугерци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Куцаровци' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Куцина' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Куцово' },
  { municipality: 'Златоград', region: 'Смолян', settlement: 'с. Кушла' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Къклица' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Къкрина' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'с. Кълново' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Кънчево' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Къпинец' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Къпиново' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Къпиново' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Къпиновци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'гр. Кърджали' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Кърланово' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Кърналово' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Кърнаре' },
  { municipality: 'Летница', region: 'Ловеч', settlement: 'с. Кърпачево' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Кърпелево' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Къртипъня' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Къртожабене' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Кърчовско' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Кършалево' },
  { municipality: 'Доспат', region: 'Смолян', settlement: 'с. Късак' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Кътина' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Къшин' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Къшле' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Кьолмен' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Кьосево' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Кьосевци' },
  { municipality: 'Каспичан', region: 'Шумен', settlement: 'с. Кюлевча' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'гр. Кюстендил' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Лагерите' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Лагошевци' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Ладарево' },
  { municipality: 'Кнежа', region: 'Плевен', settlement: 'с. Лазарово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Лазарци' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Лакатник' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Лакатник' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Лале' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Лалково' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Ламбух' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Ласкар' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Ласкарево' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Латинка' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Лебед' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Лебница' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Лева река' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Левище' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Левка' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Левочево' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'с. Левски' },
  { municipality: 'Суворово', region: 'Варна', settlement: 'с. Левски' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'гр. Левски' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Левуново' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Левци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Леденик' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Лелинци' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Ленище' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Ленково' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Леново' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Ленско' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Лепица' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Лесидрен' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Лесичарка' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Лесиче' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Лесичери' },
  { municipality: 'Лесичово', region: 'Пазарджик', settlement: 'с. Лесичово' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Леска' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Леска' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Лесковдол' },
  { municipality: 'Оряхово', region: 'Враца', settlement: 'с. Лесковец' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Лесковец' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Лесковец' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Лесново' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Лесово' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Лесура' },
  { municipality: 'Летница', region: 'Ловеч', settlement: 'гр. Летница' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Летница' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Летовник' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Лехово' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Лехчево' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Лешко' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Лешниково' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Лешниковци' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Лешница' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Лешница' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Лещак' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Лещарка' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Лещен' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Леярово' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Ливада' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Ливаде' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Лик' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Лилеково' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Лилково' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Лиляк' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Лиляново' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Лиляч' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Лиляче' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Лимец' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Липен' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Липец' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Липинци' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Липник' },
  { municipality: 'Мизия', region: 'Враца', settlement: 'с. Липница' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Липница' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Лисец' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Лисец' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Лисец' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Лиси връх' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Лисиците' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Лисия' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Лисово' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Листец' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Листец' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Литаково' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Лобош' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Ловец' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Ловец' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Ловец' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'гр. Ловеч' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Ловнидол' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Ловско' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Ловци' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Ловчанци' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Логодаж' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Лоза' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Лозарево' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Лозево' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Лозен' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Лозен' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Лозен' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'с. Лозен' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Лозен' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Лозенградци' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Лозенец' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Лозенец' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Лозенец' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Лозеница' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Лозица' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Лозица' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'гр. Лозница' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Лозница' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Лозно' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Локвата' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Локорско' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'гр. Лом' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Лом Черковна' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Ломец' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Ломница' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Ломница' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Ломница' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Ломци' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Лопушна' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Лопушня' },
  { municipality: 'Етрополе', region: 'София', settlement: 'с. Лопян' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Лудогорци' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'гр. Луковит' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Луково' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Луличка' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Лъвино' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Лъвово' },
  { municipality: 'Етрополе', region: 'София', settlement: 'с. Лъга' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Лъжница' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Лъка' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Лъка' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Лъкавица' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Лъки' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'гр. Лъки' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'с. Любен' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Любен' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Любен Каравелово' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Любенец' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Любенова махала' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Любеново' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Любеново' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Любеново' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Любенци' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'гр. Любимец' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Любино' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Любичево' },
  { municipality: 'Опака', region: 'Търговище', settlement: 'с. Люблен' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Любница' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Любовище' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Любовка' },
  { municipality: 'Доспат', region: 'Смолян', settlement: 'с. Любча' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Люлин' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Люлин' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Люлка' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Люляк' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Люляково' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Люляково' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Люляково' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Лютаджик' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Лютиброд' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Лютидол' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Лютово' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Лява река' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Лялинци' },
  { municipality: 'Лясковец', region: 'Велико Търново', settlement: 'гр. Лясковец' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Лясковец' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Лясково' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Лясково' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Лясково' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Лясково' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Лясково' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Лясково' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Лятно' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Ляхово' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Ляхово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Магарджица' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Мадан' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'гр. Мадан' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Мадара' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Маджаре' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Маджари' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'гр. Маджарово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Маджерито' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Мазарачево' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Майор Узуново' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Майско' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Майсторово' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Мак' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Макариополско' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Македонци' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Маково' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Макоцево' },
  { municipality: 'Макреш', region: 'Видин', settlement: 'с. Макреш' },
  { municipality: 'Божурище', region: 'София', settlement: 'с. Мала Раковица' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Мала Фуча' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Мала църква' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Малево' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Малево' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Маленово' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Мали Върбовник' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Мали Дреновец' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Мали извор' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Малина' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Малина' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Малини' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Малиново' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Малка Арда' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Малка Верея' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Малка Желязна' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Малка поляна' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Малка Смолница' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Малка Черковна' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Малка Чинка' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Малки Българени' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Малки Воден' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Малки Вършец' },
  { municipality: 'Етрополе', region: 'София', settlement: 'с. Малки Искър' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Малки Станчовци' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Малки Цалим' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Малки чифлик' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Малко Асеново' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Малко Брягово' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Малко Враново' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'с. Малко градище' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Малко Дряново' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Малко Йонково' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Малко Кадиево' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Малко Каменяне' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Малко Кирилово' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Малко Крушево' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Малко Попово' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Малко село' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Малко Тръново' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'гр. Малко Търново' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Малко Чочовени' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Малко Шарково' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Малкоч' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Мало Бучино' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Мало Конаре' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Мало Крушево' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Мало Малово' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Мало Пещене' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Мало село' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Малоградец' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Маломир' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Маломир' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Маломирово' },
  { municipality: 'Борован', region: 'Враца', settlement: 'с. Малорад' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Малуша' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Малчика' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Малчовци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Малчовци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Малък Девесил' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Малък дол' },
  { municipality: 'Ябланица', region: 'Ловеч', settlement: 'с. Малък извор' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Малък извор' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Малък манастир' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Малък Поровец' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Малък Преславец' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'с. Малък чардак' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Мамарчево' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Манаселска река' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Манастир' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Манастир' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Манастир' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Манастирица' },
  { municipality: 'Хайредин', region: 'Враца', settlement: 'с. Манастирище' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Манастирище' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Манастирско' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Манастирци' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Мандра' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Мандрица' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Маневци' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Маноле' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Манолич' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Манолово' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Манолско Конаре' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Маноя' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Манушевци' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Манчево' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Марафелци' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Мараш' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Марговци' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Марикостиново' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Маринка' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Марино поле' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Марино поле' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Мариновци' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Марица' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Марково' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Марково' },
  { municipality: 'Каспичан', region: 'Шумен', settlement: 'с. Марково' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Марково равнище' },
  { municipality: 'Русе', region: 'Русе', settlement: 'гр. Мартен' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Мартино' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'с. Мартиново' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Марулево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Маруцековци' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Марчево' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Марчино' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Марян' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Масларево' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Маслиново' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Матешовци' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Маточина' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Махалата' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Махалници' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Маца' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Медвен' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Медевци' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Меден бук' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Меден кладенец' },
  { municipality: 'Сърница', region: 'Пазарджик', settlement: 'с. Медени поляни' },
  { municipality: 'Грамада', region: 'Видин', settlement: 'с. Медешевци' },
  { municipality: 'Медковец', region: 'Монтана', settlement: 'с. Медковец' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Медникарово' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Медовене' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Медовец' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Медовина' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Медовница' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Медово' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Медово' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Медово' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Межда' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Межден' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Междени' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'гр. Мездра' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Мездрея' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Мезек' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'гр. Мелник' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Мелница' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Меляне' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Менгишево' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Мендово' },
  { municipality: 'Белово', region: 'Пазарджик', settlement: 'с. Мененкьово' },
  { municipality: 'Лясковец', region: 'Велико Търново', settlement: 'с. Мерданя' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'гр. Меричлери' },
  { municipality: 'Банско', region: 'Благоевград', settlement: 'с. Места' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Метлика' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Метличина' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Метличина' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Метличка' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Методиево' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Методиево' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Метохия' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Мечка' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Мечка' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Мечкарево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Мечковица' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Мечковци' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Мечкул' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Мечово' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Мещица' },
  { municipality: 'Мизия', region: 'Враца', settlement: 'гр. Мизия' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Мийковци' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Микре' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Микрево' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Миладиново' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Миладиновци' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Миладиновци' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Миладиновци' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Миланово' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Миланово' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Миле' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Милево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Милевци' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Милино' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Милковица' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Милково' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Милковци' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Милкьовци' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Милославци' },
  { municipality: 'Грамада', region: 'Видин', settlement: 'с. Милчина лъка' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Миндя' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Миневци' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Минерални бани' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Минзухар' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Мирково' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Мировец' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Мирово' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Мирово' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Мирово' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Мировци' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Мировяне' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Миролюбово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Мирчовци' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Мирянци' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Митино' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Митовска' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'с. Митровци' },
  { municipality: 'Хайредин', region: 'Враца', settlement: 'с. Михайлово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Михайлово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Михайловци' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Михалич' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Михалич' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Михалково' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Михалци' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Михилци' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Михнево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Миховци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Мичковци' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Мишевско' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Мишеморков хан' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Млада гвардия' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Младежко' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Младен' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Младиново' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Младово' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Мламолово' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Млекарево' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Млечево' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Млечино' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Могила' },
  { municipality: 'Каспичан', region: 'Шумен', settlement: 'с. Могила' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Могила' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Могилец' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Могилино' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Могилите' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Могилица' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Могилище' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Могилово' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Могиляне' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Мокрен' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Мокреш' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Мокреш' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Мокрище' },
  { municipality: 'Костенец', region: 'София', settlement: 'гр. Момин проход' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Момин сбор' },
  { municipality: 'Белово', region: 'Пазарджик', settlement: 'с. Момина клисура' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Момина сълза' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Момина църква' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Момино' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Момино' },
  { municipality: 'Раковски', region: 'Пловдив', settlement: 'с. Момино село' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Моминско' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Момково' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'гр. Момчилград' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Момчилово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Момчиловци' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'гр. Монтана' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Морава' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Моравица' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Моравица' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Моравка' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Моровеците' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Мортагоново' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Морянци' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Московец' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Мост' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Мостич' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Мостово' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Мочуре' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Мощанец' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Мракетинци' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Мрамор' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Мрамор' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Мрамор' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Мраморен' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Мрахори' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Мраченик' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Мрежичко' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Мрежичко' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Мръзеци' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Мугла' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Музга' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Мулдава' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Мурга' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Мургаш' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Мургово' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Мурено' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Мурсалево' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Муртинци' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Мусачево' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Мусачево' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Мусево' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Муселиево' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Мусина' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Мосомище' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Мустрак' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Мухово' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Муця' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Мъглен' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Мъглене' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'гр. Мъглиж' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Мъглища' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Мъдрево' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Мъдрец' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Мъдрец' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Мъдрино' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Мърводол' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Мъртвината' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Мърчаево' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Мърчево' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Мътеница' },
  { municipality: 'Симеоновград', region: 'Хасково', settlement: 'с. Навъсен' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Надарево' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Надарци' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Надежден' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'с. Найден Герово' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Найденово' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Нане' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Нановица' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Нареченски бани' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Насалевци' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Наум' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Научене' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Нацовци' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Начево' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Небеска' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Невестино' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Невестино' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Невестино' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Невша' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Негован' },
  { municipality: 'Ново село', region: 'Видин', settlement: 'с. Неговановци' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Негованци' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Негушево' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Недан' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'с. Неделево' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'гр. Неделино' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Неделище' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Неделкова Гращица' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Неделково' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Недоклан' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Недялковци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Недялковци' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Недялско' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Нейково' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Нейково' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Нейкьовец' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Нейчовци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Ненково' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Неново' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Неновци' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Неофит Бозвелиево' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Неофит Рилски' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Непразненци' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'гр. Несебър' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Несла' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Нефела' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Нешевци' },
  { municipality: 'Борован', region: 'Враца', settlement: 'с. Нивянин' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Никачковци' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Никола Козлево' },
  { municipality: 'Суворово', region: 'Варна', settlement: 'с. Николаевка' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Николаево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Николаево' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Николаево' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Николаево' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Николаево' },
  { municipality: 'Николаево', region: 'Стара Загора', settlement: 'гр. Николаево' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Николичевци' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Николово' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Николово' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Николово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Николовци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Николчовци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Николчовци' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'гр. Никопол' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Никудин' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Никюп' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Нисово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Ничовци' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Нова бяла река' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Нова Върбовка' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'гр. Нова Загора' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Нова Камена' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Нова ливада' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Нова Ловча' },
  { municipality: 'Батак', region: 'Пазарджик', settlement: 'с. Нова махала' },
  { municipality: 'Николаево', region: 'Стара Загора', settlement: 'с. Нова махала' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Нова Надежда' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Нова Попина' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Нова Черна' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Нова Шипка' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Новаково' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Новаково' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Новаковци' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Новачево' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Новачене' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Новачене' },
  { municipality: 'Ценово', region: 'Русе', settlement: 'с. Новград' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Нови извор' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'гр. Нови Искър' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Нови пазар' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'гр. Нови пазар' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Нови хан' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Нови чифлик' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Ново Ботево' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Ново бърдо' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Ново градище' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Ново Делчево' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Ново Железаре' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Ново Кономлади' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Ново Лески' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Ново Оряхово' },
  { municipality: 'Приморско', region: 'Бургас', settlement: 'с. Ново Паничарево' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Ново село' },
  { municipality: 'Ново село', region: 'Видин', settlement: 'с. Ново село' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Ново село' },
  { municipality: 'Стамболийски', region: 'Пловдив', settlement: 'с. Ново село' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Ново село' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Ново село' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Ново село' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Ново Ходжово' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'с. Ново Янково' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Новогорци' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Новосел' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Новоселец' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Новоселище' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Новоселци' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Новоселяне' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Ноевци' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Ножарево' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Ножарово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Ножерите' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Носеите' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Ночево' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Нюшковци' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Обединение' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Обел' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'гр. Обзор' },
  { municipality: 'Банско', region: 'Благоевград', settlement: 'с. Обидим' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Обител' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Обичник' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Обнова' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Оборище' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'с. Оборище' },
  { municipality: 'Борово', region: 'Русе', settlement: 'с. Обретеник' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Оброчище' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Обручище' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Овен' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Овощарци' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Овощна' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Овощник' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Овча могила' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Овчага' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Овчари' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Овчарово' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Овчарово' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Овчарово' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Овчарово' },
  { municipality: 'Сапарева баня', region: 'Кюстендил', settlement: 'с. Овчарци' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Овчарци' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Овчево' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Овчеполци' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Овчи кладенец' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Оглед' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Огнен' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Огняново' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Огняново' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Огняново' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Огняново' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Огоя' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Оградна' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Огражден' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'с. Одоровци' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Одраница' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Одринци' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Одринци' },
  { municipality: 'Пордим', region: 'Плевен', settlement: 'с. Одърне' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Одърци' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Озърновци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Околиите' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Окоп' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Окорш' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Оман' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Омарчево' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Омарчево' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'гр. Омуртаг' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Оногур' },
  { municipality: 'Опака', region: 'Търговище', settlement: 'гр. Опака' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Опан' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Опанец' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Опанец' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Опицвет' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Оплетня' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Опълченец' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Опълченско' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Орач' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Орех' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Ореховица' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Орехово' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Ореш' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Орешак' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Орешак' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Орешари' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Ореше' },
  { municipality: 'Ябланица', region: 'Ловеч', settlement: 'с. Орешене' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Орешене' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Орешец' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Орешец' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Орешец' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Орешец' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Орешец' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Орешино' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Орешник' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Орешница' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Оризаре' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Оризари' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'с. Оризари' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Оризово' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Орлинци' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Орлица' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Орлов дол' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Орлова могила' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Орловец' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Орлово' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Орлово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Орловци' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Орляк' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Орляне' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'с. Орсоя' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Орцево' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Оряховец' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Оряховица' },
  { municipality: 'Оряхово', region: 'Враца', settlement: 'гр. Оряхово' },
  { municipality: 'Любимец', region: 'Хасково', settlement: 'с. Оряхово' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Оселна' },
  { municipality: 'Етрополе', region: 'София', settlement: 'с. Оселна' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Осен' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Осен' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Осен' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Осенарите' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Осенец' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Осеновец' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Осеновлаг' },
  { municipality: 'Банско', region: 'Благоевград', settlement: 'с. Осеново' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Осеново' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Осетеново' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Осиковица' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Осиково' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Осиково' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Осиково' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Осиковска Лакавица' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Осина' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Осларка' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Ослен Криводол' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Осмар' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Осоица' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Остра могила' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Остра могила' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Острец' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Остри пазлак' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Острица' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Острица' },
  { municipality: 'Оряхово', region: 'Враца', settlement: 'с. Остров' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Островец' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Островица' },
  { municipality: 'Завет', region: 'Разград', settlement: 'с. Острово' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Островче' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Острокапци' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Остър камък' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Отец Кирилово' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Отец Паисиево' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Охлювец' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Оходен' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Охрид' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Очиндол' },
  { municipality: 'Костенец', region: 'София', settlement: 'с. Очуша' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Ошане' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Ошаните' },
  { municipality: 'Кресна', region: 'Благоевград', settlement: 'с. Ощава' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Павел' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'гр. Павел баня' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Павелско' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Павлевци' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'гр. Павликени' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Паволче' },
  { municipality: 'Рила', region: 'Кюстендил', settlement: 'с. Падала' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Падало' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Падеш' },
  { municipality: 'Девня', region: 'Варна', settlement: 'с. Падина' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Падина' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Падина' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Падине' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'гр. Пазарджик' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Пазарци' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Паисиево' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Паисий' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Пайдушко' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Паламарца' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Палат' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Палатик' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Палатово' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Палаузово' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Палилула' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Палици' },
  { municipality: 'Лесичово', region: 'Пазарджик', settlement: 'с. Памидово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Памукчии' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Памукчии' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'гр. Панагюрище' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'с. Панагюрски колонии' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Панайот Волово' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Панайот Хитово' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Панаретовци' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Паницово' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Паничарево' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Паничерево' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Паничери' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Паничино' },
  { municipality: 'Сапарева баня', region: 'Кюстендил', settlement: 'с. Паничище' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Паничково' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Пановци' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Панчарево' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Панчево' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Папрат' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Папратлива' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Парамун' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Парил' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Партизани' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Партизанин' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Парчовци' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Паскалевец' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Паскалево' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Паспал' },
  { municipality: 'Рила', region: 'Кюстендил', settlement: 'с. Пастра' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Пастух' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Паталеница' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Патица' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Патреш' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Патрешко' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Патриарх Евтимово' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Пауново' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Пашинци' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Пашкул' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Пашови' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Пашово' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Певец' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Певците' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Пейковци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Пейна' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Пейовци' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Пейчиново' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Пелатиково' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Пелин' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Пелишат' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Пенковци' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Пенкьовци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Пеньово' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Пепелина' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Пепелище' },
  { municipality: 'Бойница', region: 'Видин', settlement: 'с. Периловец' },
  { municipality: 'Перник', region: 'Перник', settlement: 'гр. Перник' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Перперек' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Перуника' },
  { municipality: 'Перущица', region: 'Пловдив', settlement: 'гр. Перущица' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Песнопой' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Песнопой' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Песочница' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Пет кладенци' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Пет могили' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Пет могили' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Петелово' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Петко Каравелово' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Петко Славейков' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Петково' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Петково' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Петковци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Петковци' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Петлешково' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Петлино' },
  { municipality: 'Белене', region: 'Плевен', settlement: 'с. Петокладенци' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Петревене' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Петрелик' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Петрино' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'гр. Петрич' },
  { municipality: 'Златица', region: 'София', settlement: 'с. Петрич' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Петров дол' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Петров дол' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Петрово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Петрово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Петровци' },
  { municipality: 'Долни Дъбник', region: 'Плевен', settlement: 'с. Петърница' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Петърч' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Пецовци' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Печеница' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Печинска' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Пешаково' },
  { municipality: 'Пещера', region: 'Пазарджик', settlement: 'гр. Пещера' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Пещера' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Пещера' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Пещерна' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Пещерско' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Пиперево' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Пиперица' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Пиперков чифлик' },
  { municipality: 'Ценово', region: 'Русе', settlement: 'с. Пиперково' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Пирамидата' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Пиргово' },
  { municipality: 'Пирдоп', region: 'София', settlement: 'гр. Пирдоп' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Пирин' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Пиринец' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Пирне' },
  { municipality: 'Ветово', region: 'Русе', settlement: 'с. Писанец' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Писаница' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Писарево' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Писарево' },
  { municipality: 'Искър', region: 'Плевен', settlement: 'с. Писарово' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Писарово' },
  { municipality: 'Приморско', region: 'Бургас', settlement: 'с. Писменово' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Питово' },
  { municipality: 'Медковец', region: 'Монтана', settlement: 'с. Пишурка' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Пищане' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Пищигово' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Пиявец' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Плазище' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Плаково' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Плакудер' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Плана' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Планинец' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Планиница' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Планиница' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Планиново' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Планинско' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Планинци' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Планинци' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Плачидол' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Плачка' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'гр. Плачковци' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'гр. Плевен' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Плевун' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Пленимир' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Плетена' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Плешивец' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Плешинци' },
  { municipality: 'Каспичан', region: 'Шумен', settlement: 'гр. Плиска' },
  { municipality: 'Пловдив', region: 'Пловдив', settlement: 'гр. Пловдив' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Пловдивци' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Пловка' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Плодовитово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Плоска могила' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Плоски' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Плъзище' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Плъстина' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Победа' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Победа' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Победа' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Побит камък' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Побит камък' },
  { municipality: 'Сърница', region: 'Пазарджик', settlement: 'с. Побит камък' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Побък' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Повалиръж' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Повет' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Подайва' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Подвис' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Подвис' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Подвръх' },
  { municipality: 'Макреш', region: 'Видин', settlement: 'с. Подгоре' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Подгорец' },
  { municipality: 'Костенец', region: 'София', settlement: 'с. Подгорие' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Подгорица' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Подгумер' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Подем' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Подкова' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Подкрепа' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Подлес' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Подрумче' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Подслон' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Подслон' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Пожарево' },
  { municipality: 'Божурище', region: 'София', settlement: 'с. Пожарево' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Пожерник' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'с. Поибрене' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Покрайна' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Покрован' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Покровник' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Полена' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Поленица' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Полетинци' },
  { municipality: 'Кула', region: 'Видин', settlement: 'с. Полетковци' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Полето' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Поликраище' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Полковник Дяково' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Полковник Желязово' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Полковник Иваново' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Полковник Ламбриново' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Полковник Минково' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Полковник Савово' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Полковник Свещарово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Полковник Серафимово' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Полковник Таслаково' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Полковник Чолаково' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Полска Скакавица' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Полски Градец' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Полски извор' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Полски Сеновец' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'гр. Полски Тръмбеш' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Полско Косово' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Полско Пъдарево' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Поляна' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Поляна' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Поляна' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Полянец' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Поляново' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Поляново' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Полянци' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Поляците' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Помеждин' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Помен' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'гр. Поморие' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Помощица' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Помощник' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Понор' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Попари' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Попгергевци' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Попгригорово' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Попгруево' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Попина' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'с. Попинци' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Попица' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Попкралево' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Поповец' },
  { municipality: 'Гоце Делчев', region: 'Благоевград', settlement: 'с. Попови ливади' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Поповица' },
  { municipality: 'Бяла', region: 'Варна', settlement: 'с. Попович' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'гр. Попово' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Попово' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Поповци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Поповци' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Поповци' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Поповяне' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Попрайковци' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Попрелка' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Попрусаново' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Попрусевци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Попска' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Попска' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Попско' },
  { municipality: 'Пордим', region: 'Плевен', settlement: 'гр. Пордим' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Пороище' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Порой' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Поройна' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Поройно' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Поройно' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Пороминово' },
  { municipality: 'Бойчиновци', region: 'Монтана', settlement: 'с. Портитовци' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Поручик Кърджиево' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Поручик Чунчево' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Посабина' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Посев' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Постник' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Поток' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Потока' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Потоп' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Поточарка' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Поточе' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Поточница' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Поцърненци' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Правда' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Правда' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Правдино' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Правдолюб' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Правенци' },
  { municipality: 'Правец', region: 'София', settlement: 'гр. Правец' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Правешка Лакавица' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'с. Правище' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Право бърдо' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Православ' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Православен' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Праужда' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Прахали' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'с. Превала' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Преколница' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Прекръсте' },
  { municipality: 'Завет', region: 'Разград', settlement: 'с. Прелез' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Прелом' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Преображенци' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Пресека' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Преселенци' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Преселец' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Преселка' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Пресиян' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Преславен' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Преславец' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Преславци' },
  { municipality: 'Златоград', region: 'Смолян', settlement: 'с. Пресока' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Преспа' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Преспа' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Престой' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Пресяк' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Пресяка' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Прибой' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Прилеп' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Прилеп' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Прилепци' },
  { municipality: 'Приморско', region: 'Бургас', settlement: 'гр. Приморско' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Приморци' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Припек' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Припек' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Припек' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Присад' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Присад' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Присадец' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Приселци' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Приселци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Присово' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Присойна' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Пристое' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Пробуда' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'гр. Провадия' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Проглед' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Прогрес' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Продановци' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Продановци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Продановци' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Проданча' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Пролаз' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Пролазница' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Пролез' },
  { municipality: 'Божурище', region: 'София', settlement: 'с. Пролеша' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Пролом' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Пропаст' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Просена' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Просеник' },
  { municipality: 'Суворово', region: 'Варна', settlement: 'с. Просечен' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Просторно' },
  { municipality: 'Чупрене', region: 'Видин', settlement: 'с. Протопопинци' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Професор Златарски' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Професор Иширково' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Прохлада' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Проход' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Прохорово' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Пряпорец' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Пряпорец' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Птичар' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Птичево' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Пудрия' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Пушево' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Пчела' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Пчелари' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Пчеларово' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Пчеларово' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Пчелин' },
  { municipality: 'Костенец', region: 'София', settlement: 'с. Пчелин' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Пчелина' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Пчелино' },
  { municipality: 'Гурково', region: 'Стара Загора', settlement: 'с. Пчелиново' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Пчелище' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Пчелник' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Пчелник' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Пчелно' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Пшеничево' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Пъдарево' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Пъдарино' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Пъдарско' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Пъдарци' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Пънчево' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Първан' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Първенец' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Първенец' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Първенци' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Първица' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Първомай' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'гр. Първомай' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Първомайци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Пържиграх' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Пърличево' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Пъровци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Пъртевци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Пърша' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Пъстрен' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Пъстрово' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Пъстрогор' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Пъстроок' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Пътниково' },
  { municipality: 'Симеоновград', region: 'Хасково', settlement: 'с. Пясъчево' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Рабиша' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Рабово' },
  { municipality: 'Бойница', region: 'Видин', settlement: 'с. Раброво' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Равадиново' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Равда' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Равен' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Равна' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'с. Равна' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Равна' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Равна гора' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Равна гора' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Равна гора' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Равнец' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Равнец' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Равнил' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Равнината' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Равнища' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Равнище' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Равно' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Равно нивище' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Равно поле' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Равно село' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Равново' },
  { municipality: 'Брацигово', region: 'Пазарджик', settlement: 'с. Равногор' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Радан войвода' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Раданово' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Раданчето' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Радево' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Радево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Радевци' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Радецки' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Радибош' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Радиево' },
  { municipality: 'Пещера', region: 'Пазарджик', settlement: 'с. Радилово' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Радинград' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Радино' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Радиново' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Радишево' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Радко Димитриево' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Радковци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Радловци' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'гр. Раднево' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Радовене' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Радовец' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Радово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Радовци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Радовци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Радоевци' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Радойново' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'гр. Радомир' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Радомирци' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Радотина' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Радуил' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Радуй' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Радуловци' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Радунци' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Радювене' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Раевци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Раждавица' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Разбоище' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Разбойна' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Разбойна' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Развигорово' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Разград' },
  { municipality: 'Разград', region: 'Разград', settlement: 'гр. Разград' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Раздел' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Раздел' },
  { municipality: 'Белослав', region: 'Варна', settlement: 'с. Разделна' },
  { municipality: 'Гълъбово', region: 'Стара Загора', settlement: 'с. Разделна' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Разделци' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Раздол' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Разлив' },
  { municipality: 'Разлог', region: 'Благоевград', settlement: 'гр. Разлог' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Разсоха' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Райкова могила' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Райковци' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Райнино' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Райново' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Райновци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Райновци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Райнушковци' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Райово' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Ракево' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Ракиловци' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Ракита' },
  { municipality: 'Сливница', region: 'София', settlement: 'с. Ракита' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Ракитна' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Ракитница' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'с. Ракитница' },
  { municipality: 'Ракитово', region: 'Пазарджик', settlement: 'гр. Ракитово' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Раклиново' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Раклица' },
  { municipality: 'Макреш', region: 'Видин', settlement: 'с. Раковица' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Раково' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Раково' },
  { municipality: 'Раковски', region: 'Пловдив', settlement: 'гр. Раковски' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Раковски' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Раковски' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Раковсково' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Ралево' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Ралевци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Ралиновци' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Ралица' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Ралица' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Раличево' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Раненци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Рани лист' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Рани луг' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Расник' },
  { municipality: 'Медковец', region: 'Монтана', settlement: 'с. Расово' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Растник' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Раховци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Рачевци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Рачовци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Рашевци' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Рашка Гращица' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Рашково' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Рашовите' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Рашовица' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Раювци' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Раяновци' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Раяновци' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Раянци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Ребревци' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Ребро' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Реброво' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Ребърково' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Редешковци' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Редина' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Режанци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Режинци' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Резач' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Резбарци' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Резово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Река' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Рекичка' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Рельово' },
  { municipality: 'Чупрене', region: 'Видин', settlement: 'с. Репляна' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Реселец' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Ресен' },
  { municipality: 'Сапарева баня', region: 'Кюстендил', settlement: 'с. Ресилово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Речани' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Речица' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Реяновци' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Рибарица' },
  { municipality: 'Етрополе', region: 'София', settlement: 'с. Рибарица' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Рибарци' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Рибен' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Рибен дол' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Рибино' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Рибник' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Рибница' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Рибново' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Ридино' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Ридово' },
  { municipality: 'Рила', region: 'Кюстендил', settlement: 'гр. Рила' },
  { municipality: 'Рила', region: 'Кюстендил', settlement: 'ман. Рилски манастир' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Рилци' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Рисиманово' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Ритя' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'с. Риш' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Робово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Ровина' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Рогач' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Рогачево' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Роглец' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Рогозари' },
  { municipality: 'Хайредин', region: 'Враца', settlement: 'с. Рогозен' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Рогозина' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Рогозиново' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Рогозче' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Рогош' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Родина' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Родопи' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Родопско' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Рожден' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Рожденско' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Рожен' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Роза' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Розино' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Розино' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Розовец' },
  { municipality: 'Брацигово', region: 'Пазарджик', settlement: 'с. Розово' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Розово' },
  { municipality: 'Роман', region: 'Враца', settlement: 'гр. Роман' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Ропот' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'с. Росен' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Росен' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Росен' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Росеново' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Росеново' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Росина' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Росица' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Росица' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Росица' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Росно' },
  { municipality: 'Божурище', region: 'София', settlement: 'с. Росоман' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Рохлева' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Рояк' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Рударци' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Рудина' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Рудина' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Рудник' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'гр. Рудозем' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Руевци' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Руен' },
  { municipality: 'Куклен', region: 'Пловдив', settlement: 'с. Руен' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Руец' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Ружинци' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Ружица' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Ружица' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Руйно' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Руйчовци' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Руманя' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Румелия' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Румянцево' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Руня' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Рупите' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Рупките' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Рупци' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Рупци' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Рупча' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Русалина' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Русалско' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Русаля' },
  { municipality: 'Русе', region: 'Русе', settlement: 'гр. Русе' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Русиновци' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Руска Бела' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Русковци' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Русокастро' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Рустан' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Руховци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Ручей' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Ръжавец' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Ръжана' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Ръждак' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Ръжево' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Ръжево Конаре' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Ръжена' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Ръженово' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Ръжица' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Ръсово' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Рът' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Рътлина' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Рязковци' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Ряхово' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Ряховците' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Сава' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Савин' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Савино' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Савойски' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Садина' },
  { municipality: 'Долни Дъбник', region: 'Плевен', settlement: 'с. Садовец' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Садовик' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Садовица' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Садово' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Садово' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Садово' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'гр. Садово' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Сажденик' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Сакарци' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Саласука' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Салаш' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Салманово' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Самовила' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Самоводене' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Самодива' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Самокитка' },
  { municipality: 'Самоков', region: 'София', settlement: 'гр. Самоков' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Самораново' },
  { municipality: 'Бяла', region: 'Варна', settlement: 'с. Самотино' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Самсиите' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Самуил' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Самуилова крепост' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Самуилово' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Самуилово' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Самуилово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Самуилово' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Санадиново' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'гр. Сандански' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Сандрово' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Сан-Стефано' },
  { municipality: 'Сапарева баня', region: 'Кюстендил', settlement: 'гр. Сапарева баня' },
  { municipality: 'Сапарева баня', region: 'Кюстендил', settlement: 'с. Сапарево' },
  { municipality: 'Мизия', region: 'Враца', settlement: 'с. Сараево' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Саранско' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Саранци' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Сарая' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Сатовча' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Сбор' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Сбор' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Сборино' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'с. Сборище' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Сваленик' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Сватбаре' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Свежен' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Света Петка' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'гр. Свети Влас' },
  { municipality: 'Пещера', region: 'Пазарджик', settlement: 'с. Свети Константин' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Свети Никола' },
  { municipality: 'Долна баня', region: 'София', settlement: 'с. Свети Спас' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Светлен' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Светлен' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Светлина' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Светлина' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Светлина' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Светля' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Световрачене' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Светослав' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Светослав' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Светославци' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Светулка' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Свещари' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Свидня' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'гр. Свиленград' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Свинарски дол' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Свирачи' },
  { municipality: 'Симеоновград', region: 'Хасково', settlement: 'с. Свирково' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Свирци' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Свирчово' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'гр. Свищов' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Свобода' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Свобода' },
  { municipality: 'Стрелча', region: 'Пазарджик', settlement: 'с. Свобода' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Свобода' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Свобода' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Свободен' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Свободиново' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Свободица' },
  { municipality: 'Своге', region: 'София', settlement: 'гр. Своге' },
  { municipality: 'Правец', region: 'София', settlement: 'с. Своде' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Севар' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Севдалина' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Северняк' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Северци' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'гр. Севлиево' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Седелец' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Седефче' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Седларево' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Седлари' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Седларци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Седловина' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Седянковци' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Сейдол' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Сейковци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Сеймените' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Секирка' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Секулово' },
  { municipality: 'Оряхово', region: 'Враца', settlement: 'с. Селановци' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Селиминово' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Селище' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Селище' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Селище' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Селищен дол' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Селска поляна' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Селце' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Селце' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Селци' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Селча' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Селянин' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Семерджиево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Семерджиите' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Семерци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Семковци' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Семчиново' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Сенник' },
  { municipality: 'Ветово', region: 'Русе', settlement: 'гр. Сеново' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Сеноклас' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Сенокос' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Сенокос' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Сенце' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'гр. Септември' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Септемврийци' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Септемврийци' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Септемврийци' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Сеслав' },
  { municipality: 'Белово', region: 'Пазарджик', settlement: 'с. Сестримо' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Сестринско' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Сечен камък' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Сечище' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Сив кладенец' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Сива река' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Сивино' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Сигмен' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Силен' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'гр. Силистра' },
  { municipality: 'Симеоновград', region: 'Хасково', settlement: 'гр. Симеоновград' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Симеоновец' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Симеоново' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'гр. Симитли' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Синаговци' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Синапово' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Синдел' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Синделци' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Синеморец' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Сини вир' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Сини връх' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Сини рид' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Синигер' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Синитово' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Синчец' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Синьо бърдо' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Синьо камене' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Синя вода' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Сипей' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Сипец' },
  { municipality: 'Борован', region: 'Враца', settlement: 'с. Сираково' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Сираково' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Сираково' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Сирищник' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Ситово' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Ситово' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Ситово' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Скала' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Скала' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Скалак' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Скалак' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Скалина' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Скалица' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Скалище' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Скална глава' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Скалско' },
  { municipality: 'Априлци', region: 'Ловеч', settlement: 'с. Скандалото' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Склаве' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Скобелево' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Скобелево' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Скобелево' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Скобелево' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Скобелево' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Скомля' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Скорците' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Скравена' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Скребатно' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Скрино' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Скриняно' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Скрът' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Скутаре' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Скърбино' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Славеево' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Славеево' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Славейково' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Славейково' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Славейково' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Славейно' },
  { municipality: 'Септември', region: 'Пазарджик', settlement: 'с. Славовица' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Славовица' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Славотин' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Славщица' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Славяни' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Славянин' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'гр. Славяново' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Славяново' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Славяново' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Славянци' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Сладка вода' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Сладкодум' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Сладун' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Сладък кладенец' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Слаковци' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Сламино' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Слана бара' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Сланотрън' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Слатина' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Слатина' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Слатина' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Слатина' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Слатино' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Слатино' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Слащен' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Сливак' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Сливарка' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Сливарово' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'с. Сливата' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Сливек' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'гр. Сливен' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Сливенци' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Сливито' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Сливка' },
  { municipality: 'Кресна', region: 'Благоевград', settlement: 'с. Сливница' },
  { municipality: 'Сливница', region: 'София', settlement: 'гр. Сливница' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'гр. Сливо поле' },
  { municipality: 'Медковец', region: 'Монтана', settlement: 'с. Сливовик' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Сливовица' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Сливовник' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Сливово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Сливово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Сливово' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Слишовци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Слокощица' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Сломер' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Слънчево' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Слънчовец' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Слънчоглед' },
  { municipality: 'Стрелча', region: 'Пазарджик', settlement: 'с. Смилец' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Смилец' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Смиловци' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Смилян' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Смин' },
  { municipality: 'Брусарци', region: 'Монтана', settlement: 'с. Смирненски' },
  { municipality: 'Ветово', region: 'Русе', settlement: 'с. Смирненски' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Смирненци' },
  { municipality: 'Земен', region: 'Перник', settlement: 'с. Смиров дол' },
  { municipality: 'Якоруда', region: 'Благоевград', settlement: 'с. Смолево' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Смоличано' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Смолник' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Смолница' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Смолско' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Смолча' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'гр. Смолян' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Смоляновци' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Смочан' },
  { municipality: 'Рила', region: 'Кюстендил', settlement: 'с. Смочево' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'гр. Смядово' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Снежа' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Снежина' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Снежинка' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Сноп' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Снягово' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Снягово' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Совата' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Соволяно' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'гр. Созопол' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Сокол' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Сокол' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Соколаре' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Соколарци' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Соколенци' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Соколец' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Соколино' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Соколите' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Соколица' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Соколник' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Соколово' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Соколово' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Соколово' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Соколово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Соколовци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Соколско' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Соколяне' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Солари' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Солища' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Солище' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Солник' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Сомовит' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Сопица' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Сопово' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'с. Сопот' },
  { municipality: 'Сопот', region: 'Пловдив', settlement: 'гр. Сопот' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Сопотот' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Сотиря' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Софийци' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'гр. София' },
  { municipality: 'Мизия', region: 'Враца', settlement: 'с. Софрониево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Спанци' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'с. Спанчевци' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Спасово' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Спасово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Спасовци' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Спатово' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Спахиево' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Сполука' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Срацимир' },
  { municipality: 'Грамада', region: 'Видин', settlement: 'с. Срацимирово' },
  { municipality: 'Панагюрище', region: 'Пазарджик', settlement: 'с. Сребриново' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Сребърна' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'гр. Средец' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Средец' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Средец' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Средина' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Срединка' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Средище' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Средковец' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Средна махала' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Среднево' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Средни колиби' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Средни рът' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Средно градище' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Средно село' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Средно село' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Средногорово' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Средногорци' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Средня' },
  { municipality: 'Чупрене', region: 'Видин', settlement: 'с. Средогрив' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Средок' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Средорек' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Средорек' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Средоселци' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Средска' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Средско' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Срем' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Срънско' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'с. Ставерци' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Стаевци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Стайновци' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Стайчин дол' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Стайчовци' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Стакевци' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Сталево' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'с. Сталийска махала' },
  { municipality: 'Стамболийски', region: 'Пловдив', settlement: 'гр. Стамболийски' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Стамболийски' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Стамболово' },
  { municipality: 'Павликени', region: 'Велико Търново', settlement: 'с. Стамболово' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Стамболово' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Стамболово' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Стан' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'с. Станево' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Станец' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Станинци' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Становец' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Станча' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Станчов хан' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Станьовци' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Станянци' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Стар читак' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'гр. Стара Загора' },
  { municipality: 'Кресна', region: 'Благоевград', settlement: 'с. Стара Кресна' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Стара река' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Стара река' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Стара речка' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Старейшино' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Стари чал' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Старилковци' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Старо Железаре' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Старо място' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Старо Оряхово' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Старо селище' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Старо село' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Старо село' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Старо село' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Старо село' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Старо село' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Старово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Старозагорски бани' },
  { municipality: 'Кула', region: 'Видин', settlement: 'с. Старопатица' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Старосел' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Староселец' },
  { municipality: 'Искър', region: 'Плевен', settlement: 'с. Староселци' },
  { municipality: 'Златоград', region: 'Смолян', settlement: 'с. Старцево' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Старчево' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Старчище' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Стеврек' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Стежерово' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Стенско' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Стефан Караджа' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Стефан Караджа' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Стефан Караджа' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Стефан Караджово' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Стефан Стамболово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Стефаново' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Стефаново' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Стефаново' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Стефаново' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Стикъл' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Стоб' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Стоево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Стоевци' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Стожа' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Стожер' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Стоил войвода' },
  { municipality: 'Малко Търново', region: 'Бургас', settlement: 'с. Стоилово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Стойките' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Стойково' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Стойковци' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Стойново' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Стойчевци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Стойчовци' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Стоките' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Столетово' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Столетово' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Столник' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Столът' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Стоманево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Стоманеците' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Стоманци' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Стоян Михайловски' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Стоян-Заимово' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Стояново' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'с. Стояново' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Стояновци' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Стояновци' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Страдалово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Стража' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Стража' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Стражата' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Стражевци' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Стражец' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Стражец' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'гр. Стражица' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Стражица' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Стражница' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'гр. Стралджа' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Странджа' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Странджево' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Странско' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Страхил' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Страхил войвода' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Страхилица' },
  { municipality: 'Полски Тръмбеш', region: 'Велико Търново', settlement: 'с. Страхилово' },
  { municipality: 'Поморие', region: 'Бургас', settlement: 'с. Страцин' },
  { municipality: 'Златоград', region: 'Смолян', settlement: 'с. Страшимир' },
  { municipality: 'Белослав', region: 'Варна', settlement: 'с. Страшимирово' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Стрезимировци' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Стрелец' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Стрелец' },
  { municipality: 'Кайнарджа', region: 'Силистра', settlement: 'с. Стрелково' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Стрелци' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Стрелци' },
  { municipality: 'Стрелча', region: 'Пазарджик', settlement: 'гр. Стрелча' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Стремово' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Стремци' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Стрижба' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Строево' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Стройно' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Стройновци' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Струиндол' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Струино' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Струма' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Струмешница' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Струмяни' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Струпец' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Струпец' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Струя' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Стръмци' },
  { municipality: 'Раковски', region: 'Пловдив', settlement: 'с. Стряма' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Стубел' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Студен извор' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Студен кладенец' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Студена' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Студена' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Студена' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Студенец' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Студенец' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Студеница' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Студено буче' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Стъргел' },
  { municipality: 'Бяла', region: 'Русе', settlement: 'с. Стърмен' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Стърница' },
  { municipality: 'Суворово', region: 'Варна', settlement: 'гр. Суворово' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Сугарево' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Сулица' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Султани' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Сумер' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'гр. Сунгурларе' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Сусам' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Суха река' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Суха река' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Сухаче' },
  { municipality: 'Сухиндол', region: 'Велико Търново', settlement: 'гр. Сухиндол' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Сухово' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Суходол' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Суходол' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Сухозем' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Сухолоевци' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Сухострел' },
  { municipality: 'Завет', region: 'Разград', settlement: 'с. Сушево' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Сушина' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Сушица' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Сушица' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Сушица' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Събковци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Съботковци' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Събрано' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Съдиево' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Съдиево' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Съдийско поле' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Съединение' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'гр. Съединение' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Съединение' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Съединение' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Сърнак' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Сърневец' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Сърнево' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Сърнево' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Сърнегор' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Сърненци' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Сърнец' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Сърнино' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Сърнино' },
  { municipality: 'Сърница', region: 'Пазарджик', settlement: 'гр. Сърница' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Сърница' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Сърпово' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'с. Сърцево' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Сяново' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Сяровци' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Сярци' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Табан' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Табачка' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Табашка' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Таваличево' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Таймище' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Тамарино' },
  { municipality: 'Първомай', region: 'Пловдив', settlement: 'с. Татарево' },
  { municipality: 'Минерални бани', region: 'Хасково', settlement: 'с. Татарево' },
  { municipality: 'Белене', region: 'Плевен', settlement: 'с. Татари' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Татково' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Татул' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Твърдинци' },
  { municipality: 'Бургас', region: 'Бургас', settlement: 'с. Твърдица' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'гр. Твърдица' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Твърдица' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Текето' },
  { municipality: 'Крушари', region: 'Добрич', settlement: 'с. Телериг' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Телиш' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Телчарка' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Теменуга' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Теменуга' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Тенево' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Тепава' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Теплен' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'гр. Тервел' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Тервел' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Терзиите' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Терзийско' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Терзийско' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Тертер' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'гр. Тетевен' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Тетово' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Тешел' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'с. Тешово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Тикале' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Тимарево' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Тинтява' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Типченица' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Титевци' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Тиховец' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Тихомир' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Тихомирово' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Тича' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Тишаново' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Тишевица' },
  { municipality: 'Брегово', region: 'Видин', settlement: 'с. Тияновци' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Тлачене' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Тодор Икономово' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Тодореците' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Тодоричене' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Тодорово' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Тодорово' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Тодоровци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Тодоровци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Тодорчета' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Тодювци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Токачка' },
  { municipality: 'Макреш', region: 'Видин', settlement: 'с. Толовица' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Томбето' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Томпсън' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Томчевци' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Тонско дабе' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Топола' },
  { municipality: 'Варна', region: 'Варна', settlement: 'с. Тополи' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Тополи дол' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Тополица' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Тополка' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Тополница' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Тополница' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'гр. Тополовград' },
  { municipality: 'Кула', region: 'Видин', settlement: 'с. Тополовец' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Тополовец' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Тополово' },
  { municipality: 'Маджарово', region: 'Хасково', settlement: 'с. Тополово' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Тополчане' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Тополчане' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Тополяне' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Топузево' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Топузи' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Топчии' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Топчийско' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Торбалъжите' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Торос' },
  { municipality: 'Пордим', region: 'Плевен', settlement: 'с. Тотлебен' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Точилари' },
  { municipality: 'Грамада', region: 'Видин', settlement: 'с. Тошевци' },
  { municipality: 'Баните', region: 'Смолян', settlement: 'с. Траве' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Травник' },
  { municipality: 'Лом', region: 'Монтана', settlement: 'с. Трайково' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Тракиец' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Тракийци' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Тракия' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Трапесковци' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Трапище' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Трапоклово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Требище' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Трекляно' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Трем' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Трескавец' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Три кладенци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Три могили' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Три могили' },
  { municipality: 'Стамболийски', region: 'Пловдив', settlement: 'с. Триводици' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Тригорци' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Триград' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Трилистник' },
  { municipality: 'Монтана', region: 'Монтана', settlement: 'с. Трифоново' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Троица' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Тросково' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'гр. Троян' },
  { municipality: 'Симеоновград', region: 'Хасково', settlement: 'с. Троян' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Трояново' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Трояново' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Труд' },
  { municipality: 'Ботевград', region: 'София', settlement: 'с. Трудовец' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Тръбач' },
  { municipality: 'Трън', region: 'Перник', settlement: 'гр. Трън' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Трънак' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Трънито' },
  { municipality: 'Нови пазар', region: 'Шумен', settlement: 'с. Тръница' },
  { municipality: 'Раднево', region: 'Стара Загора', settlement: 'с. Трънково' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Трънково' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Трънковци' },
  { municipality: 'Левски', region: 'Плевен', settlement: 'с. Трънчовица' },
  { municipality: 'Долна Митрополия', region: 'Плевен', settlement: 'гр. Тръстеник' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Тръстеник' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Тръстика' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Тръстиково' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Тръстиково' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'гр. Трявна' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Туден' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Тулово' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Тумбалово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Тумбевци' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Турия' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Туркинча' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Туроковци' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Турян' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'гр. Тутракан' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Тутраканци' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Туховища' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Тученица' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Тушовица' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Тъжа' },
  { municipality: 'Каолиново', region: 'Шумен', settlement: 'с. Тъкач' },
  { municipality: 'Неделино', region: 'Смолян', settlement: 'с. Тънка бара' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Тънки рът' },
  { municipality: 'Несебър', region: 'Бургас', settlement: 'с. Тънково' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Тънково' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Тънкото' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Тъпчилещово' },
  { municipality: 'Чупрене', region: 'Видин', settlement: 'с. Търговище' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'гр. Търговище' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Търкашени' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Търна' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Търнава' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Търнава' },
  { municipality: 'Бяла Слатина', region: 'Враца', settlement: 'с. Търнак' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Търнене' },
  { municipality: 'Павел баня', region: 'Стара Загора', settlement: 'с. Търничени' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Търновлаг' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Търновца' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Търновци' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Търновци' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Търносливка' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Търняне' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Търсино' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Търхово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Търън' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Тюленово' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Тюркмен' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Тютюнче' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Тянево' },
  { municipality: 'Симеоновград', region: 'Хасково', settlement: 'с. Тянево' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Угледно' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Углярци' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Угорелец' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Угорялковци' },
  { municipality: 'Угърчин', region: 'Ловеч', settlement: 'гр. Угърчин' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Узово' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Узунджово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Узуните' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Узуново' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Умаревци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Урвата' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Уровене' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Уручевци' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Усойка' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Устина' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Устрем' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Устрен' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Ухловица' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Ушевци' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Уши' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Ушинци' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'с. Фазаново' },
  { municipality: 'Средец', region: 'Бургас', settlement: 'с. Факия' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Фатово' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Фелдфебел Денково' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Филаретово' },
  { municipality: 'Банско', region: 'Благоевград', settlement: 'с. Филипово' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Филипово' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Филиповци' },
  { municipality: 'Ново село', region: 'Видин', settlement: 'с. Флорентин' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Фотиново' },
  { municipality: 'Батак', region: 'Пазарджик', settlement: 'с. Фотиново' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Фролош' },
  { municipality: 'Криводол', region: 'Враца', settlement: 'с. Фурен' },
  { municipality: 'Сатовча', region: 'Благоевград', settlement: 'с. Фъргово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Фърговци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Фъревци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Фъртуни' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Хаджи Димитър' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Хаджидимитрово' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Хаджидимитрово' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Хаджидимитрово' },
  { municipality: 'Хаджидимово', region: 'Благоевград', settlement: 'гр. Хаджидимово' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Хаджиево' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Хаджиите' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Хаджийско' },
  { municipality: 'Хайредин', region: 'Враца', settlement: 'с. Хайредин' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Халваджийско' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Хан Аспарухово' },
  { municipality: 'Велики Преслав', region: 'Шумен', settlement: 'с. Хан Крум' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Ханово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Харачерите' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Харваловци' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'гр. Харманли' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'гр. Хасково' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Хасовица' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'с. Хвойна' },
  { municipality: 'Гърмен', region: 'Благоевград', settlement: 'с. Хвостяне' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Хвърчил' },
  { municipality: 'Божурище', region: 'София', settlement: 'с. Хераково' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Хирево' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Хисар' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'гр. Хисаря' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Хитово' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Хитрино' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Хлевене' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Хлябово' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Ходжовци' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Хотанца' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Хотница' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Хотово' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Храбрино' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Храброво' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Храброво' },
  { municipality: 'Божурище', region: 'София', settlement: 'с. Храбърско' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Храсна' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Храстово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Християново' },
  { municipality: 'Карлово', region: 'Пловдив', settlement: 'с. Христо Даново' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Христовци' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Христовци' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Хрищени' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Хромица' },
  { municipality: 'Роман', region: 'Враца', settlement: 'с. Хубавене' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Хума' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Хухла' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Хъневци' },
  { municipality: 'Козлодуй', region: 'Враца', settlement: 'с. Хърлец' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Хърсово' },
  { municipality: 'Самуил', region: 'Разград', settlement: 'с. Хърсово' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Хърсово' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Цаконица' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Цалапица' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Цани Гинчево' },
  { municipality: 'Струмяни', region: 'Благоевград', settlement: 'с. Цапарево' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Цар Асен' },
  { municipality: 'Алфатар', region: 'Силистра', settlement: 'с. Цар Асен' },
  { municipality: 'Попово', region: 'Търговище', settlement: 'с. Цар Асен' },
  { municipality: 'Цар Калоян', region: 'Разград', settlement: 'гр. Цар Калоян' },
  { municipality: 'Куклен', region: 'Пловдив', settlement: 'с. Цар Калоян' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Цар Самуил' },
  { municipality: 'Видин', region: 'Видин', settlement: 'с. Цар Симеоново' },
  { municipality: 'Макреш', region: 'Видин', settlement: 'с. Цар Шишманово' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Царацово' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Царев брод' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Царев дол' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Царева ливада' },
  { municipality: 'Стамболово', region: 'Хасково', settlement: 'с. Царева поляна' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Царевец' },
  { municipality: 'Мездра', region: 'Враца', settlement: 'с. Царевец' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Царевец' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Царевец' },
  { municipality: 'Царево', region: 'Бургас', settlement: 'гр. Царево' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Царевци' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Царевци' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'с. Царимир' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Царино' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Царичина' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Царичино' },
  { municipality: 'Кула', region: 'Видин', settlement: 'с. Цар-Петрово' },
  { municipality: 'Стражица', region: 'Велико Търново', settlement: 'с. Царски извор' },
  { municipality: 'Златоград', region: 'Смолян', settlement: 'с. Цацаровци' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Цацаровци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Цвеклювци' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Цветино' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Цветкова бара' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Цветница' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Цвятковци' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Цвятово' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Цегриловци' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Целина' },
  { municipality: 'Нова Загора', region: 'Сливен', settlement: 'с. Ценино' },
  { municipality: 'Силистра', region: 'Силистра', settlement: 'с. Ценович' },
  { municipality: 'Ценово', region: 'Русе', settlement: 'с. Ценово' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Ценово' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Цепераните' },
  { municipality: 'Съединение', region: 'Пловдив', settlement: 'с. Церетелево' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Церецел' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Церковски' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Церова кория' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Церовец' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Церовица' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Церовище' },
  { municipality: 'Благоевград', region: 'Благоевград', settlement: 'с. Церово' },
  { municipality: 'Лесичово', region: 'Пазарджик', settlement: 'с. Церово' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Церово' },
  { municipality: 'Бобошево', region: 'Кюстендил', settlement: 'с. Циклово' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Цирка' },
  { municipality: 'Дългопол', region: 'Варна', settlement: 'с. Цонево' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Цонковци' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Црешново' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Цръклевци' },
  { municipality: 'Доспат', region: 'Смолян', settlement: 'с. Црънча' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Црънча' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Църварица' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Цървена ябълка' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Цървендол' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Цървеняно' },
  { municipality: 'Кочериново', region: 'Кюстендил', settlement: 'с. Цървище' },
  { municipality: 'Балчик', region: 'Добрич', settlement: 'с. Църква' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Църквица' },
  { municipality: 'Никола Козлево', region: 'Шумен', settlement: 'с. Църквица' },
  { municipality: 'Златица', region: 'София', settlement: 'с. Църквище' },
  { municipality: 'Чавдар', region: 'София', settlement: 'с. Чавдар' },
  { municipality: 'Доспат', region: 'Смолян', settlement: 'с. Чавдар' },
  { municipality: 'Ловеч', region: 'Ловеч', settlement: 'с. Чавдарци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Чавдарци' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Чавеи' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Чавка' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Чайка' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Чайка' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Чакаларово' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Чакали' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Чакалите' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Чакалци' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Чал' },
  { municipality: 'Борино', region: 'Смолян', settlement: 'с. Чала' },
  { municipality: 'Раковски', region: 'Пловдив', settlement: 'с. Чалъкови' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Чамла' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Чарган' },
  { municipality: 'Стралджа', region: 'Ямбол', settlement: 'с. Чарда' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Чарково' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Чеганци' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Чеканец' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Чеканец' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Чеканци' },
  { municipality: 'Горна Малина', region: 'София', settlement: 'с. Чеканчево' },
  { municipality: 'Тунджа', region: 'Ямбол', settlement: 'с. Челник' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Челопек' },
  { municipality: 'Челопеч', region: 'София', settlement: 'с. Челопеч' },
  { municipality: 'Каварна', region: 'Добрич', settlement: 'с. Челопечене' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'с. Челюстница' },
  { municipality: 'Георги Дамяново', region: 'Монтана', settlement: 'с. Чемиш' },
  { municipality: 'Чепеларе', region: 'Смолян', settlement: 'гр. Чепеларе' },
  { municipality: 'Ковачевци', region: 'Перник', settlement: 'с. Чепино' },
  { municipality: 'Рудозем', region: 'Смолян', settlement: 'с. Чепинци' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Чепинци' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Чеплетен' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Чепърлинци' },
  { municipality: 'Асеновград', region: 'Пловдив', settlement: 'с. Червен' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Червен' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Червен брег' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'гр. Червен бряг' },
  { municipality: 'Свищов', region: 'Велико Търново', settlement: 'с. Червена' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Червена вода' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Червена локва' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Червена могила' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Червена скала' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'с. Червенаково' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Червенковци' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Червенци' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Черганово' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'с. Черенча' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Черепово' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Череша' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Черешица' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Черешка' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Черешките' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Черешница' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Черешовица' },
  { municipality: 'Белица', region: 'Благоевград', settlement: 'с. Черешово' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Черешово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Черешово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Черешовска река' },
  { municipality: 'Вършец', region: 'Монтана', settlement: 'с. Черкаски' },
  { municipality: 'Никопол', region: 'Плевен', settlement: 'с. Черковица' },
  { municipality: 'Мирково', region: 'София', settlement: 'с. Черковище' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Черковна' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Черковна' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Черковна' },
  { municipality: 'Търговище', region: 'Търговище', settlement: 'с. Черковна' },
  { municipality: 'Карнобат', region: 'Бургас', settlement: 'с. Черково' },
  { municipality: 'Добрич-селска', region: 'Добрич', settlement: 'с. Черна' },
  { municipality: 'Хитрино', region: 'Шумен', settlement: 'с. Черна' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Черна вода' },
  { municipality: 'Братя Даскалови', region: 'Стара Загора', settlement: 'с. Черна гора' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Черна гора' },
  { municipality: 'Якоруда', region: 'Благоевград', settlement: 'с. Черна Места' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Черна могила' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Черна могила' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Черна нива' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Черна скала' },
  { municipality: 'Суворово', region: 'Варна', settlement: 'с. Чернево' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Черневци' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Черни бряг' },
  { municipality: 'Тетевен', region: 'Ловеч', settlement: 'с. Черни Вит' },
  { municipality: 'Камено', region: 'Бургас', settlement: 'с. Черни връх' },
  { municipality: 'Вълчедръм', region: 'Монтана', settlement: 'с. Черни връх' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'с. Черни връх' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Черни дял' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Черни Осъм' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Черни рид' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Чернигово' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Черник' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Черница' },
  { municipality: 'Симитли', region: 'Благоевград', settlement: 'с. Черниче' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Черничево' },
  { municipality: 'Хисаря', region: 'Пловдив', settlement: 'с. Черничево' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Черничино' },
  { municipality: 'Ружинци', region: 'Видин', settlement: 'с. Черно поле' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Черновръх' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Черноглавци' },
  { municipality: 'Главиница', region: 'Силистра', settlement: 'с. Черногор' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Черногорово' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Черногорово' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Черноград' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Чернодъб' },
  { municipality: 'Елхово', region: 'Ямбол', settlement: 'с. Чернозем' },
  { municipality: 'Калояново', region: 'Пловдив', settlement: 'с. Черноземен' },
  { municipality: 'Омуртаг', region: 'Търговище', settlement: 'с. Чернокапци' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Чернолик' },
  { municipality: 'Созопол', region: 'Бургас', settlement: 'гр. Черноморец' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'с. Черноморци' },
  { municipality: 'Провадия', region: 'Варна', settlement: 'с. Черноок' },
  { municipality: 'Крумовград', region: 'Кърджали', settlement: 'с. Чернооки' },
  { municipality: 'Генерал Тошево', region: 'Добрич', settlement: 'с. Чернооково' },
  { municipality: 'Върбица', region: 'Шумен', settlement: 'с. Чернооково' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Черноочене' },
  { municipality: 'Ихтиман', region: 'София', settlement: 'с. Черньово' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Черньовци' },
  { municipality: 'Тервел', region: 'Добрич', settlement: 'с. Честименско' },
  { municipality: 'Невестино', region: 'Кюстендил', settlement: 'с. Четирци' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Чехларе' },
  { municipality: 'Трекляно', region: 'Кюстендил', settlement: 'с. Чешлянци' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Чешма' },
  { municipality: 'Садово', region: 'Пловдив', settlement: 'с. Чешнегирово' },
  { municipality: 'Костинброд', region: 'София', settlement: 'с. Чибаовци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Чилик' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Чилнов' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Чинтулово' },
  { municipality: 'Чипровци', region: 'Монтана', settlement: 'гр. Чипровци' },
  { municipality: 'Враца', region: 'Враца', settlement: 'с. Чирен' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'гр. Чирпан' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Чистово' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Читаковци' },
  { municipality: 'Белоградчик', region: 'Видин', settlement: 'с. Чифлик' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Чифлик' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Чифлик' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Чичево' },
  { municipality: 'Кула', region: 'Видин', settlement: 'с. Чичил' },
  { municipality: 'Брезово', region: 'Пловдив', settlement: 'с. Чоба' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Чобанка' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Чокманово' },
  { municipality: 'Сливен', region: 'Сливен', settlement: 'с. Чокоба' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Чолакова' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Чомаково' },
  { municipality: 'Червен бряг', region: 'Плевен', settlement: 'с. Чомаковци' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Чорбаджийско' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Чорул' },
  { municipality: 'Сунгурларе', region: 'Бургас', settlement: 'с. Чубра' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Чубрика' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Чудинци' },
  { municipality: 'Лозница', region: 'Разград', settlement: 'с. Чудомир' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Чуйпетлово' },
  { municipality: 'Айтос', region: 'Бургас', settlement: 'с. Чукарка' },
  { municipality: 'Тополовград', region: 'Хасково', settlement: 'с. Чукарово' },
  { municipality: 'Златарица', region: 'Велико Търново', settlement: 'с. Чуката' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Чукилите' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Чуковезер' },
  { municipality: 'Радомир', region: 'Перник', settlement: 'с. Чуковец' },
  { municipality: 'Алфатар', region: 'Силистра', settlement: 'с. Чуковец' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Чуково' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Чуково' },
  { municipality: 'Чупрене', region: 'Видин', settlement: 'с. Чупрене' },
  { municipality: 'Елин Пелин', region: 'София', settlement: 'с. Чурек' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Чурен' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Чурилово' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Чуричени' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Чурка' },
  { municipality: 'Девин', region: 'Смолян', settlement: 'с. Чуруково' },
  { municipality: 'Ивайловград', region: 'Хасково', settlement: 'с. Чучулига' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Чучулигово' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Чучур' },
  { municipality: 'Шабла', region: 'Добрич', settlement: 'гр. Шабла' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Шаново' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Шарани' },
  { municipality: 'Мадан', region: 'Смолян', settlement: 'с. Шаренска' },
  { municipality: 'Болярово', region: 'Ямбол', settlement: 'с. Шарково' },
  { municipality: 'Бобов дол', region: 'Кюстендил', settlement: 'с. Шатрово' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Шейново' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Шемшево' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Шереметя' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Шиварово' },
  { municipality: 'Твърдица', region: 'Сливен', settlement: 'гр. Шивачево' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Шиливери' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Шилковци' },
  { municipality: 'Бойница', region: 'Видин', settlement: 'с. Шипикова махала' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'гр. Шипка' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Шипковица' },
  { municipality: 'Троян', region: 'Ловеч', settlement: 'с. Шипково' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Шипок' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Шипот' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Шипочане' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Шипочано' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Шипчените' },
  { municipality: 'Смолян', region: 'Смолян', settlement: 'с. Широка лъка' },
  { municipality: 'Хасково', region: 'Хасково', settlement: 'с. Широка поляна' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Широки дол' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Широко поле' },
  { municipality: 'Две могили', region: 'Русе', settlement: 'с. Широково' },
  { municipality: 'Бойница', region: 'Видин', settlement: 'с. Шишенци' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Шишковица' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Шишковци' },
  { municipality: 'Харманли', region: 'Хасково', settlement: 'с. Шишманово' },
  { municipality: 'Раковски', region: 'Пловдив', settlement: 'с. Шишманци' },
  { municipality: 'Гулянци', region: 'Плевен', settlement: 'с. Шияково' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Шкорпиловци' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Шодековци' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Шопите' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Шопци' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Шубеци' },
  { municipality: 'Годеч', region: 'София', settlement: 'с. Шума' },
  { municipality: 'Севлиево', region: 'Габрово', settlement: 'с. Шумата' },
  { municipality: 'Шумен', region: 'Шумен', settlement: 'гр. Шумен' },
  { municipality: 'Тутракан', region: 'Силистра', settlement: 'с. Шуменци' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Шумнатица' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Шушня' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Щерна' },
  { municipality: 'Вълчи дол', region: 'Варна', settlement: 'с. Щипско' },
  { municipality: 'Свиленград', region: 'Хасково', settlement: 'с. Щит' },
  { municipality: 'Иваново', region: 'Русе', settlement: 'с. Щръклево' },
  { municipality: 'Лесичово', region: 'Пазарджик', settlement: 'с. Щърково' },
  { municipality: 'Луковит', region: 'Ловеч', settlement: 'с. Ъглен' },
  { municipality: 'Лъки', region: 'Пловдив', settlement: 'с. Югово' },
  { municipality: 'Сливо поле', region: 'Русе', settlement: 'с. Юделник' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Юлиево' },
  { municipality: 'Аврен', region: 'Варна', settlement: 'с. Юнак' },
  { municipality: 'Момчилград', region: 'Кърджали', settlement: 'с. Юнаци' },
  { municipality: 'Пазарджик', region: 'Пазарджик', settlement: 'с. Юнаците' },
  { municipality: 'Велинград', region: 'Пазарджик', settlement: 'с. Юндола' },
  { municipality: 'Долни чифлик', region: 'Варна', settlement: 'с. Юнец' },
  { municipality: 'Кубрат', region: 'Разград', settlement: 'с. Юпер' },
  { municipality: 'Якоруда', region: 'Благоевград', settlement: 'с. Юруково' },
  { municipality: 'Ябланица', region: 'Ловеч', settlement: 'гр. Ябланица' },
  { municipality: 'Своге', region: 'София', settlement: 'с. Ябланица' },
  { municipality: 'Котел', region: 'Сливен', settlement: 'с. Ябланово' },
  { municipality: 'Ардино', region: 'Кърджали', settlement: 'с. Ябълковец' },
  { municipality: 'Кюстендил', region: 'Кюстендил', settlement: 'с. Ябълково' },
  { municipality: 'Димитровград', region: 'Хасково', settlement: 'с. Ябълково' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Ябълковци' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Ябълчево' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Ябълчени' },
  { municipality: 'Трявна', region: 'Габрово', settlement: 'с. Явор' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Яворец' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Яворница' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Яворовец' },
  { municipality: 'Черноочене', region: 'Кърджали', settlement: 'с. Яворово' },
  { municipality: 'Стара Загора', region: 'Стара Загора', settlement: 'с. Яворово' },
  { municipality: 'Куклен', region: 'Пловдив', settlement: 'с. Яврово' },
  { municipality: 'Ветрино', region: 'Варна', settlement: 'с. Ягнило' },
  { municipality: 'Мъглиж', region: 'Стара Загора', settlement: 'с. Ягода' },
  { municipality: 'Борино', region: 'Смолян', settlement: 'с. Ягодина' },
  { municipality: 'Берковица', region: 'Монтана', settlement: 'с. Ягодово' },
  { municipality: 'Родопи', region: 'Пловдив', settlement: 'с. Ягодово' },
  { municipality: 'Чирпан', region: 'Стара Загора', settlement: 'с. Яздач' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Язовец' },
  { municipality: 'Исперих', region: 'Разград', settlement: 'с. Яким Груево' },
  { municipality: 'Якимово', region: 'Монтана', settlement: 'с. Якимово' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Яковица' },
  { municipality: 'Петрич', region: 'Благоевград', settlement: 'с. Яково' },
  { municipality: 'Елена', region: 'Велико Търново', settlement: 'с. Яковци' },
  { municipality: 'Якоруда', region: 'Благоевград', settlement: 'гр. Якоруда' },
  { municipality: 'Драгоман', region: 'София', settlement: 'с. Ялботина' },
  { municipality: 'Велико Търново', region: 'Велико Търново', settlement: 'с. Ялово' },
  { municipality: 'Ямбол', region: 'Ямбол', settlement: 'гр. Ямбол' },
  { municipality: 'Джебел', region: 'Кърджали', settlement: 'с. Ямино' },
  { municipality: 'Етрополе', region: 'София', settlement: 'с. Ямна' },
  { municipality: 'Столична', region: 'София (столица)', settlement: 'с. Яна' },
  { municipality: 'Кирково', region: 'Кърджали', settlement: 'с. Янино' },
  { municipality: 'Смядово', region: 'Шумен', settlement: 'с. Янково' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Янковци' },
  { municipality: 'Сандански', region: 'Благоевград', settlement: 'с. Яново' },
  { municipality: 'Горна Оряховица', region: 'Велико Търново', settlement: 'с. Янтра' },
  { municipality: 'Дряново', region: 'Габрово', settlement: 'с. Янтра' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Яньовец' },
  { municipality: 'Перник', region: 'Перник', settlement: 'с. Ярджиловци' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Яребица' },
  { municipality: 'Дулово', region: 'Силистра', settlement: 'с. Яребица' },
  { municipality: 'Аксаково', region: 'Варна', settlement: 'с. Яребична' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Яребично' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Яребковица' },
  { municipality: 'Димово', region: 'Видин', settlement: 'с. Ярловица' },
  { municipality: 'Самоков', region: 'София', settlement: 'с. Ярлово' },
  { municipality: 'Трън', region: 'Перник', settlement: 'с. Ярловци' },
  { municipality: 'Брезник', region: 'Перник', settlement: 'с. Ярославци' },
  { municipality: 'Ново село', region: 'Видин', settlement: 'с. Ясен' },
  { municipality: 'Плевен', region: 'Плевен', settlement: 'с. Ясен' },
  { municipality: 'Габрово', region: 'Габрово', settlement: 'с. Ясените' },
  { municipality: 'Венец', region: 'Шумен', settlement: 'с. Ясенково' },
  { municipality: 'Разград', region: 'Разград', settlement: 'с. Ясеновец' },
  { municipality: 'Руен', region: 'Бургас', settlement: 'с. Ясеново' },
  { municipality: 'Казанлък', region: 'Стара Загора', settlement: 'с. Ясеново' },
  { municipality: 'Приморско', region: 'Бургас', settlement: 'с. Ясна поляна' },
  { municipality: 'Марица', region: 'Пловдив', settlement: 'с. Ясно поле' },
  { municipality: 'Кърджали', region: 'Кърджали', settlement: 'с. Ястреб' },
  { municipality: 'Антоново', region: 'Търговище', settlement: 'с. Ястребино' },
  { municipality: 'Ситово', region: 'Силистра', settlement: 'с. Ястребна' },
  { municipality: 'Русе', region: 'Русе', settlement: 'с. Ястребово' },
  { municipality: 'Опан', region: 'Стара Загора', settlement: 'с. Ястребово' },
  { municipality: 'Дупница', region: 'Кюстендил', settlement: 'с. Яхиново' },
];
