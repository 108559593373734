import { MeteringPoint } from 'types';

import { overwriteFileName } from '~/files';
import { removeEmptyFields } from '~/utils';

import { Location } from '../../types';
import { getErpByMeterPointId } from '../determineErp/determineErp';

export const prepareFilesAndLocations = (locations: Location[], companyName: string) => {
  const files: File[] = [];

  const meteringPoints: MeteringPoint[] = locations.map((location, i) => {
    const { file, ...restOfLocation } = location;

    if (file) {
      const newFilename = `${companyName}_invoice_${i + 1}`;
      overwriteFileName(file, newFilename);

      files.push(file);
    }

    return {
      ...removeEmptyFields(restOfLocation),
      erp: getErpByMeterPointId(restOfLocation.meteringPoint),
    };
  });

  return {
    files,
    meteringPoints,
  };
};
