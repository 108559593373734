import { countriesData } from 'ui';

export function removeEmptyFields(data) {
  const copyData = { ...data };
  Object.keys(copyData).forEach((key) => {
    if (data[key] === '' || data[key] === undefined) {
      delete copyData[key];
    }

    if (
      key.includes('phone') &&
      data[key] &&
      data[key].value.length <= countriesData[data[key].country].countryCode.length
    ) {
      delete copyData[key];
    }
  });
  return copyData;
}
