import { Checkbox, TextInput, useFormContext } from 'forms';
import { nanoid } from 'nanoid';
import { useTranslation } from 'next-export-i18n';
import { Alert, Box, Grid, Typography } from 'ui';
import { array, object, string } from 'yup';

import { AutocompletedCompany, CompanyAutocomplete } from '~/companyAutocomplete';

import { COMPANY_TYPES, CompanyTypeAutocomplete } from '../CompanyTypeAutocomplete';
import Owners from '../Owners/Owners';

interface BusinessOwner {
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface BusinessFormValues {
  isDataAutoPopulated: boolean;
  companyName: string;
  companyType: CompanyTypeAutocomplete;
  eik: string;
  owners: BusinessOwner[];
}

export const initialBusinessValues = {
  companyName: '',
  companyType: null,
  eik: '',
  isDataAutoPopulated: false,
  owners: [{ firstName: '', lastName: '', middleName: '', uid: nanoid() }],
};

export const getBusinessValidationSchema = (requiredMessage: string) =>
  object({
    companyName: string().required(requiredMessage),
    companyType: object({
      label: string(),
      value: string(),
    })
      .nullable()
      .required(requiredMessage),
    eik: string().required(requiredMessage),
    owners: array().of(
      object({
        firstName: string().required(requiredMessage),
        lastName: string().required(requiredMessage),
        middleName: string(),
      })
    ),
  });

export const CompanyData = () => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext();

  const isDataAutoPopulated = watch('isDataAutoPopulated');

  const setCompanyInfo = (data: AutocompletedCompany) => {
    if (!data) {
      return;
    }

    const { owners, name, address } = data;

    setValue('isDataAutoPopulated', false);

    setValue('business.companyName', name.toUpperCase());
    setValue(
      'business.companyType',
      COMPANY_TYPES.find((type) => type.value === data.type)
    );

    if (owners) {
      setValue(
        'business.owners',
        owners.map((owner) => ({ ...owner, uid: nanoid() }))
      );
    }

    const fields = ['municipality', 'region', 'settlement', 'street', 'area', 'postCode'];

    for (const field of fields) {
      if (address && address[field]) setValue(`address.${field}`, address[field]);
    }
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('switching.steps.companyData')}
      </Typography>

      <Box mt={2}>
        <CompanyAutocomplete
          label={t('shared.inputLabels.eik')}
          name="business.eik"
          data-testid="eik"
          setCompanyInfo={setCompanyInfo}
        />
      </Box>

      <Checkbox
        label={t('shared.common.changeCompanyData')}
        name="isDataAutoPopulated"
        disabled={isDataAutoPopulated}
        data-testid="is-data-auto-populated"
      />

      {isDataAutoPopulated && <Alert severity="warning">{t('switching.common.tradeRegistryWarning')}</Alert>}

      <Grid container spacing={2} alignItems="center" mt={1} mb={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            label={t('shared.inputLabels.firstName')}
            name="business.companyName"
            autocomplete="companyName"
            disabled={!isDataAutoPopulated}
            uppercase
            required
            data-testid="companyName"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompanyTypeAutocomplete disabled={!isDataAutoPopulated} fieldName="business.companyType" />
        </Grid>
      </Grid>

      <Box mb={2}>
        <Typography variant="h6" gutterBottom>
          {t('shared.common.owners')}
        </Typography>

        <Owners fieldName="business.owners" maxOwners={2} />
      </Box>
    </>
  );
};
