import { uploadDocumentByType } from 'firebase-client';

export const sendFilesForUpload = (
  bodyOptions: { companyName: string; requestId: string },
  ...files: (File | null | undefined | true)[]
) => {
  const formData = new FormData();

  Object.entries(bodyOptions).forEach(([key, value]) => formData.append(key, value));

  files.forEach((file) => {
    if (file instanceof File) {
      formData.append(encodeURI(file.name), file);
    }
  });

  return uploadDocumentByType(formData);
};
