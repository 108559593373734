import logger from 'logger';
import { useTranslation } from 'next-export-i18n';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { addError, addWarning, Errors, Warnings } from 'rum';
import { isError } from 'types';
import { AutocompleteField, CircularProgress, FormHelperText, TextField } from 'ui';

import { AutocompletedCompany } from '../types';
import { debounce } from '../utils/debounceFunction';
import { getCompany } from '../utils/getCompany/getCompany';

const MIN_EIK_LENGTH = 9;

interface Props {
  name: string;
  label: string;
  setCompanyInfo: (companyInfo: AutocompletedCompany) => void;
  'data-testid'?: string | undefined;
}

const CompanyAutocomplete = ({ name, label, setCompanyInfo, 'data-testid': dataTestId }: Props) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();
  const isDataAutoPopulated = watch('isDataAutoPopulated');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [options, setOptions] = useState<AutocompletedCompany[]>([]);

  const handleSelect = (
    _e: SyntheticEvent<Element, Event> | undefined,
    value: AutocompletedCompany | null,
    onChange: (...event: unknown[]) => void
  ) => {
    if (value) {
      setValue('eik', value.id);
      onChange(value.id);
      setCompanyInfo(value);
    }
    setOpen(false);
  };

  const fetchData = debounce(async (eik) => {
    try {
      const company = await getCompany(eik);

      if (!company) {
        addWarning(Warnings.COMPANY_AUTOCOMPLETE_NO_COMPANY, { eik });
        setError(t('switching.common.autocompleteError'));
        return;
      }

      if (!company.address) {
        addWarning(Warnings.COMPANY_AUTOCOMPLETE_NO_ADDRESS, { company });
      }

      if (!company.owners) {
        addWarning(Warnings.COMPANY_AUTOCOMPLETE_NO_OWNERS, { company });
      }

      setOptions([company]);
      setOpen(true);
    } catch (err) {
      logger.debug(`Error fetching company from Trade Registry with EIK: ${eik}`, { err });

      if (isError(err)) {
        addError(Errors.TRADE_REGISTRY_API_ERROR, err);
        setError(t('switching.common.autocompleteError'));
      }

      setOpen(false);
    } finally {
      setLoading(false);
    }
  }, 250);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.length < MIN_EIK_LENGTH) {
      setOpen(false);
    }

    if (e.target.value.length >= MIN_EIK_LENGTH) {
      setError('');
      fetchData(e.target.value);
      setLoading(true);
      setOpen(true);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { value: fieldValue, onChange } }) => (
          <AutocompleteField<AutocompletedCompany>
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => {
              return typeof option !== 'string' ? `${option.id}` : option;
            }}
            value={fieldValue}
            options={options}
            open={open}
            loading={loading}
            autoHighlight
            loadingText={t('switching.common.searchingInTradeRegistry')}
            noOptionsText={t('switching.common.noCompanyOptions')}
            onChange={(e: SyntheticEvent<Element, Event> | undefined, value: AutocompletedCompany | null) =>
              handleSelect(e, value, onChange)
            }
            renderOption={(props, option) => {
              if (loading) return <li {...props}>{t('switching.common.searchingInTradeRegistry')}</li>;
              return <li {...props}>{`${option.name} ${option.type}, ЕИК: ${option.id}`}</li>;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={label}
                name={name}
                data-testid={dataTestId}
                onChange={(e) => {
                  if (typeof e.target.value === 'string' && isDataAutoPopulated) {
                    onChange(e.target.value);
                    setValue('eik', e.target.value);
                  }
                  handleInputChange(e);
                }}
                error={Boolean(error)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps ? params.InputProps.endAdornment : null}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
    </>
  );
};

export default CompanyAutocomplete;
