export const measuringPointLabel = {
  Благоевград: 'meteringPoint',
  Бургас: 'itn',
  Варна: 'uin',
  'Велико Търново': 'uin',
  Видин: 'meteringPoint',
  Враца: 'meteringPoint',
  Габрово: 'uin',
  Добрич: 'uin',
  Кърджали: 'itn',
  Кюстендил: 'meteringPoint',
  Ловеч: 'meteringPoint',
  Монтана: 'meteringPoint',
  Пазарджик: 'itn',
  Перник: 'meteringPoint',
  Плевен: 'meteringPoint',
  Пловдив: 'itn',
  Разград: 'uin',
  Русе: 'uin',
  Силистра: 'uin',
  Сливен: 'itn',
  Смолян: 'itn',
  София: 'meteringPoint',
  'София (столица)': 'meteringPoint',
  'Стара Загора': 'itn',
  Търговище: 'uin',
  Хасково: 'itn',
  Шумен: 'uin',
  Ямбол: 'itn',
};
