import { GrowthBook as ServerSideGrowthBook } from '@growthbook/growthbook';
import { GrowthBook as ClientSideGrowthBook } from '@growthbook/growthbook-react';
import { addFeatureFlagEvaluation } from 'rum';

export const setInitialFeaturesInRum = async (gbInstance: ClientSideGrowthBook | ServerSideGrowthBook) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }

  await gbInstance.loadFeatures();
  const features = gbInstance.getFeatures();

  Object.keys(features).forEach((featureName) => {
    const { defaultValue } = features[featureName];
    const value = gbInstance.getFeatureValue(featureName, defaultValue);

    addFeatureFlagEvaluation(featureName, value);
  });
};
