import { FieldError, FieldErrorsImpl } from 'react-hook-form';

const isFieldError = (error: Record<string, unknown>): error is FieldError =>
  Boolean(error.message) && Boolean(error.type);

const splitName = (name: string): string[] => {
  const firstDotIndex = name.indexOf('.');
  return [name.substring(0, firstDotIndex), name.substring(firstDotIndex + 1)];
};

export const getError = (errors: FieldErrorsImpl, fieldName: string): FieldError | null => {
  if (!errors) {
    return null;
  }

  const isLastLevel = !fieldName.includes('.');

  if (isLastLevel) {
    const error = errors[fieldName];

    if (!error || !isFieldError(error)) {
      return null;
    }
    return error;
  }

  const [currentKey, nextKey] = splitName(fieldName);

  const nextLevel = errors[currentKey];

  if (nextKey && nextLevel && !isFieldError(nextLevel)) {
    return getError(nextLevel, nextKey);
  }

  return null;
};
