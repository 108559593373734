import { Box, Paper } from 'ui';

import { useSwitchingStore } from '~/store';

import CustomerForm from './CustomerForm/CustomerForm';
import MailingData from './MailingData/MailingData';

interface Props {
  onCustomerFormComplete?: (values) => void;
  onMailingDataComplete?: (values) => void;
}

const CustomerInfoView = ({ onCustomerFormComplete: onComplete, onMailingDataComplete }: Props) => {
  const { form } = useSwitchingStore();

  return (
    <Paper>
      <Box mb={4}>
        <MailingData onMailingDataComplete={onMailingDataComplete} />
      </Box>

      {form?.contact?.name && <CustomerForm onComplete={onComplete} />}
    </Paper>
  );
};

export default CustomerInfoView;
