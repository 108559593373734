import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlGroupLabel, FormHelperText, Radio, RadioGroup, RadioGroupProps } from 'ui';

import { getError } from '../../utils';

interface Props extends RadioGroupProps {
  name: string;
  options: Array<{ value: string; label?: string; description?: JSX.Element }>;
  defaultValue?: string | boolean;
  autocomplete?: string;
  disabled?: boolean;
}

export const RadioButtons = ({
  name,
  autocomplete,
  options,
  defaultValue = '',
  row = true,
  disabled = false,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = getError(errors, name);

  return (
    <FormControl error={Boolean(error)} disabled={disabled}>
      <RadioGroup row={row} aria-labelledby={name} name={autocomplete || name}>
        {options.map((option, i) => (
          <div key={option.value}>
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <FormControlGroupLabel
                  checked={!field.value ? defaultValue === option.value : field.value === option.value}
                  data-testid={`${field.name}-${i}`}
                  control={<Radio />}
                  label={option.label || option.value}
                  onChange={() => field.onChange(option.value)}
                />
              )}
            />
            {option.description}
          </div>
        ))}
      </RadioGroup>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};
