import { useEffect } from 'react';

import { useSwitchingStore } from '~/store';

export const useLoginUser = () => {
  const { user, initUser } = useSwitchingStore();

  useEffect(() => {
    if (!user) {
      initUser();
    }
  }, [initUser, user]);
};
