export const areas = {
  Варна: ['Аспарухово', 'Владислав Варненчик', 'Младост', 'Одесос', 'Приморски'],
  Пловдив: ['Централен', 'Източен', 'Западен', 'Северен', 'Южен', 'Тракия'],
  София: [
    'Банкя',
    'Витоша',
    'Връбница',
    'Възраждане',
    'Изгрев',
    'Илинден',
    'Искър',
    'Красна поляна',
    'Красно село',
    'Кремиковци',
    'Лозенец',
    'Люлин',
    'Младост',
    'Надежда',
    'Нови Искър',
    'Оборище',
    'Овча купел',
    'Панчарево',
    'Подуяне',
    'Сердика',
    'Слатина',
    'Средец',
    'Студентски',
    'Триадица',
  ],
};
