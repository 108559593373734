import { CompletedBusinessOwner } from 'types';

import { TradeRegistryOwners } from '~/companyAutocomplete/types';

export const convertOwners = (owners: TradeRegistryOwners[]): CompletedBusinessOwner[] => {
  return owners.map((owner) => {
    const ownerName = owner.name.split(' ');

    if (ownerName.length === 2) {
      return { firstName: ownerName[0], lastName: ownerName[1], middleName: '' };
    }
    return { firstName: ownerName[0], lastName: ownerName[2], middleName: ownerName[1] };
  });
};
