import { AnalyticsEvents, getRequestContractNumber, trackEvent } from 'firebase-client';
import {
  ACCEPTED_FILE_FORMATS,
  InputFile,
  MAX_FILE_SIZE,
  RadioButtons,
  validateFileFormat,
  validateFileSize,
  yupResolver,
} from 'forms';
import logger from 'logger';
import { useTranslation } from 'next-export-i18n';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { addError, Errors } from 'rum';
import { isError } from 'types';
import { Box, Button, Form, FormHelperText, Link, Paper, Typography } from 'ui';
import { mixed, object, string } from 'yup';

import { useSwitchingStore } from '~/store';
import { buildCustomerName } from '~/utils';

import { autoDownloadFile } from '../utils/autoDownloadFile/autoDownloadFile';
import { buildContractFileName } from '../utils/buildContractFileName';
import { uploadSignedContract } from '../utils/uploadContract';
import { ContractDownloadButton } from './ContractDownloadButton';

type FormValues = {
  contractSignOption?: 'Прикачи файл' | 'Ще изпратя по-късно по имейл';
  signedContract?: File | null;
};

interface Props {
  onDownload?: () => void;
  onComplete?: () => void;
}

export const ContractDownloadForm = ({ onComplete, onDownload }: Props) => {
  const { t } = useTranslation();

  const { form, updateRequest } = useSwitchingStore();

  const [isLoading, setIsLoading] = useState(false);
  const [contractGenerated, setContractGenerated] = useState(false);

  const {
    handleSubmit,
    watch,
    formState: { errors, ...formStateRest },
    ...rest
  } = useForm({
    resolver: yupResolver(
      object({
        contractSignOption: string()
          .oneOf([t('shared.contractSignOptions.attachFile'), t('shared.contractSignOptions.sendEmail')])
          .required(),
        signedContract: mixed<File>()
          .nullable()
          .when('contractSignOption', {
            is: (value) => {
              return value === t('shared.contractSignOptions.attachFile');
            },
            then: () => mixed().required(t('shared.formValidations.downloadContract')),
          })
          .when([], {
            is: () => contractGenerated === false,
            otherwise: () =>
              mixed<File>()
                .test('isEmpty', t('shared.formValidations.downloadContract'), () => true)
                .test('isTooBig', t('shared.formValidations.bigFile', { size: MAX_FILE_SIZE }), validateFileSize)
                .test('isCorrectType', t('shared.formValidations.wrongFile'), validateFileFormat),
            then: () => mixed().test('isEmpty', t('shared.formValidations.downloadContract'), () => false),
          }),
      })
    ),
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    if (!form) {
      return;
    }
    setIsLoading(true);
    const { signedContract, ...requestData } = values;

    if (signedContract) {
      const contractNumber = await getRequestContractNumber(form.requestId);

      if (!contractNumber) {
        logger.debug('Contract number is not available');
        return;
      }

      try {
        trackEvent(AnalyticsEvents.sFormFileUpload, { planName: form.plan });

        const customerName = buildCustomerName(
          form.business?.companyName,
          form.business?.companyType,
          form.contact?.name
        );
        await uploadSignedContract({
          contractNumber,
          customerName,
          file: signedContract,
          requestId: form.requestId,
        });
      } catch (error) {
        if (isError(error)) {
          addError(Errors.UPLOAD_SIGNED_CONTRACT_FAILURE, error);
        }
      }
    }

    trackEvent(AnalyticsEvents.sFormSubmit, { planName: form.plan });
    await updateRequest({ ...requestData, completed: true });
    document.body.scrollIntoView({ block: 'start' });
    setIsLoading(false);

    if (onComplete) {
      onComplete();
    }
  };

  const handleDownload = async (file) => {
    if (!form) {
      logger.debug('Contract number is not available');
      return;
    }

    try {
      const contractNumber = await getRequestContractNumber(form.requestId);

      if (!contractNumber) {
        logger.debug('Contract number is not available');
        return;
      }

      const customerName = buildCustomerName(
        form.business?.companyName,
        form.business?.companyType,
        form.contact?.name
      );
      const fileName = buildContractFileName(contractNumber, customerName, 'generated');
      await autoDownloadFile(file, fileName);

      if (!contractGenerated) {
        setContractGenerated(true);
      }
    } catch (error) {
      if (isError(error)) {
        addError(Errors.DOWNLOAD_GENERATED_CONTRACT_FAILURE, error);
      }
    }

    if (onDownload) {
      onDownload();
    }
  };

  return (
    <FormProvider
      {...{
        formState: { errors, ...formStateRest },
        handleSubmit,
        watch,
        ...rest,
      }}
    >
      <Form id="signContract" onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box mb={2}>
            {form && <ContractDownloadButton onDownload={handleDownload} />}
            <Typography mt={3}>{t('switching.contractGenerationInfo.signAndAttach')}</Typography>
            <Typography>{t('switching.contractGenerationInfo.otherOptions')}</Typography>
            <Typography>
              {`${t('switching.contractGenerationInfo.printAndSend')} `}
              <Link href={`mailto:${t('shared.company.consumerSalesEmail')}`}>
                {t('shared.company.consumerSalesEmail')}
              </Link>
            </Typography>
          </Box>

          <Box mb={2}>
            <RadioButtons
              row={false}
              name="contractSignOption"
              options={[
                { value: t('shared.contractSignOptions.attachFile') },
                { value: t('shared.contractSignOptions.sendEmail') },
              ]}
            />
          </Box>

          {watch('contractSignOption') === t('shared.contractSignOptions.attachFile') && (
            <>
              <InputFile
                label={t('shared.contractSignOptions.attachFile')}
                name="signedContract"
                fileFormats={ACCEPTED_FILE_FORMATS}
                helperText={t('switching.common.acceptedFormats', { formats: ACCEPTED_FILE_FORMATS.join(', ') })}
              />

              {watch('signedContract') !== undefined && (
                <Button size="sm" type="submit" onClick={handleSubmit(onSubmit)} loading={isLoading}>
                  {t('switching.common.attach')}
                </Button>
              )}
            </>
          )}

          {watch('contractSignOption') === t('shared.contractSignOptions.sendEmail') && (
            <>
              <Button loading={isLoading} type="submit" data-testid="finish-form">
                {t('switching.common.completeRequest')}
              </Button>
              <Box>
                <FormHelperText error>{errors.signedContract?.message}</FormHelperText>
              </Box>
            </>
          )}
        </Paper>
      </Form>
    </FormProvider>
  );
};
