import { useEffect } from 'react';
import { FieldValues, Path, UseFormTrigger, UseFormWatch } from 'react-hook-form';

export const useDependentFieldsTrigger = <T extends FieldValues>(
  watch: UseFormWatch<T>,
  trigger: UseFormTrigger<T>,
  dependentFields: [string, string]
) => {
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name?.includes(dependentFields[0]) || (name?.includes(dependentFields[1]) && type)) {
        const dependentField = name?.includes(dependentFields[0])
          ? (name.replace(dependentFields[0], dependentFields[1]) as Path<T>)
          : (name.replace(dependentFields[1], dependentFields[0]) as Path<T>);
        trigger(name);
        trigger(dependentField);
      } else if (type) {
        trigger(name);
      }
    });

    return () => subscription.unsubscribe();
  }, [dependentFields, trigger, watch]);
};
