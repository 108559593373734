import logger from 'logger';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

interface TagManagerOptions {
  gtmId: string;
}

export const initGoogleTagManager = ({ gtmId }: TagManagerOptions) => {
  if (!gtmId) {
    logger.error('Google tag manager is not init - gtmId is not set');
    return;
  }
  logger.debug(`Google tag manager is initializing`);
  TagManager.initialize({
    gtmId,
  });
};

export const useTagManager = ({ gtmId }: TagManagerOptions) => {
  useEffect(() => {
    initGoogleTagManager({ gtmId });
  }, [gtmId]);
};
