import { Checkbox, FieldSet, useFormContext } from 'forms';
import { useTranslation } from 'next-export-i18n';

const InvoicingData = () => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  return (
    <>
      <Checkbox
        sx={{ mt: 2 }}
        label={t('switching.common.invoicingData')}
        name="invoicingData.isCheckedInvoicingData"
      />

      {watch('invoicingData.isCheckedInvoicingData') && (
        <FieldSet
          fields={[
            {
              autocomplete: 'name',
              'data-testid': `contactName`,
              label: t('shared.inputLabels.fullName'),
              name: 'invoicingData.name',
              required: true,
            },
            {
              autocomplete: 'email',
              'data-testid': 'invoicingData.email',
              label: t('shared.inputLabels.email'),
              name: 'invoicingData.email',
              required: true,
            },
          ]}
        />
      )}
    </>
  );
};

export default InvoicingData;
