import { yupResolver } from 'forms';
import { FULL_NAME_REGEX, NUMBERS_ONLY_REGEX } from 'forms/validations/constants';
import { useTranslation } from 'next-export-i18n';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RequestSlice } from 'store';
import { Box, Button, Form, Toggle, Typography } from 'ui';
import { boolean, object, string } from 'yup';

import { AREA_CITIES, MIN_BULSTAT_LENGTH, MIN_PID_LENGTH } from '~/config/constants';
import { useSwitchingStore } from '~/store';
import { getMunicipality, removeEmptyFields, valueOrDefault } from '~/utils';

import AddressContent from '../AddressContent';
import {
  BusinessFormValues,
  CompanyData,
  getBusinessValidationSchema,
  initialBusinessValues,
} from '../CompanyData/CompanyData';
import { COMPANY_TYPES } from '../CompanyTypeAutocomplete';
import IndividualData from '../IndividualData/IndividualData';

interface AddressFormValues {
  area: string;
  municipality: string;
  settlement: string;
  region: string;
  street: string;
  postCode: string;
}

interface InvoicingDataFormValues {
  name: string;
  email: string;
  isCheckedInvoicingData: boolean;
}
interface FormValues {
  address: AddressFormValues;
  pid: string;
  bulstat: string;
  business: BusinessFormValues;
  invoicingData: InvoicingDataFormValues;
  isBusiness: boolean;
  isDataAutoPopulated: boolean;
}

const initialAddressValues = {
  area: '',
  municipality: '',
  postCode: '',
  region: '',
  settlement: '',
  street: '',
};

const initialInvoicingDataValues = {
  email: '',
  isCheckedInvoicingData: false,
  name: '',
};

const initialFormValues = {
  address: initialAddressValues,
  bulstat: '',
  business: initialBusinessValues,
  invoicingData: initialInvoicingDataValues,
  isBusiness: true,
  isDataAutoPopulated: false,
  pid: '',
};

const getInitialFormValues = (store: RequestSlice['form']): FormValues => {
  return store?.address?.street
    ? {
        address: valueOrDefault(store, 'address', initialFormValues),
        bulstat: valueOrDefault(store, 'bulstat', initialFormValues),
        business: valueOrDefault(store, 'business', initialFormValues) && {
          ...valueOrDefault(store, 'business', initialFormValues),
          companyType:
            valueOrDefault(store.business, 'companyType', initialFormValues) &&
            COMPANY_TYPES.find((type) => type.value === store.business?.companyType),
        },
        invoicingData: valueOrDefault(store.business, 'invoicingData', initialFormValues) && {
          email: store.invoicingData?.email,
          isCheckedInvoicingData: store.invoicingData?.isCheckedInvoicingData,
          name: store.invoicingData?.name,
        },
        isBusiness: !valueOrDefault(store, 'pid', true),
        isDataAutoPopulated: valueOrDefault(store, 'isDataAutoPopulated', initialFormValues),
        pid: valueOrDefault(store, 'pid', initialFormValues),
      }
    : initialFormValues;
};

const addressValidation = (requiredMessage: string) => {
  const schema = {
    area: string()
      .nullable()
      .when('settlement', {
        is: (value) => AREA_CITIES.includes(value),
        then: () => string().required(requiredMessage).nullable(),
      }),
    postCode: string().required(requiredMessage),
    region: string().required(requiredMessage).nullable(),
    settlement: string().required(requiredMessage),
    street: string().required(requiredMessage),
  };

  return object(schema);
};

interface Props {
  onComplete?: (values) => void;
}

const CustomerForm = ({ onComplete }: Props) => {
  const { t } = useTranslation();
  const requiredMessage = t('shared.formValidations.required');

  const { form, updateRequest } = useSwitchingStore();

  const [isLoading, setIsLoading] = useState(false);

  const formSchema = object({
    address: addressValidation(requiredMessage),
    bulstat: string()
      .matches(/^(\s*|\d+)$/, { message: t('shared.formValidations.onlyNumbersField') })
      .test(
        'empty-check',
        t('shared.formValidations.minFieldLength', { minLength: MIN_BULSTAT_LENGTH }),
        (bulstat) => !bulstat || bulstat.length >= MIN_BULSTAT_LENGTH
      ),
    business: object().when('isBusiness', {
      is: true,
      then: () => getBusinessValidationSchema(requiredMessage),
    }),
    invoicingData: object({
      email: string().when('isCheckedInvoicingData', {
        is: true,
        then: () => string().required(requiredMessage).email(t('shared.formValidations.email')),
      }),
      isCheckedInvoicingData: boolean(),
      name: string().when('isCheckedInvoicingData', {
        is: true,
        then: () =>
          string()
            .required(requiredMessage)
            .matches(FULL_NAME_REGEX, { message: t('shared.formValidations.fullName') }),
      }),
    }),
    isBusiness: boolean(),
    pid: string().when('isBusiness', {
      is: false,
      then: () =>
        string()
          .required(requiredMessage)
          .matches(NUMBERS_ONLY_REGEX, { message: t('shared.formValidations.onlyNumbersField') })
          .min(MIN_PID_LENGTH, t('shared.formValidations.minFieldLength', { minLength: MIN_PID_LENGTH })),
    }),
  });

  const { handleSubmit, watch, getValues, setValue, ...rest } = useForm({
    defaultValues: getInitialFormValues(form),
    resolver: yupResolver(formSchema),
  });
  const isBusiness = watch('isBusiness');

  const onSubmit = async (formValues) => {
    setIsLoading(true);

    const {
      address,
      business,
      pid,
      bulstat,
      isDataAutoPopulated,
      invoicingData: { email, name, isCheckedInvoicingData },
    } = formValues;

    const request = {
      address: {
        ...removeEmptyFields(address),
        municipality: getMunicipality(address.region, address.settlement),
        postCode: address.postCode,
      },
      bulstat: !formValues.isBusiness ? bulstat : '',
      business: formValues.isBusiness
        ? {
            ...removeEmptyFields(business),
            companyType: business.companyType?.value,
            isDataAutoPopulated: !isDataAutoPopulated,
          }
        : null,
      invoicingData: {
        email,
        isCheckedInvoicingData,
        name,
      },
      pid: !formValues.isBusiness ? pid : '',
    };

    await updateRequest(request);

    if (onComplete) onComplete(formValues);
    setIsLoading(false);
  };

  return (
    <FormProvider {...{ getValues, handleSubmit, setValue, watch, ...rest }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {form?.contact?.name && (
          <Box width="100%" display="flex" justifyContent="center" mb={2}>
            <Toggle
              data-testid="toggle-business-individual"
              disabled={isLoading}
              onChangeHandler={() => setValue('isBusiness', !getValues('isBusiness'))}
              options={[t('shared.common.business'), t('shared.common.private')]}
              checked={!isBusiness}
            />
          </Box>
        )}

        {isBusiness ? <CompanyData /> : <IndividualData />}

        <Box my={2}>
          <Typography variant="h6" gutterBottom mt={2}>
            {isBusiness ? t('shared.common.registrationAddress') : t('shared.common.identityCardAddress')}
          </Typography>

          <AddressContent fieldName="address" />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" data-testid="submit-company-info" loading={isLoading}>
            {t('shared.common.next')}
          </Button>
        </Box>
      </Form>
    </FormProvider>
  );
};

export default CustomerForm;
