import { setAttributes } from 'feature-flag';
import { AnalyticsEvents, handleCompletedRequest, trackEvent, useTagManager } from 'firebase-client';
import logger from 'logger';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-export-i18n';
import { useEffect, useState } from 'react';
import {
  Actions,
  addAction,
  addError,
  addWarning,
  Errors,
  setGlobalContext,
  setGlobalContextProperty,
  setUserProperty,
  Warnings,
} from 'rum';
import { useNps } from 'survey';
import {
  BillingPlans,
  Contact,
  CustomerCategory,
  isError,
  isSupportedPlan,
  PartiallyCompletedSwitchingRequest,
  RequestTypes,
  SwitchingRequest,
} from 'types';
import { Box, Container, Loader, Navbar, RedirectToHomepage, Stepper } from 'ui';

import { useLoginUser } from '~/auth';
import { CustomerInfoView } from '~/companyInfo';
import { CompletionView } from '~/completionView/CompletionView';
import { ContractGenerationView } from '~/contractGeneration';
import { MeteringPointsView } from '~/meteringPoint';
import { useCustomNavigation } from '~/navigation';
import { SwitchingSteps, useSwitchingStore } from '~/store';

const Switching = () => {
  useLoginUser();
  useTagManager({
    gtmId: process.env.NEXT_PUBLIC_GTM_CONFIG!,
  });

  const { t } = useTranslation();
  const router = useRouter();
  const { pushState } = useCustomNavigation();

  const { user, form, updateUser, initRequest, step, setStep } = useSwitchingStore();

  const { replaceState, addPopstateEvent } = useCustomNavigation();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);

  const survey = useNps({
    email: user?.email,
    properties: { ...form, form: 'switching' },
  });

  const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

  useEffect(() => {
    if (user && isProduction) {
      survey();
    }
  }, [survey, user, isProduction]);

  useEffect(() => {
    if (!router.isReady) return;

    if (typeof router.query.plan !== 'string' || !isSupportedPlan(router.query.plan)) {
      setShouldRedirect(true);
    }

    window.history.replaceState(null, '', '/');
  }, [router]);

  useEffect(() => {
    if (!router.isReady || !user) {
      logger.debug('router is not ready or user is not logged in');
      return;
    }

    if (typeof router.query.plan !== 'string') {
      logger.debug('plan is not a string', { plan: router.query.plan });
      return;
    }

    const { plan } = router.query;
    logger.debug('selected plan', { plan });

    if (!isSupportedPlan(plan)) {
      addWarning(Warnings.UNSUPPORTED_PLAN, { plan });
      return;
    }

    if (!form) {
      const generatedPlan = BillingPlans[plan];
      logger.debug('generatedPlan', generatedPlan);

      const type = RequestTypes.switching;
      logger.debug('initing request');
      initRequest({ category: CustomerCategory.retail, plan: generatedPlan, type });

      setGlobalContext({
        plan: generatedPlan,
        type,
      });
    }

    setIsFormReady(true);
  }, [router, initRequest, user, form]);

  useEffect(() => {
    if (router.isReady && form?.plan && !step) {
      addPopstateEvent();
      setStep('companyData');
      replaceState('companyData');
    }
  }, [addPopstateEvent, form?.plan, replaceState, router.isReady, setStep, step]);

  const steps: Record<SwitchingSteps, string> = {
    companyData: t('switching.steps.companyData'),
    contract: t('switching.steps.contract'),
    meteringPoints: t('switching.steps.meteringPoints'),
    sign: t('switching.steps.sign'),
  };

  const saveUserEmail = (email: string) => {
    logger.debug('saving user email', { email });
    updateUser({ email });
    setUserProperty('email', email);
    setAttributes({ email });
  };

  const onMailingDataComplete = (values: Contact) => {
    if (values.email) {
      saveUserEmail(values.email);
    }
    trackEvent(AnalyticsEvents.sFormMailingData, { planName: form?.plan });
    addAction(Actions.SWITCHING_INPUT_MAILING_DATA, values);
    setGlobalContextProperty('contact', values);
  };

  const onCustomerInfoViewComplete = (
    values: Pick<SwitchingRequest, 'address' | 'pid' | 'business' | 'invoicingData'>
  ) => {
    trackEvent(AnalyticsEvents.sFormContractInfo, { planName: form?.plan });
    addAction(Actions.SWITCHING_INPUT_COMPANY_INFO, values);
    setStep('meteringPoints');
    pushState('meteringPoints');
    setGlobalContextProperty('customer', values);

    document.body.scrollIntoView({ block: 'start' });
  };

  const onMeteringDataComplete = async (switchingRequest: PartiallyCompletedSwitchingRequest) => {
    try {
      await handleCompletedRequest(switchingRequest);

      trackEvent(AnalyticsEvents.sFormMeterPoint, { planName: form?.plan });
      addAction(Actions.SWITCHING_INPUT_METERING_POINTS);
      setStep('contract');
      pushState('contract');

      document.body.scrollIntoView({ block: 'start' });
    } catch (error) {
      if (isError(error)) {
        addError(Errors.HANDLE_COMPLETED_REQUEST_FAILURE, error);
      }
    }
  };

  return (
    <>
      <Head>
        <title>{t('switching.title')}</title>
      </Head>

      <Navbar>{step && <Stepper step={step} steps={steps} />}</Navbar>

      {shouldRedirect && <RedirectToHomepage />}

      {isFormReady ? (
        <Container>
          {step === 'companyData' && (
            <CustomerInfoView
              onCustomerFormComplete={onCustomerInfoViewComplete}
              onMailingDataComplete={onMailingDataComplete}
            />
          )}
          {step === 'meteringPoints' && (
            <MeteringPointsView
              onComplete={onMeteringDataComplete}
              backButtonLabel={t('shared.common.back')}
              submitButtonLabel={t('shared.common.next')}
            />
          )}
          {step === 'contract' && (
            <ContractGenerationView
              onComplete={() => {
                setStep('sign');
                addAction(Actions.SWITCHING_COMPLETE_FORM);
              }}
              onDownload={() => {
                addAction(Actions.SWITCHING_DOWNLOAD_CONTRACT);
              }}
            />
          )}
          {step === 'sign' && <CompletionView />}
        </Container>
      ) : (
        <Container>
          <Box display="flex" justifyContent="center">
            <Loader />
          </Box>
        </Container>
      )}
    </>
  );
};

export default Switching;
