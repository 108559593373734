import { TradeRegistryCompanyData } from '../../types';
import { convertAddressStringToObject } from '../convertAddressStringToObject/convertAddressStringToObject';
import { convertOwners } from '../convertOwners/convertOwners';
import { getRepresentatives } from '../getRepresentatives/getRepresentatives';

export const prepareCompanyData = (company: TradeRegistryCompanyData) => {
  const { legal_form_bg: type, owners: ownersList, address: addressString, company_id: id, name } = company;

  const owners = getRepresentatives(type, ownersList);

  const address = addressString ? convertAddressStringToObject(addressString) : null;
  const ownerNames = owners ? convertOwners(owners) : null;

  return {
    address,
    id,
    name,
    owners: ownerNames,
    type,
  };
};
