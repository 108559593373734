import { Autocomplete } from 'forms';
import { useTranslation } from 'next-export-i18n';
import { CompanyType } from 'types';

type InitialValue = '';

export interface CompanyTypeAutocomplete {
  value: CompanyType | InitialValue;
  label: string;
}

export const COMPANY_TYPES: CompanyTypeAutocomplete[] = [
  { label: 'ЕТ - Едноличен търговец', value: 'ЕТ' },
  {
    label: 'ЕООД - Еднолично дружество с ограничена отговорност',
    value: 'ЕООД',
  },
  { label: 'ООД - Дружество с ограничена отговорност', value: 'ООД' },
  { label: 'ЕАД - Еднолично акционерно дружество', value: 'ЕАД' },
  { label: 'АД - Акционерно дружество', value: 'АД' },
  { label: 'ЗП - Земеделски производител', value: 'ЗП' },
  { label: 'Сдружение', value: 'СДРУЖЕНИЕ' },
  { label: 'Фондация', value: 'ФОНДАЦИЯ' },
  { label: 'Кооперация', value: 'КООПЕРАЦИЯ' },
  { label: 'Народно Читалище', value: 'НАРОДНО ЧИТАЛИЩЕ' },
  { label: 'СД - Събирателно дружество', value: 'СД' },
];

interface Props {
  fieldName: string;
  disabled?: boolean;
  freeSolo?: boolean;
}

export const CompanyTypeAutocomplete = ({ fieldName, freeSolo, disabled = false }: Props) => {
  const { t } = useTranslation();

  return (
    <Autocomplete<CompanyTypeAutocomplete>
      label={t('shared.inputLabels.companyType')}
      name={fieldName}
      required
      disabled={disabled}
      freeSolo={freeSolo}
      data-testid="companyType"
      options={COMPANY_TYPES}
      noOptionsText="Няма резултати"
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
};
