import { nanoid } from 'nanoid';

import { Owner } from '../types';

export const initialOwner: Owner = {
  firstName: '',
  lastName: '',
  middleName: '',
  uid: nanoid(),
};
