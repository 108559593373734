import { generateDocument } from 'firebase-client';
import { RequestTypes } from 'types';

export const generateContract = async ({
  type,
  requestId,
}: {
  type: RequestTypes.switching;
  requestId: string;
}): Promise<Buffer> => {
  const response = await generateDocument(type, requestId);

  const buffer = Buffer.from(await response.arrayBuffer());

  return buffer;
};
