const overwriteFileName = (file: File, newName: string): void => {
  const fileExtension = file.name.split('.').pop();

  Object.defineProperty(file, 'name', {
    value: `${newName}.${fileExtension}`,
    writable: true,
  });
};

export { overwriteFileName };
