import {
  create,
  createRequestSlice,
  createStepperSlice,
  createUserSlice,
  RequestSlice,
  StepperSlice,
  UserSlice,
} from 'store';

type SwitchingSteps = 'sign' | 'meteringPoints' | 'companyData' | 'contract';
type SwitchingStore = StepperSlice<SwitchingSteps> & RequestSlice & UserSlice;
export const useSwitchingStore = create<SwitchingStore>()((...a) => ({
  ...createStepperSlice<SwitchingSteps>()(...a),
  ...createRequestSlice(...a),
  ...createUserSlice(...a),
}));

export type { SwitchingSteps };
